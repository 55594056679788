import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { getDate, getTime } from '../../lib/dateLib';
import styles from './TimeSheet.module.scss';

const TimeSheet = ({ enteries }) => {
  const { t } = useTranslation('lang');
  const entriesPrefix = useId();
  return (
    enteries.length !== 0 &&
    enteries.map((en) => {
      const newKeyIndex = uuid();
      return (
        <div className={`${styles.timeSheet}`} key={`${entriesPrefix}${newKeyIndex}`}>
          <div className={`d-flex align-items-center ${styles.sheetTitleContainer}`}>
            <p>{en.worker?.firstname}</p>
            <p>-</p>
            <p>({en?.total_working_hours})</p>{' '}
            <div>
              {en?.total_working_hours === 0 && (
                <p className={`${styles.noTimeLog}`}>No Time Logs</p>
              )}
            </div>
          </div>
          {en?.total_working_hours !== 0 && (
            <table className={`${styles.tableContainer}`}>
              <tr className={`${styles.sheetHeaderContainer}`}>
                <td>
                  <div>
                    <p>{t('timesheet.date')}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>{t('timesheet.started')}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>{t('timesheet.finished')}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>{t('timesheet.total_hours')}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>{t('timesheet.cost_rate')}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>{t('timesheet.billing_rate')}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>{t('timesheet.type')}</p>
                  </div>
                </td>
              </tr>
              {en.time_entries.map((te) => {
                const newKeyIndexForTimeEntries = uuid();
                return (
                  <tr
                    className={`${styles.sheetDataContainer} ${
                      te.invoiced || ['lunch', 'break'].includes(te.type?.toLowerCase())
                        ? styles.disabled
                        : ''
                    }`}
                    key={newKeyIndexForTimeEntries}
                  >
                    <td>
                      <div>
                        <p>{getDate(te.work_date)}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{getTime(te.start_time)}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{getTime(te.end_time)}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{te.total_hours}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        {['lunch', 'break'].includes(te.type?.toLowerCase()) ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {en.cost_rate} € {t('timesheet.h')}
                          </p>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {['lunch', 'break'].includes(te.type?.toLowerCase()) ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {en.billing_rate} € {t('timesheet.h')}
                          </p>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{te.type ?? null}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      );
    })
  );
};

export default TimeSheet;
