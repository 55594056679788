import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import styles from './FeedbackList.module.scss';
import { getDate } from '../../lib/dateLib';

function FeedbackList({ feedbacks }) {
  const { t } = useTranslation('lang');

  return (
    <div>
      <p>{t('task.feedback.past_comments')}</p>
      <div className={`${styles.commentsDiv}`}>
        {feedbacks.length !== 0 &&
          feedbacks.map((feed) => {
            const newKeyIndex = uuid();
            return (
              <div key={newKeyIndex}>
                <div className={`${styles.innerDiv}`}>{feed.feedback}</div>
                <div className={`${styles.createdDiv}`}>
                  <div>{getDate(feed.createdAt)}</div>
                  <div>
                    {t('task.feedback.by')} : {`${feed.from.firstname} ${feed.from.lastname}`}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

FeedbackList.propTypes = {
  feedbacks: PropTypes.arrayOf(Object),
};

FeedbackList.defaultProps = {
  feedbacks: [],
};
export default FeedbackList;
