import React from 'react';
import PropTypes from 'prop-types';
import TrashSvg from '../svgs/trash-svg';
import DownloadIconSvg from '../svgs/download-icon';
import styles from './Attachment.module.scss';

function Attachment({ file, onDelete, onDownload, className }) {
  return (
    <div className={`d-flex align-items-center justify-content-between ${className}`}>
      <p>{file.name}</p>
      <div className={`d-flex align-items-center ${styles.iconContainer}`}>
        {file.uuid && (
          <div
            style={{ margin: '20px' }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className={styles.downBtn}
            onClick={() => onDownload()}
          >
            <DownloadIconSvg />
          </div>
        )}
        <div
          className={styles.TrashBtn}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={onDelete}
        >
          <TrashSvg />
        </div>
      </div>
    </div>
  );
}

Attachment.propTypes = {
  file: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  className: PropTypes.string,
};

Attachment.defaultProps = {
  file: null,
  onDelete() {},
  onDownload() {},
  className: null,
};

export default Attachment;
