import { useTranslation } from 'react-i18next';
import { languages } from '../../data';

const useConstants = () => {
  const { t } = useTranslation('lang');

  const BINARY_STATUS = [true, false];

  const WORKER_BASIC_DETAILS = {
    firstname: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.basic.placeholders.name'),
      label: t('worker.details.basic.fields.name'),
    },
    lastname: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.basic.placeholders.surname'),
      label: t('worker.details.basic.fields.surname'),
    },
    work_status: { type: 'select', value: '' },
    gender: {
      type: 'radio',
      value: 'Male',
      options: ['Male', 'Female'],
      label: t('worker.details.basic.fields.gender'),
    },
    dob: {
      type: 'date',
      value: '',
      placeholder: '',
      label: t('worker.details.basic.fields.dob'),
    },
    born_nationality: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.basic.placeholders.born_nationality'),
      label: t('worker.details.basic.fields.born_nationality'),
    },
    born_city: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.basic.placeholders.born_city'),
      label: t('worker.details.basic.fields.born_city'),
    },
    current_nationality: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.basic.placeholders.current_nationality'),
      label: t('worker.details.basic.fields.current_nationality'),
    },
    preferred_language: {
      type: 'radio',
      value: 'English',
      options: [...languages.map((lang) => lang.name)],
      label: t('worker.details.basic.fields.prefered_lang'),
    },
    workTypes: [],
    workTypePermissions: [],
  };

  const ARRIVAL_STATUS = ['alone', 'with one guardian', 'with both guardian'];

  const PASSPORT_DETAILS = {
    passport_number: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.passport.placeholders.number'),
      label: t('worker.details.passport.fields.number'),
    },
    passport_type: {
      type: 'radio',
      value: 'regular',
      label: t('worker.details.passport.fields.type'),
      options: ['regular', 'diplomat', 'refugee'],
    },
    passport_with: {
      type: 'radio',
      value: ARRIVAL_STATUS[0],
      label: '',
      options: [...ARRIVAL_STATUS],
    },
    issuing_country: {
      type: 'text',
      value: '',
      placeholder: 'Germany',
      label: t('worker.details.passport.fields.issuing_country'),
    },
    issuing_authority: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.passport.placeholders.authority'),
      label: t('worker.details.passport.fields.issuing_authority'),
    },
    issued_on: {
      type: 'date',
      value: '',
      label: t('worker.details.passport.fields.issued'),
    },
    valid_until: {
      type: 'date',
      value: '',
      label: t('worker.details.passport.fields.valid'),
    },
  };

  const CONTACT_DETAILS = {
    street_address: {
      type: 'text',
      value: '',
      placeholder: 'Kainuuntie 28',
      label: t('worker.details.contact.fields.street'),
    },
    street_address_2: {
      type: 'text',
      value: '',
      placeholder: 'A 8',
      label: t('worker.details.contact.fields.street2'),
    },
    zip: {
      type: 'text',
      value: '',
      placeholder: '8E3 E54',
      label: t('worker.details.contact.fields.zip'),
    },
    city: {
      type: 'text',
      value: '',
      placeholder: 'Sotkamo',
      label: t('worker.details.contact.fields.city'),
    },
    phone: {
      type: 'number',
      value: '',
      placeholder: '040 123 4567',
      label: t('worker.details.contact.fields.phone'),
    },
    email: {
      type: 'email',
      value: '',
      placeholder: 'test.user@email.com',
      label: t('worker.details.contact.fields.email'),
    },
  };

  const WORKER_DETAILS_SECTIONS = [
    t('worker.details.basic.title'),
    t('worker.details.family.title'),
    t('worker.details.passport.title'),
    t('worker.details.contact.title'),
    t('worker.details.visa.title'),
    t('worker.details.work.title'),
    t('worker.details.other.title'),
    t('worker.details.attachment.title'),
    t('worker.details.consent.title'),
  ];

  const VISA_DETAILS = {
    visa_number: {
      type: 'text',
      value: '',
      placeholder: 'Kainuuntie 28',
      label: t('worker.details.visa.fields.number'),
    },
    visa_valid_until: {
      type: 'date',
      value: '',
      label: t('worker.details.visa.fields.valid'),
    },
    has_eu_working_permit: {
      type: 'radio',
      value: BINARY_STATUS[1],
      question: t('worker.details.visa.fields.msg'),
    },
    country: {
      type: 'text',
      value: '',
      placeholder: 'Germany',
      label: t('worker.details.visa.fields.country'),
    },
    issued_on: {
      type: 'date',
      value: '',
      label: t('worker.details.visa.fields.issued'),
    },
    valid_until: {
      type: 'date',
      value: '',
      label: t('worker.details.visa.fields.valid'),
    },
  };

  const EMPLOYER_DETAILS = {
    employer_name: '',
    start_date: '',
    end_date: '',
  };

  const OTHER_DETAILS = {
    is_returning_to_previous_employer: {
      type: 'radio',
      value: BINARY_STATUS[1],
      label: t('worker.details.other.previous'),
      question: t('worker.details.other.prev_msg'),
    },
    has_accomodation_in_finland: {
      type: 'radio',
      value: BINARY_STATUS[1],
      question: t('worker.details.other.prev_msg1'),
    },
    street_address: {
      type: 'text',
      value: '',
      label: t('worker.details.other.street'),
      placeholder: 'Kainuuntie 28',
    },
    zip: {
      type: 'text',
      value: '',
      placeholder: '8E3 E54',
      label: t('worker.details.other.zip'),
    },
    city: {
      type: 'text',
      value: '',
      placeholder: 'Sotkamo',
      label: t('worker.details.other.city'),
    },
    additional_information: {
      type: 'text',
      value: '',
      placeholder: t('worker.details.other.comment'),
      label: t('worker.details.other.additional'),
    },
  };
  return {
    BINARY_STATUS,
    WORKER_BASIC_DETAILS,
    ARRIVAL_STATUS,
    PASSPORT_DETAILS,
    CONTACT_DETAILS,
    WORKER_DETAILS_SECTIONS,
    VISA_DETAILS,
    EMPLOYER_DETAILS,
    OTHER_DETAILS,
  };
};

export default useConstants;
