import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Button from '../../../components/button';
import { forgotPassword } from '../../../services/auth';
import styles from './ForgotPassword.module.scss';
import SideLoader from '../../../components/side-loader';
import { changeLanguageOnLoginPage } from '../../../lib/dateLib';

function ForgotPassword() {
  const [email, setEmail] = useState('sifars@hiukka.com');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('lang');

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    forgotPassword(email)
      .then(() => {
        navigate('/auth/reset-link/success');
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className={styles.formContainer}>
      <Helmet>
        <title>Hiukka - {t('page_titles.reset_password')}</title>{' '}
      </Helmet>
      {loading ? <SideLoader /> : null}
      <Form onSubmit={onSubmit}>
        <Form.Group className={styles.inputContainer}>
          <Form.Control
            className={styles.inputField}
            type="email"
            placeholder={t('login.email')}
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text />
        </Form.Group>
        <Button disabled={loading} type="submit" className={styles.loginBtn}>
          {t('login.send_resent_link')}
        </Button>
      </Form>
      <div className={styles.forgotPasswordContainer}>
        <Link to="/auth/login" className={styles.forgotPasswordLink}>
          {t('login.go_to_login')}
        </Link>
      </div>
      <div className={`d-flex align-items-center justify-content-center ${styles.flagContainer}`}>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('en')}
          >
            <img className={styles.flagImg} src="/images/flag1.png" alt="flag" />
          </button>
        </div>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('fi')}
          >
            <img className={styles.flagImg} src="/images/flag2.png" alt="flag" />
          </button>
        </div>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('ru')}
          >
            <img className={styles.flagImg} src="/images/flag3.png" alt="flag" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
