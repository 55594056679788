import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import User from '../user';
import AddUser from '../add-user';
import Button from '../button';
import { getWorkers } from '../../services/workers';
import styles from './TaskWorkersForm.module.scss';
import ConfirmationModal from '../confirmation-modal';
import SearchBox from '../search-box';
import { Console, getImageSrcUrl } from '../../utils';
import { getTask, assignWorkersToTask, unAssignWorkersToTask } from '../../services/task';
import { updateEvent, getEvent } from '../../services/googleCalendar';

function TaskWorkersForm({ handleSubmit, resetForm }) {
  const { companyId, projectId, taskId } = useParams();

  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const [assignedWorkers, setAssignedWorkers] = useState([]);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [workerToUnAssign, setWorkerToUnAssign] = useState({});
  const [query, setQuery] = useState('');
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [requiredWorkers, setRequiredWorkers] = useState(0);
  const [workerListTab, setWorkerListTab] = useState(true);
  const [workersToDelete, setWorkersToDelete] = useState([]);
  const [event, setEvent] = useState({});
  const [oldState, setOldState] = useState(null);

  const fetchExistingWorkersFromTask = async () => {
    try {
      const { data } = await getTask(companyId, projectId, taskId);
      const modifiedWorkers =
        data.workers.map(({ worker }) => ({ ...worker, preloaded: true })) || [];
      setAssignedWorkers(modifiedWorkers);
      setRequiredWorkers(data.no_of_workers);
      if (data.event_id) {
        const eventData = await getEvent('primary', data.event_id);
        setEvent(eventData);
        setOldState({
          assignedWorkers: modifiedWorkers,
          requiredWorkers: data.no_of_workers,
          event: eventData,
        });
      }
    } catch (error) {
      Console.log(error);
    }
  };

  const fetchWorkers = () => {
    getWorkers().then((res) => {
      setUsers(
        res.data
          .filter(
            (u) =>
              u.work_status?.status?.toLowerCase() !== 'pending' &&
              u.status?.toLowerCase() !== 'inactive'
          )
          .map((u) => ({ ...u, selected: false }))
      );
    });
  };

  const onUserAdd = (user) => {
    const assignedIndx = assignedWorkers.findIndex((wrkr) => wrkr.uuid === user.uuid);
    if (assignedIndx === -1) {
      setUsers(users.map((u) => (u.uuid === user.uuid ? { ...u, selected: !u.selected } : u)));
    } else {
      toast.error(t(`task.msgs.already_exists`));
    }
  };

  const onUnAssign = (user) => {
    setShowModal(true);
    setWorkerToUnAssign(user);
    setWorkersToDelete((prev) => [...(prev ?? []), user.uuid]);
  };

  const unAssignWorker = () => {
    setShowModal(false);
    setAssignedWorkers(assignedWorkers.filter((wrkr) => wrkr.uuid !== workerToUnAssign.uuid));
  };

  const onSelectedWorkerSave = () => {
    const wrkrs = [...assignedWorkers, ...users.filter((u) => u.selected)];
    if (wrkrs.length <= requiredWorkers) {
      setAssignedWorkers(wrkrs);
      setUsers(users.map((u) => ({ ...u, selected: false })));
      setWorkerListTab(true);
    } else {
      toast.error(t(`task.msgs.required_worker_limit_msg`));
    }
  };

  const switchTabLists = () => {
    if (workerListTab) {
      return assignedWorkers;
    }
    return users;
  };

  const filterSearchResults = (usersRec) =>
    usersRec.filter(
      (u) =>
        u.firstname?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        u.lastname?.toLowerCase().indexOf(query.toLocaleLowerCase()) > -1
    );

  const assignWorkers = async () => {
    const workersToAssign = assignedWorkers
      .filter((worker) => !worker.preloaded)
      .map((worker) => ({ uuid: worker.uuid, email: worker.email }));

    try {
      await Promise.all([
        workersToAssign.length !== 0 &&
          assignWorkersToTask(companyId, projectId, taskId, {
            workers: workersToAssign.map(({ uuid }) => uuid),
          }),
        workersToDelete.length !== 0 &&
          unAssignWorkersToTask(companyId, projectId, taskId, {
            workers: workersToDelete,
          }),
      ]);

      await updateEvent({
        calendarId: 'primary',
        eventId: event.id,
        body: { ...event, attendees: workersToAssign.map(({ email }) => ({ email })) },
      });
      toast.success(t(`task.msgs.workers_assigned_msg`));
    } catch (error) {
      Console.log(error);
    }
    fetchExistingWorkersFromTask();
    handleSubmit(2);
  };

  useEffect(() => {
    if (companyId && projectId && taskId) {
      fetchExistingWorkersFromTask();
      fetchWorkers();
    }
  }, [companyId, projectId, taskId]);

  useEffect(() => {
    if (resetForm.reset) {
      if (oldState) {
        setAssignedWorkers(oldState.assignedWorkers);
        setRequiredWorkers(oldState.requiredWorkers);
        setEvent(oldState.event);
      }
      setWorkerListTab(true);
      setWorkersToDelete([]);
      setWorkerToUnAssign({});
      setSaveBtnDisabled(true);
    }
  }, [resetForm.resetId]);

  return (
    <div className="scrollContainer uploadAssignedMainContainer d-lg-flex flex-lg-wrap align-items-lg-start">
      <p className="cardLabel">
        {workerListTab ? t('task.workers.assigned') : t('task.workers.select_worker')}
      </p>

      <div className={`uploadAssignedContainer  ${styles.uploadAssignedContainer}`}>
        {!workerListTab && <SearchBox className="searchDiv" onSearch={setQuery} />}
        {switchTabLists().length !== 0 &&
          filterSearchResults(switchTabLists()).map((user) => (
            <User
              key={user.uuid}
              id={user.uuid}
              name={`${user.firstname} ${user.lastname}`}
              profilePic={getImageSrcUrl(user)}
              tags={user.workTypes}
              specialTags={user.workTypePermissions}
              alignImage="top"
              status={user.work_status?.status ?? user.status}
              deleteBtn={workerListTab}
              onDelete={() => {
                onUnAssign(user);
                setSaveBtnDisabled(false);
              }}
              selected={!workerListTab ? user.selected : false}
              onSelect={() => {
                if (!workerListTab) {
                  onUserAdd(user);
                  setSaveBtnDisabled(false);
                }
              }}
              onEdit={(id) => navigate(`/workers/${id}`)}
            />
          ))}
        {workerListTab && (
          <AddUser
            modalRequired={false}
            onClick={() => setWorkerListTab(false)}
            placeHolder={t('task.workers.add_worker')}
          />
        )}
      </div>

      <div className="btnContainer">
        <Button
          disabled={saveBtnDisabled}
          variant="saveBtn"
          onClick={() => {
            if (workerListTab) {
              assignWorkers();
              setSaveBtnDisabled(true);
            } else {
              onSelectedWorkerSave();
            }
          }}
        >
          {workerListTab ? t('buttons.save_and_continue') : t('buttons.save')}
        </Button>
      </div>

      <ConfirmationModal
        title={t(`task.msgs.remove_worker`)}
        message={
          <span>
            {t(`task.msgs.remove_worker_msg`, {
              name: `${workerToUnAssign?.firstname} ${workerToUnAssign?.lastname}`,
            })}
          </span>
        }
        show={showModal}
        onConfirm={() => unAssignWorker()}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
}

TaskWorkersForm.propTypes = {
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.instanceOf(Object),
};

TaskWorkersForm.defaultProps = {
  handleSubmit() {},
  resetForm: null,
};

export default TaskWorkersForm;
