const FILE_TYPES = {
  IMAGE: { 'image/*': ['.png', '.jpg', '.jpeg'] },
  DOCUMENT: {
    'application/msword': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    'application/pdf': [],
    'application/vnd.ms-powerpoint': [],
    'text/plain': [],
  },
  EXCEL: {
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  },
};

export default FILE_TYPES;
