import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import BasicSettings from '../../settings/basic-settings';

// separate page is added to keep structured folders for worker view
function Settings() {
  const { t } = useTranslation('lang');

  const resetForm = {
    reset: false,
    resetId: uuid(),
  };

  return (
    <>
      <Helmet>
        <title>Hiukka - {t('page_titles.settings')}</title>
      </Helmet>
      <BasicSettings resetForm={resetForm} />
    </>
  );
}

export default Settings;
