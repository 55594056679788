import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from '../button';
import styles from './CompanyForm.module.scss';

function CompanyForm({ isValid, handleSubmit, company, setCompany }) {
  const { t } = useTranslation('lang');

  return (
    <Form
      className={`d-lg-flex flex-lg-column h-100 ${styles.companyFormContainer}`}
      validated={isValid}
      onSubmit={handleSubmit}
    >
      <div className={styles.companyDetailsContent}>
        <div
          className={`d-flex flex-wrap align-items-center justify-content-between ${styles.firstContainer}`}
        >
          <div className="order-0 d-lg-flex align-items-lg-center">
            <Form.Label className={styles.formLabel}>{t('company.name')}</Form.Label>
            <Form.Control
              className={styles.formInput}
              required
              value={company.name}
              placeholder={t('company.name_p')}
              onChange={(e) => setCompany({ ...company, name: e.target.value })}
            />
          </div>
          <div className="order-1 d-lg-flex align-items-lg-center">
            <Form.Label className={styles.formLabel}>{t('company.vat')}</Form.Label>
            <Form.Control
              className={styles.formInput}
              required
              value={company.vat}
              placeholder={t('company.vat_p')}
              onChange={(e) => setCompany({ ...company, vat: e.target.value })}
            />
          </div>
          <div className="order-2 order-lg-3 d-lg-flex align-items-lg-center">
            <Form.Label className={styles.formLabel}>{t('company.phone')}</Form.Label>
            <Form.Control
              className={styles.formInput}
              required
              onKeyDown={(e) => {
                if (['-', '*', '/', '.', 'e', 'E'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              type="Number"
              value={company.phone}
              placeholder={t('company.phone_p')}
              onChange={(e) => setCompany({ ...company, phone: e.target.value })}
            />
          </div>
          <div className="order-3 order-lg-2 d-lg-flex align-items-lg-center">
            <Form.Label className={styles.formLabel}>{t('company.contact_person')}</Form.Label>
            <Form.Control
              className={styles.formInput}
              required
              placeholder={t('company.contact_name_p')}
              value={company.contact_person}
              onChange={(e) =>
                setCompany({
                  ...company,
                  contact_person: e.target.value.replace(/[0-9]/g, ''),
                })
              }
            />
          </div>
        </div>
        <div
          className={`d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start ${styles.clientContainer}`}
        >
          <Form.Label className={styles.formLabel}>{t('company.client_is')}</Form.Label>

          <div>
            <Form.Label className="radioContainer">
              {t('company.client_is_comapny')}
              <Form.Check.Input
                className={styles.formInput}
                checked={company.type === 'company'}
                type="radio"
                name="type"
                onChange={() => setCompany({ ...company, type: 'company' })}
              />
              <span className="radioIcon" />
            </Form.Label>
          </div>

          <div>
            <Form.Label className="radioContainer">
              {t('company.client_is_private')}
              <Form.Check.Input
                className={styles.formInput}
                checked={company.type === 'private'}
                type="radio"
                name="type"
                onChange={() => setCompany({ ...company, type: 'private' })}
              />
              <span className="radioIcon" />
            </Form.Label>
          </div>
        </div>
        <div className={`d-lg-flex flex-wrap align-items-center ${styles.secondContainer}`}>
          <div>
            <Form.Label className={styles.formLabel}>{t('company.web')}</Form.Label>
          </div>
          <div>
            <Form.Control
              className={styles.formInput}
              value={company.url}
              required
              type="url"
              placeholder={t('company.web_p')}
              onChange={(e) => setCompany({ ...company, url: e.target.value })}
            />
          </div>
        </div>
        <div className={`d-lg-flex flex-wrap align-items-center ${styles.secondContainer}`}>
          <div>
            <Form.Label className={styles.formLabel}>{t('company.address')}</Form.Label>
          </div>
          <div>
            <Form.Control
              className={styles.formInput}
              required
              value={company.address}
              placeholder={t('company.address_p')}
              onChange={(e) => setCompany({ ...company, address: e.target.value })}
            />
          </div>
        </div>
        <div
          className={`d-flex flex-wrap align-items-center justify-content-between ${styles.zipCityContainer}`}
        >
          <div className="d-lg-flex align-items-center justify-content-between">
            <Form.Label className={styles.formLabel}>{t('company.zip')}</Form.Label>
            <Form.Control
              className={styles.formInput}
              required
              type="text"
              value={company.zip}
              placeholder="3E4 5T6"
              onChange={(e) => setCompany({ ...company, zip: e.target.value })}
            />
          </div>
          <div className="d-lg-flex align-items-center justify-content-between">
            <Form.Label className={styles.formLabel}>{t('company.city')}</Form.Label>
            <Form.Control
              className={styles.formInput}
              required
              value={company.city}
              placeholder={t('company.city_p')}
              onChange={(e) => {
                const val = e.target.value;
                if (val[0] >= '0' && val[0] <= '9') {
                  setCompany({ ...company, city: e.target.value.slice(1) });
                } else {
                  setCompany({ ...company, city: e.target.value });
                }
              }}
            />
          </div>
        </div>
        <div className={`d-lg-flex flex-wrap align-items-center ${styles.secondContainer}`}>
          <div>
            <Form.Label className={styles.formLabel}>{t('company.invoicing')}</Form.Label>
          </div>
          <div>
            <Form.Control
              className={styles.formInput}
              placeholder={t('company.invoicing_p')}
              required
              value={company.e_invoicing_address}
              onChange={(e) => setCompany({ ...company, e_invoicing_address: e.target.value })}
            />
          </div>
        </div>
        <div className={`d-lg-flex flex-wrap align-items-center ${styles.secondContainer}`}>
          <div>
            <Form.Label className={styles.formLabel}>{t('company.operator_id')}</Form.Label>
          </div>
          <div>
            <Form.Control
              className={styles.formInput}
              value={company.operator_id}
              placeholder={t('company.operator_id_p')}
              required
              onChange={(e) => setCompany({ ...company, operator_id: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className={`d-lg-flex align-items-center ${styles.btnContainer}`}>
        <div>
          <Button variant="saveBtn" type="submit">
            {t('buttons.save_and_continue')}
          </Button>
        </div>
        <div>
          <Button type="button" variant="warnBtn">
            {t('company.msgs.check_status')}
          </Button>
        </div>
      </div>
    </Form>
  );
}

CompanyForm.propTypes = {
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  company: PropTypes.instanceOf(Object),
  setCompany: PropTypes.func,
};

CompanyForm.defaultProps = {
  isValid: false,
  handleSubmit() {},
  company: null,
  setCompany() {},
};

export default CompanyForm;
