/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWorkerStatus } from '../../../services/workers';

const initialState = {
  workerStatus: [],
  loading: false,
  error: '',
};
export const fetchWorkerStatus = createAsyncThunk('fetchWorkerStatus', async () =>
  getWorkerStatus().then(async (response) => response.data)
);

const worker = createSlice({
  name: 'worker',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWorkerStatus.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchWorkerStatus.fulfilled]: (state, action) => ({
      ...state,
      workerStatus: action.payload,
      error: '',
    }),
    [fetchWorkerStatus.rejected]: (state, action) => ({
      ...state,
      userInfo: {},
      loading: false,
      error: action.error.message,
    }),
  },
});

export default worker;
