import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MultiSelectDropDown from '../multi-select-dropdown';
import SelectableMultiTags from '../selectable-multi-tags';
import Button from '../button';
import styles from './TaskForm.module.scss';
import FormDatePicker from '../form-date-picker';
import FormTimePicker from '../form-time-picker';
import {
  getTimeFormattedIn24Hours,
  getDate,
  getUTCFormattedTime,
  getTimeZone,
} from '../../lib/dateLib';
import { preventInvalidChars, preventDecimalAndInvalidChars, Console } from '../../utils';
import TASK_DETAILS from '../../views/app/task/constant';
import { getIndustries } from '../../services/industries';
import { getWorkTypes } from '../../services/workTypes';
import { getWorkTypePermissions } from '../../services/workTypePermissions';
import { getTask, addTask, updateTask } from '../../services/task';
import { getEvent, createEvent, updateEvent } from '../../services/googleCalendar';

function TaskDetailsForm({
  companyId,
  projectId,
  taskId,
  company,
  project,
  handleSubmit,
  resetForm,
}) {
  const navigate = useNavigate();
  const [form, setForm] = useState(TASK_DETAILS);
  const [industries, setIndustries] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [workTypePermissions, setWorkTypePermissions] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [event, setEvent] = useState(null);
  const [oldState, setOldState] = useState(null);

  const { t } = useTranslation('lang');

  const getInitialEvent = (data) => {
    const startTime = getUTCFormattedTime({
      date: getDate(data.contract_period_start, `YYYY-MM-DD`),
      time: data.daily_work_start,
    });
    const endTime = getUTCFormattedTime({
      date: getDate(data.contract_period_start, `YYYY-MM-DD`),
      time: data.daily_work_end,
    });
    const firstWorkingDay = moment(data.contract_period_start, `YYYY-MM-DD`);
    const lastWorkingDay = moment(data.contract_period_end, `YYYY-MM-DD`);
    const dur = moment.duration(lastWorkingDay.diff(firstWorkingDay));
    const recurrenceCount = dur.asDays() + 1;
    const location = `${company?.address ? company.address : ''} , ${
      company?.city ? company.city : ''
    } , ${company?.zip ? company.zip : ''}`;
    return {
      summary: data.name,
      start: { dateTime: startTime, timeZone: getTimeZone() },
      end: { dateTime: endTime, timeZone: getTimeZone() },
      recurrence: [`RRULE:FREQ=DAILY;COUNT=${recurrenceCount}`],
      location,
      guestsCanInviteOthers: false,
      guestsCanSeeOtherGuests: false,
    };
  };

  const fetchTask = () => {
    getTask(companyId, projectId, taskId).then((res) => {
      if (res.is_success) {
        const { data } = res;
        const {
          workTypes: taskWorkTypes,
          workTypePermissions: taskWorkTypePermissions,
          ...restTaskData
        } = res.data;

        const formValues = {
          industry_id: data.industry?.uuid,
          work_type_id: taskWorkTypes[0]?.uuid,
          work_type_permissions: taskWorkTypePermissions,
          ...restTaskData,
        };

        setForm(formValues);

        if (restTaskData.event_id) {
          getEvent('primary', restTaskData.event_id).then((eventResponse) => {
            setEvent(eventResponse);
            setOldState({ taskDetails: formValues, event: eventResponse });
          });
        }

        setIsValid(false);
      } else {
        toast.info(t('common_messages.invalid_data'));
        navigate(`/companies/${companyId}/projects/${projectId}`);
      }
    });
  };

  const onTaskCreate = async (formValues, taskDescription) => {
    try {
      const payload = formValues;
      const res = await addTask(companyId, projectId, payload);
      const eventRes = await createEvent({
        calendarId: 'primary',
        body: { ...getInitialEvent(payload), description: taskDescription },
      });
      setEvent(eventRes);
      const eventId = eventRes.id;
      await updateTask(companyId, projectId, res.data.uuid, { event_id: eventId, ...payload });
      toast.success(t('task.msgs.added'));
      navigate(`/companies/${companyId}/projects/${projectId}/task/${res.data.uuid}`);
    } catch (error) {
      Console.log(error);
    }
  };

  const onTaskUpdate = async (formValues, taskDescription) => {
    try {
      const payload = formValues;
      await updateTask(companyId, projectId, taskId, payload);
      await updateEvent({
        calendarId: 'primary',
        eventId: event.id,
        body: { ...event, ...getInitialEvent(payload), description: taskDescription },
      });
      fetchTask();
      toast.success(t('task.msgs.updated'));
    } catch (error) {
      Console.log(error);
    }
    handleSubmit(1);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (form.industry_id === '' || !form.industry_id) {
      toast.error(t('common_messages.tag_select_msg'));
    } else if (
      form.work_type_id === '' ||
      !form.work_type_id ||
      !workTypes.find((wrk) => wrk.uuid === form.work_type_id)
    ) {
      toast.error(t('common_messages.work_type_select_msg'));
    } else {
      const values = form;
      const taskDescription = `${values.no_of_workers} x ${
        industries.filter((ind) => ind.uuid === values.industry_id)[0].name
      } - ${workTypes.filter((wrk) => wrk.uuid === values.work_type_id)[0].name}`;

      values.name = `${company?.name} - ${project?.name} - ${
        workTypes.filter((wrk) => wrk.uuid === values.work_type_id)[0].name
      }`;

      setForm(values);
      if (!taskId) {
        onTaskCreate(values, taskDescription);
      } else {
        onTaskUpdate(values, taskDescription);
      }
    }
  };

  const onWorkTypePermissionsSelect = (values) => {
    const populatedWorkTypePermissions = values.map(({ uuid, name }) => ({ uuid, name }));
    setForm({ ...form, work_type_permissions: populatedWorkTypePermissions });
  };

  const fetchIndustries = () => {
    getIndustries()
      .then((response) => {
        setIndustries(response.data);
      })
      .catch((error) => {
        Console.log(error);
      });
  };

  const fetchWorkTypes = () => {
    getWorkTypes(form.industry_id)
      .then((response) => {
        setWorkTypes(response.data);
      })
      .catch((error) => {
        Console.log(error);
      });
  };

  const fetchWorkTypePermissions = () => {
    getWorkTypePermissions()
      .then((response) => {
        setWorkTypePermissions(response.data);
      })
      .catch((error) => {
        Console.log(error);
      });
  };

  useEffect(() => {
    if (companyId && projectId) {
      fetchWorkTypePermissions();
      fetchIndustries();
    }
  }, []);

  useEffect(() => {
    if (companyId && projectId && taskId) {
      fetchTask();
    }
  }, []);

  useEffect(() => {
    if (form.industry_id) {
      fetchWorkTypes();
    }
  }, [form.industry_id]);

  useEffect(() => {
    if (resetForm.reset) {
      if (oldState && oldState.taskDetails) {
        setForm(oldState.taskDetails);
        setEvent(oldState.event);
      }
    }
  }, [resetForm.resetId]);

  return (
    <div className="scrollContainer">
      <Form
        className="h-100 d-lg-flex flex-lg-column"
        validated={isValid}
        onSubmit={handleFormSubmit}
      >
        <div>
          <div className="d-lg-flex flex-wrap">
            <div className="dropdownContainer d-lg-flex justify-content-lg-between">
              <p className="dropdownLabel">{t('task.details.industries')}</p>
              <div className="dropdownDiv">
                <div>
                  <MultiSelectDropDown
                    placeholder={t('task.details.select_industry')}
                    options={industries}
                    selectedOptions={
                      form.industry_id
                        ? industries.filter((ind) => ind.uuid === form.industry_id)[0]
                        : []
                    }
                    handleChange={(val) => {
                      setForm({ ...form, industry_id: val.uuid });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="dropdownContainer d-lg-flex justify-content-lg-between">
              <p className={`dropdownLabel ${styles.textRight}`}>{t('task.details.work_type')}</p>
              <div className="dropdownDiv">
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  onClick={() => {
                    if (!form.industry_id) toast.error(t('common_messages.tag_select_msg'));
                  }}
                >
                  <MultiSelectDropDown
                    placeholder={t('task.details.select_work_type')}
                    isDisabled={!form.industry_id}
                    options={workTypes}
                    selectedOptions={
                      form.work_type_id
                        ? workTypes.filter((wrk) => wrk.uuid === form.work_type_id)[0]
                        : []
                    }
                    handleChange={(val) => setForm({ ...form, work_type_id: val.uuid })}
                  />
                </div>
              </div>
            </div>
            <div className="dropdownContainer d-lg-flex ">
              <p className={`dropdownLabel ${styles.textRight}`}>
                {t('task.details.work_type_per')}
              </p>
              <div
                className={`${styles.workTypeMainDiv} workTypeMainDiv workTypePositionDiv d-lg-flex justify-content-between`}
              >
                <div className={`${styles.workTypeDiv} workTypeDiv`}>
                  <SelectableMultiTags
                    label="name"
                    id="uuid"
                    list={workTypePermissions}
                    onSelect={onWorkTypePermissionsSelect}
                    selectedTags={form.work_type_permissions ?? []}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="inputMainContainer">
            <div className="inputContainer d-lg-flex justify-content-lg-between">
              <p className="dropdownLabel">{t('task.details.workers_required')}</p>
              <div className="inputDiv">
                <Form.Control
                  required
                  className="inputField"
                  type="number"
                  min={1}
                  step={1}
                  value={form.no_of_workers}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      no_of_workers: e.target.value,
                    })
                  }
                  onKeyDown={preventDecimalAndInvalidChars}
                  placeholder="Number of workers"
                />
              </div>
            </div>
            <div className="inputContainer d-lg-flex justify-content-lg-between">
              <p className="dropdownLabel">{t('task.card.contract_period')}</p>
              <div className="dashedInputContainer  d-flex align-items-center justify-content-between">
                <div className="inputDiv">
                  <FormDatePicker
                    className="inputField"
                    value={form.contract_period_start}
                    onChange={(val) => {
                      setForm({
                        ...form,
                        contract_period_start: val,
                      });
                    }}
                  />
                </div>
                <div className="inputDash">-</div>
                <div className="inputDiv">
                  <FormDatePicker
                    className="inputField"
                    value={form.contract_period_end}
                    onChange={(val) => {
                      setForm({
                        ...form,
                        contract_period_end: val,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="inputContainer d-lg-flex justify-content-lg-between">
              <p className="dropdownLabel">{t('task.card.daily_work_time')}</p>
              <div className="dashedInputContainer d-flex align-items-center justify-content-between">
                <div className="inputDiv">
                  <FormTimePicker
                    required
                    className="inputField"
                    value={form.daily_work_start}
                    onChange={(val) =>
                      setForm({
                        ...form,
                        daily_work_start: getTimeFormattedIn24Hours(val),
                      })
                    }
                  />
                </div>
                <div className="inputDash">-</div>
                <div className="inputDiv">
                  <FormTimePicker
                    required
                    className="inputField"
                    value={form.daily_work_end}
                    onChange={(val) =>
                      setForm({
                        ...form,
                        daily_work_end: getTimeFormattedIn24Hours(val),
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="inputContainer d-lg-flex justify-content-lg-between">
              <p className="dropdownLabel">{t('task.details.hourly_rate')}</p>
              <div className="dashedInputContainer d-flex align-items-center justify-content-between">
                <div className="inputDiv">
                  <Form.Control
                    required
                    min={0.1}
                    step="any"
                    className="inputField"
                    type="number"
                    value={form.hourly_rate}
                    onChange={(e) => {
                      const newValue = e.target.value.startsWith('.')
                        ? `0${e.target.value}`
                        : e.target.value;
                      setForm({
                        ...form,
                        hourly_rate: newValue,
                      });
                    }}
                    placeholder="0.1"
                    onKeyDown={preventInvalidChars}
                  />
                </div>
                <div className="inputDash" />
                <div className="inputDiv inputSymbol">€ {t('task.card.h')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="btnContainer">
          <Button variant="saveBtn" type="submit">
            {taskId ? t('buttons.save_and_continue') : t('buttons.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

TaskDetailsForm.propTypes = {
  companyId: PropTypes.string,
  projectId: PropTypes.string,
  taskId: PropTypes.string,
  company: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.instanceOf(Object),
  project: PropTypes.instanceOf(Object),
};

TaskDetailsForm.defaultProps = {
  companyId: null,
  projectId: null,
  taskId: null,
  company: null,
  handleSubmit() {},
  resetForm: null,
  project: null,
};

export default TaskDetailsForm;
