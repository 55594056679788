import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';
import styles from './Tag.module.scss';

function PriceTag(props) {
  const { children, className, pricePerHour } = props;
  return (
    <Tag className={`${className} ${styles.priceTag}`}>
      {pricePerHour}€/h
      {children}
    </Tag>
  );
}

PriceTag.propTypes = {
  pricePerHour: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PriceTag.defaultProps = {
  className: null,
};
export default PriceTag;
