import service from './wrapper';

export const getAttachments = (holder, taskId) => service.get(`/attachments/${holder}/${taskId}`);

export const uploadAttachment = (holder, taskId, data) =>
  service.post(`/attachments/${holder}/${taskId}`, data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export const deleteAttachment = (holder, taskId, uuid) =>
  service.delete(`/attachments/${holder}/${taskId}/${uuid}`);

export const downloadAttachment = (holder, taskId, uuid) =>
  service.get(`/attachments/${holder}/${taskId}/download/${uuid}`, {
    responseType: 'blob',
  });
