export const handleProgressBar = (percentage, color, classes) => {
  const progressBar = document.querySelector(`.${classes[0]}`);
  const valueContainer = document.querySelector(`.${classes[1]}`);
  const progressEndValue = Math.round(percentage && percentage !== null ? percentage : 0);
  const speed = 1;
  const startingIndexValue = (360 * (100 - progressEndValue)) / 100;
  const progressInterval = setInterval(() => {
    if (valueContainer) {
      valueContainer.textContent = `${progressEndValue}%`;
      progressBar.style.background = `conic-gradient(from ${startingIndexValue}deg,
          ${color}  ${progressEndValue * 3.6}deg,
          #455368 ${progressEndValue * 3.6}deg
      )`;
    }

    clearInterval(progressInterval);
  }, speed);
};

export const getProgressBarRandomClasses = () => [
  `circularProgressBar${Math.random().toString(36).substring(2, 5)}`,
  `valueContainerBar${Math.random().toString(36).substring(2, 5)}`,
];
