import React, { useState, useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import Button from '../../../components/button';
import DropDown from '../../../components/dropdown';
import ProjectList from '../../../components/project';
import Status from '../../../components/status';
import PlusSvg from '../../../components/svgs/plus-svg';
import styles from './Company.module.scss';
import { getCompanies, getCompaniesByStatus } from '../../../services/company';
import PageLoader from '../../../components/page-loader';
import CustomToggle from '../../../components/utils/cutom-toggle';
import useConstants from './useConstants';
import ActionMenu from '../../../components/action-menu';

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [activeCard, setActiveCard] = useState();
  const [selectedValue, setSelectedValue] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const { statuses } = useConstants();
  const { t } = useTranslation('lang');

  const fetchCompanies = () => {
    setPageLoading(true);
    getCompanies()
      .then((response) => {
        setCompanies(response.data);
        setPageLoading(false);
      })
      .catch(() => {
        setPageLoading(false);
      });
  };

  const handleCompanyFilter = (val) => {
    setSelectedValue(val);
    if (val === 'all') {
      fetchCompanies();
    } else {
      getCompaniesByStatus(val).then((response) => {
        setCompanies(response.data);
      });
    }
  };

  const actions = [
    {
      actionLabel: t('actions.edit_company'),
      onClick: (companyUuid) => navigate(`/companies/${companyUuid}`),
    },
  ];

  useEffect(() => {
    fetchCompanies();
  }, []);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div className={styles.projectViewContainer}>
      <Helmet>
        <title>Hiukka - {t('page_titles.projects')}</title>{' '}
      </Helmet>
      <div className="d-lg-flex justify-content-between align-items-center">
        <p className="pageTitle">{t('project.heading')}</p>
        <div
          className={`d-flex align-items-center justify-content-between justify-content-lg-end ${styles.buttonContainer}`}
        >
          <div>
            <Button
              className="d-flex align-items-center justify-content-center"
              variant="addBtnBlue"
              onClick={() => navigate('/companies/create')}
            >
              <PlusSvg className={styles.plusIcon} />
              {t('project.new_company')}
            </Button>
          </div>
          <div>
            <DropDown
              placeholder={t('project.dropdown.title')}
              value={selectedValue}
              options={statuses}
              onChange={handleCompanyFilter}
            />
          </div>
        </div>
      </div>
      <div>
        <Accordion>
          {companies.length !== 0
            ? companies.map((company, index) => {
                const newKeyIndex = uuid();
                return (
                  <Card className={styles.projectViewCard} key={newKeyIndex}>
                    <Card.Header
                      className={`d-flex justify-content-between flex-wrap fontSegoe border-0 ${
                        styles.CardBody
                      } ${activeCard === index ? styles.activeCard : ''}`}
                    >
                      <div className={`order-1 order-lg-0 ${styles.CardColumn}`}>
                        <p className={styles.columnTitle}>{company.name}</p>
                        <p>{company.address}</p>
                        <p>
                          {company.city} {company.zip}
                        </p>
                      </div>
                      <div className={`order-3 order-lg-1 ${styles.CardColumn}`}>
                        <p>{company.contact_person}</p>
                        <p>
                          <span>{t('project.accordian.phone')}:</span>
                          {company.phone ? company.phone : t('common_messages.na')}
                        </p>
                        <p>
                          <span>{t('project.accordian.email')}:</span>
                          {company.email ? company.email : t('common_messages.na')}
                        </p>
                      </div>
                      <div className={`order-2 ${styles.CardColumn}`}>
                        <p>{t('project.accordian.active_project')}</p>
                        <p>{company.activeProjects}</p>
                      </div>
                      <div className={`order-4 order-lg-3 ${styles.CardColumn}`}>
                        <p>{t('project.accordian.completed_project')}</p>
                        <p>{company.completedProjects}</p>
                      </div>
                      <div
                        className={`order-0 order-lg-4 align-self-lg-center ${styles.btnMainColumn}`}
                      >
                        <div className={styles.btnContainer}>
                          <Status variant={company.status}>{company.status.toUpperCase()}</Status>
                        </div>
                      </div>
                      <div
                        className={`order-5 align-self-lg-center d-flex align-items-center' ${styles.actionMenuMainContainer}`}
                      >
                        <div className={`${styles.actionMenuContainer}`}>
                          <ActionMenu actions={actions} id={company.uuid} />
                        </div>
                        <div>
                          <CustomToggle eventKey={index} onChange={(val) => setActiveCard(val)} />
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <div>
                        <div className={styles.accordionBorder}>
                          <div>
                            <Button
                              variant="addBtnOrange"
                              className={`d-flex align-items-center justify-content-center ${styles.addBtn}`}
                              onClick={() => navigate(`/companies/${company.uuid}/projects/create`)}
                            >
                              <PlusSvg className={styles.plusIcon} />
                              {t('project.accordian.new_project')}
                            </Button>
                          </div>
                        </div>
                        <div className={styles.CardBody}>
                          {company.projects.length !== 0 && (
                            <ProjectList companyId={company.uuid} projects={company.projects} />
                          )}
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Card>
                );
              })
            : t('project.msgs.no_companies')}
        </Accordion>
      </div>
    </div>
  );
}
export default Companies;
