import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './Tasklist.module.scss';
import { getDate } from '../../lib/dateLib';

function Tasklist(props) {
  const { events: eventData, selectedDate, className } = props;
  const [events, setEvents] = useState(null);
  const { t } = useTranslation('lang');

  const populateAndSetEvents = () => {
    const { name, data } = eventData;
    const populatedEvents = [];
    data.forEach((d) => {
      const alreadyAdded = populatedEvents.findIndex((pe) => pe.id === d.id);
      if (alreadyAdded === -1) {
        populatedEvents.push(d);
      }
    });
    setEvents({ name, data: populatedEvents });
  };

  useEffect(() => {
    populateAndSetEvents();
  }, [eventData]);

  return (
    <div className={`${styles.taskListContainer} ${className}`}>
      <div className={`d-flex justify-content-between fontArial ${styles.listHeader}`}>
        <div>{t('home.task_list')}</div>
        <div>{getDate(selectedDate)}</div>
      </div>
      <div className={`${styles.ItemsContainer}`}>
        {events && events.name === 'tasks' && events.data.length !== 0
          ? events.data.map((listItem) => (
              <Link className={styles.ItemsLink} to={`/tasks/${listItem.uuid}`} key={listItem.uuid}>
                {listItem.name}
              </Link>
            ))
          : null}

        {events && events.name === 'gevents' && events.data.length !== 0
          ? events.data.map((listItem) => (
              <a
                className={styles.ItemsLink}
                href={listItem.htmlLink}
                target="_blank"
                key={listItem.id}
                rel="noreferrer"
              >
                {listItem.summary}
              </a>
            ))
          : t('task.msgs.no_tasks')}
      </div>
    </div>
  );
}

Tasklist.propTypes = {
  events: PropTypes.oneOfType([PropTypes.arrayOf(Object), PropTypes.instanceOf(Object)]),
  selectedDate: PropTypes.string,
  className: PropTypes.string,
};

Tasklist.defaultProps = {
  events: [],
  selectedDate: null,
  className: null,
};

export default Tasklist;
