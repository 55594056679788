import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import ChevronDown from '../svgs/chevron-down';
import styles from './dropdown.module.scss';

function CustomToggle() {
  // eslint-disable-next-line react/prop-types
  return React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      className={styles.dropDownBtn}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <ChevronDown className={styles.downIcon} />
    </button>
  ));
}

function DropDown({ options, value, onChange, placeholder, className }) {
  const selectedItem = options.find((option) => option.value === value);
  const lable = selectedItem?.label ?? placeholder;

  return (
    <Dropdown className={styles.dropdownContainer}>
      <Dropdown.Toggle as={CustomToggle()}>{lable}</Dropdown.Toggle>

      <Dropdown.Menu className={`${styles.dropdownMenuContainer} ${className}`}>
        {options.map((option) => {
          const newKeyIndex = uuid();
          return (
            <Dropdown.Item
              className={styles.dropdownMenuItem}
              key={newKeyIndex}
              onClick={() => onChange(option.value)}
            >
              <span className="text-uppercase">{option.label}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

DropDown.propTypes = {
  options: PropTypes.arrayOf(Object),
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

DropDown.defaultProps = {
  options: [],
  value: null,
  placeholder: 'Select',
  className: null,
  onChange() {},
};

export default DropDown;
