import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import WorkerForm from '../../../../components/worker-form/WorkerForm';

function EditWorker() {
  const { workerId } = useParams();
  const { t } = useTranslation('lang');
  const userDetails = useSelector((state) => state.userLogin.userInfo.data);

  return (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.workers')}</title>{' '}
      </Helmet>
      {userDetails.is_admin ? (
        <p className="pageTitle">{t('worker.edit_title')}</p>
      ) : (
        <p className="pageTitle">{t('worker.edit_title_for_worker')}</p>
      )}
      <div className="tabsScrollMainContainer">
        <WorkerForm workerId={workerId} />
      </div>
    </div>
  );
}

export default EditWorker;
