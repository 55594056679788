import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CompanyForm from '../../../components/company-form';
import { getCompany, updateCompany } from '../../../services/company';
import styles from './AddCompany.module.scss';
import COMPANY_DETAILS from './constant';

function EditCompany() {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [company, setCompany] = useState(COMPANY_DETAILS);
  const [isValid, setIsValid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(true);
    updateCompany(companyId, company).then(() => {
      toast.success('Details Updated.');
      navigate('/projects');
    });
  };

  useEffect(() => {
    const fetchCompany = () => {
      getCompany(companyId).then((data) => {
        if (data.status) setCompany(data.data);
        else toast.info('No Data Found.');
      });
    };
    fetchCompany();
  }, [companyId]);

  return (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.projects')}</title>{' '}
      </Helmet>
      <p className="pageTitle">Company Details</p>
      <Card className={styles.companyDetailsContainer}>
        <Card.Body className={`${styles.scrollContainer}`}>
          <CompanyForm
            company={company}
            setCompany={setCompany}
            isValid={isValid}
            handleSubmit={handleSubmit}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditCompany;
