import i18next from 'i18next';

const getTranslatedMessage = (msg) => {
  const messageKey = msg.split(' ').join('_');
  const translatedMessage = i18next.t(`lang:backend_messages.${messageKey}`);
  if (translatedMessage.includes('backend_messages')) return msg;
  return translatedMessage;
};

export default getTranslatedMessage;
