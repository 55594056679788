import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import SidebarLink from '../../components/sidebarLink';
import Footer from '../../components/footer';
import HouseDoorFillSvg from '../../components/svgs/house-door-fill-svg';
import BriefcaseFillSvg from '../../components/svgs/briefcase-fill-svg';
import PeopleSvg from '../../components/svgs/people-svg';
import FileEarmarkRuledFillSvg from '../../components/svgs/file-earmark-ruled-fill-svg';
import styles from './Sidebar.module.scss';
import Logo from '../../components/logo';
import { updateWorker } from '../../services/workers';
import { getUserProfile } from '../../redux/features/users/userSlice';

function Sidebar() {
  const { t } = useTranslation('lang');
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { data } = userInfo;
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);

  const changeLanguage = (lang) => {
    updateWorker(data?.uuid, {
      preferred_language: lang,
    }).then((res) => {
      if (res.status) {
        dispatch(getUserProfile());
        toast.success(t('settings.msgs.saved'));
      }
    });
  };

  function enableActiveTab() {
    const pathName = location.pathname.toLowerCase();
    if (pathName.startsWith('/projects') || pathName.startsWith('/companies')) {
      return setActiveTab('/projects');
    }
    if (pathName.startsWith('/workers')) {
      return setActiveTab('/workers');
    }
    if (pathName.startsWith('/invoice')) {
      return setActiveTab('/invoice');
    }
    return setActiveTab('/');
  }

  useEffect(() => {
    enableActiveTab();
  }, []);

  return (
    <div className={`d-flex flex-column ${styles.sidebar}`}>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.logoMainContainer}`}
      >
        <Logo />
      </div>
      <div className={styles.linkContainer}>
        <SidebarLink
          link="/"
          svg={HouseDoorFillSvg}
          isActive={activeTab === '/'}
          onClick={() => setActiveTab('/')}
          title={t('sidebar.dashboard')}
        />
        {/* <SidebarLink
          link='/dashboard'
          svg={ColumnsGapSvg}
          title={t('sidebar.dashboard')}
        /> */}
        <SidebarLink
          link="/projects"
          svg={BriefcaseFillSvg}
          isActive={activeTab === '/projects'}
          onClick={() => setActiveTab('/projects')}
          title={t('sidebar.projects')}
        />
        <SidebarLink
          link="/workers"
          svg={PeopleSvg}
          isActive={activeTab === '/workers'}
          onClick={() => setActiveTab('/workers')}
          title={t('sidebar.workers')}
        />
        <SidebarLink
          link="/invoice"
          svg={FileEarmarkRuledFillSvg}
          isActive={activeTab === '/invoice'}
          onClick={() => setActiveTab('/invoice')}
          title={t('sidebar.invoicing')}
        />
      </div>
      <div className={styles.footerMainContainer}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.flagContainer}`}
        >
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('english')}
            >
              <img src="/images/flag1.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>

          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('finnish')}
            >
              <img src="/images/flag2.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>

          <div>
            <button  className={`${styles.imgBtn}`} type="button" onClick={() => changeLanguage('russian')}>
              <img src="/images/flag3.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
