import service from './wrapper';

export const getWorkTypePermissions = () => service.get('/work-type-permissions');

export const addWorkTypePermission = (data) => service.post('/work-type-permissions', data);

export const updateWorkTypePermission = (id, data) =>
  service.put(`/work-type-permissions/${id}`, data);

export const deleteWorkTypePermission = (id) => service.delete(`/work-type-permissions/${id}`);
