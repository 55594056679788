import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { v4 as uuid } from 'uuid';
import GCalendar from '../../../../components/g-calendar';
import WorkerTaskOverview from '../../../../components/worker-task-overview';
import styles from './Dashboard.module.scss';
import { getEvents } from '../../../../services/googleCalendar';
import { getCurrentDate, getDateRange, getTime } from '../../../../lib/dateLib';
import PageLoader from '../../../../components/page-loader';
import Loader from '../../../../components/side-loader';
import { getWorkerTasks } from '../../../../services/workers';
import { eventColors } from '../../../../data';
import { Console } from '../../../../utils';

function Dashboard() {
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const userInfo = useSelector((state) => state.userLogin.userInfo.data);
  const navigate = useNavigate();
  const { t } = useTranslation('lang');

  const fetchGoogleCalendarEvents = async (params) => {
    setLoading(true);
    try {
      const events = await getEvents({ calendarId: 'primary', ...params });
      setLoading(false);
      return events?.items?.length > 0 ? events.items : [];
    } catch (err) {
      setLoading(false);
    }
    return [];
  };

  const loadEvents = async () => {
    setPageLoading(true);
    const events = await fetchGoogleCalendarEvents();
    setAllEvents(events);
    setPageLoading(false);
  };

  const fetchWorkersTasks = async (date) => {
    try {
      const { data } = await getWorkerTasks({
        uuid: userInfo.uuid,
        date: getCurrentDate(null, null, date),
      });
      if (data?.items?.length > 0) return data.items;
    } catch (error) {
      Console.log(error);
    }
    return [];
  };

  const prepareTasks = async (date) => {
    const googleEvents = await fetchGoogleCalendarEvents(getDateRange(date));
    const workerTasks = await fetchWorkersTasks(date);
    const populatedTasks = workerTasks.map(({ task }) => {
      const gEvent = googleEvents.find((ge) => task.event_id === ge.id);
      return {
        task_id: task.uuid,
        event_id: task.event_id,
        title: task.name ? task.name : task?.project?.company?.name,
        contact_person: task?.project?.company?.contact_person,
        phone: task?.project?.company?.phone,
        email: task?.project?.company?.email,
        location: gEvent?.location,
        start_time: task?.daily_work_start,
        end_time: task?.daily_work_end,
        description: null,
        date: getCurrentDate(true, null, date),
      };
    });
    setTasks([...populatedTasks]);
  };

  const getDateFromCalendar = async (date) => {
    await fetchGoogleCalendarEvents(getDateRange(date));
    prepareTasks(date);
  };

  useEffect(() => {
    loadEvents();
    getDateFromCalendar();
    fetchWorkersTasks();
  }, []);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div className={`${styles.dashBoardContainer}`}>
      <Helmet>
        <title>Hiukka - {t('page_titles.task')}</title>
      </Helmet>
      {loading && <Loader />}
      <div>
        <GCalendar events={allEvents} getDateFromCalendar={getDateFromCalendar} />
      </div>
      <div>
        {tasks.length !== 0 && <p className={`${styles.titleText}`}>{t('worker_home.my_tasks')}</p>}
        <div>
          {tasks.length !== 0
            ? tasks.map((task, index) => {
                const uniqueKeyIndex = uuid();
                return (
                  <WorkerTaskOverview
                    key={uniqueKeyIndex}
                    title={task.title}
                    contactPerson={task.contact_person}
                    contactNumber={task.phone}
                    address={task.location}
                    startTime={task.start_time ? getTime(task.start_time) : null}
                    endTime={task.end_time ? getTime(task.end_time) : null}
                    jobDescription={task.description}
                    date={task.date}
                    color={eventColors[index < 10 ? index + 1 : 1].background}
                    onClick={() => navigate(`/task/${task.task_id}`)}
                  />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
