import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  workerTaskViewPageTitle: null,
};

const helperSlice = createSlice({
  name: 'helper',
  initialState,
  reducers: {
    updateWorkerTaskViewPageTitle: (state, action) => ({
      ...state,
      workerTaskViewPageTitle: action.payload,
    }),
  },
});

export const { updateWorkerTaskViewPageTitle } = helperSlice.actions;
export default helperSlice;
