import service from './wrapper';

export const getCompanies = async () => service.get('/companies');

export const addCompany = async (data) => service.post('/companies', data);

export const getCompany = (id) => service.get(`/companies/${id}`);

export const updateCompany = (id, data) => service.put(`/companies/${id}`, data);

export const deleteCompany = async (id) => service.delete(`/companies/${id}`);

export const getCompaniesByStatus = (status) => service.get(`/companies?status=${status}`);

export const getCompanyStatuses = () => service.get(`/companies/status`);
