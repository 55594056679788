import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppView from './app';
import AuthView from './auth';
import ForgotPassword from './auth/forgot-password';
import Login from './auth/login';
import  PasswordReset from './auth/password-reset';
import ProtectedRoutes from './ProtectedRoutes';
import ErrorBoundary from '../lib/ErrorBoundary';
import PasswordResetLinkSuccess from './auth/password-reset-link-success'

function View() {
  
  return (
    <Routes>
      <Route path='auth' element={<AuthView />}>
        <Route
          path='login'
          element={
            <ErrorBoundary>
              <Login />
            </ErrorBoundary>
          }
        />
        <Route
          path='invitations/:workerInviteUuid/accept'
          element={
            <ErrorBoundary>
              <PasswordReset />
            </ErrorBoundary>
          }
        />
        <Route
          path='forgot-password'
          element={
            <ErrorBoundary>
              <ForgotPassword />
            </ErrorBoundary>
          }
        />
        <Route
          path='password/reset'
          element={
            <ErrorBoundary>
              <PasswordReset />
            </ErrorBoundary>
          }
        />
        <Route
          path='reset-link/success'
          element={
            <ErrorBoundary>
              <PasswordResetLinkSuccess />
            </ErrorBoundary>
          }
        />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/*' element={<AppView />} />
      </Route>
    </Routes>
  );
};

export default View;
