import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AddUser.module.scss';
import UsersSearchModal from '../users-search-modal';
import PersonPlustFillSvg from '../svgs/person-plus-fill';

function AddUser({ modalRequired, setUser, users = [], onClick, placeHolder }) {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`${styles.addUserCardContainer} d-lg-flex align-items-lg-center justify-content-lg-center`}
    >
      <UsersSearchModal
        show={show}
        users={users}
        handleClose={() => setShow(false)}
        setUser={setUser}
      />
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className={`${styles.addUserBtnContainer} d-flex align-items-center justify-content-lg-center`}
        onClick={() => {
          if (modalRequired) {
            setShow(true);
          }
          if (onClick) {
            onClick();
          }
        }}
      >
        <div className={styles.addUserBtnIcon}>
          <PersonPlustFillSvg />
        </div>

        <p className="fontProximaNova">{placeHolder}</p>
      </div>
    </div>
  );
}

AddUser.propTypes = {
  modalRequired: PropTypes.bool,
  setUser: PropTypes.func,
  users: PropTypes.arrayOf(Object),
  onClick: PropTypes.func,
  placeHolder: PropTypes.string,
};

AddUser.defaultProps = {
  modalRequired: true,
  setUser() {},
  users: [],
  onClick() {},
  placeHolder: null,
};

export default AddUser;
