import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styles from './UsersSearchModal.module.scss';
import SearchBox from '../search-box';
import User from '../user';
import { getImageSrcUrl } from '../../utils';

function UsersSearchModal({ show, users, setUser, handleClose }) {
  const [query, setQuery] = useState('');

  const { t } = useTranslation('lang');

  const handleFilter = (newUsers) =>
    newUsers.filter(
      (user) =>
        user.firstname?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        user.lastname?.toLowerCase().indexOf(query.toLowerCase()) > -1
    );

  return (
    <div className={styles.userModalContainer}>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('settings.basic.msgs.select_user')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`${styles.selectUserContainer}`}>
            <SearchBox onSearch={setQuery} />
            <div className={`${styles.UserCardContainer}`}>
              {handleFilter(users).length !== 0
                ? handleFilter(users).map((user) => (
                    <div
                      role="button"
                      onKeyDown={() => {}}
                      tabIndex={0}
                      key={user.uuid}
                      onClick={() => {
                        setUser(user);
                        handleClose();
                      }}
                    >
                      <User
                        name={`${user.firstname} ${user.lastname}`}
                        area={user.current_nationality ?? ''}
                        status={user.status}
                        tags={user.workTypes}
                        specialTags={user.workTypePermissions}
                        alignImage="left"
                        editBtn={false}
                        profilePic={getImageSrcUrl(user)}
                      />
                    </div>
                  ))
                : t('settings.basic.msgs.no_users')}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

UsersSearchModal.propTypes = {
  users: PropTypes.arrayOf(Object).isRequired,
  show: PropTypes.bool.isRequired,
  setUser: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UsersSearchModal;
