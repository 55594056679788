import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './WorkerTaskCostInputs.module.scss';

function WorkerTaskCostInputs({ kmDriven, onKmDrivenChange, otherCosts, onOtherCostsChange }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.timerContainer}`}>
      <div className="d-flex flex-column align-items-center">
        <p>{t(`worker_home.worker_task_km_cost.driven_km`)}</p>
        <div className={`d-flex align-items-center ${styles.clockContainer}`}>
          <div>
            <Form.Control
              className={`inputField ${styles.inputField}`}
              type="number"
              min={0}
              value={kmDriven}
              placeholder="0"
              onChange={(e) => onKmDrivenChange(e.target.value)}
            />
          </div>
          <div>
            <p>{t(`worker_home.worker_task_km_cost.km`)}</p>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <p>{t(`worker_home.worker_task_km_cost.other_costs`)}</p>
        <div className={`d-flex align-items-center ${styles.clockContainer}`}>
          <div>
            <Form.Control
              className={`inputField ${styles.inputField}`}
              type="number"
              min={0}
              placeholder="0"
              value={otherCosts}
              onChange={(e) => onOtherCostsChange(e.target.value)}
            />
          </div>
          <div>
            <p>€</p>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkerTaskCostInputs.propTypes = {
  kmDriven: PropTypes.number,
  onKmDrivenChange: PropTypes.func,
  otherCosts: PropTypes.number,
  onOtherCostsChange: PropTypes.func,
};

WorkerTaskCostInputs.defaultProps = {
  kmDriven: 0,
  onKmDrivenChange() {},
  otherCosts: 0,
  onOtherCostsChange() {},
};

export default WorkerTaskCostInputs;
