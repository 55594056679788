import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LeftArrowIcon from '../svgs/left-arrow';
import RightArrowIcon from '../svgs/right-arrow';
import styles from './GcalendarToolbar.module.scss';

function GcalendarToolbar({ onNavigate, date, label }) {
  // eslint-disable-next-line no-unused-vars
  const [viewState, setViewState] = useState('month');

  function addMonths(newMonthDate, months) {
    const d = newMonthDate.getDate();
    newMonthDate.setMonth(newMonthDate.getMonth() + months);
    if (newMonthDate.getDate() !== d) {
      newMonthDate.setDate(0);
    }

    return newMonthDate;
  }

  function addWeeks(newWeekDate, weeks) {
    newWeekDate.setDate(newWeekDate.getDate() + 7 * weeks);
    return newWeekDate;
  }

  function addDays(newDayDate, days) {
    newDayDate.setDate(newDayDate.getDate() + days);

    return newDayDate;
  }

  const goToBack = () => {
    if (viewState === 'month') {
      onNavigate('prev', addMonths(date, -1));
    } else if (viewState === 'week') {
      onNavigate('prev', addWeeks(date, -1));
    } else {
      onNavigate('prev', addDays(date, -1));
    }
  };

  const goToNext = () => {
    if (viewState === 'month') {
      onNavigate('next', addMonths(date, +1));
    } else if (viewState === 'week') {
      onNavigate('next', addWeeks(date, +1));
    } else {
      onNavigate('next', addDays(date, +1));
    }
  };

  return (
    <div
      className={`${styles.calendarBtnContainer} d-flex align-items-center justify-content-between`}
    >
      <div>
        <button type="button" className={styles.calendarBtn} onClick={goToBack}>
          <LeftArrowIcon className={styles.calendarIcon} />
        </button>
      </div>
      <div>
        <label htmlFor="DateLabel" className={styles.calendarLabel}>
          {label}
        </label>
      </div>
      <div>
        <button type="button" className={styles.calendarBtn} onClick={goToNext}>
          <RightArrowIcon className={styles.calendarIcon} />
        </button>
      </div>
    </div>
  );
}

GcalendarToolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  label: PropTypes.string.isRequired,
};

export default GcalendarToolbar;
