/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import Button from '../button';
import formToInitialValues from './helper';
import useConstants from './useConstants';
import styles from './OtherDetails.module.scss';
import { Console } from '../../utils';

function OtherDetails({
  workerId,
  otherDetails: newOtherDetails,
  handleSubmit,
  isFieldDisabled,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const { BINARY_STATUS, OTHER_DETAILS: form } = useConstants();
  const [otherDetails, setOtherDetails] = useState(formToInitialValues(form));
  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);

  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.other_details) {
        fieldsToUpdate.other_Details = [];
        return;
      }
      if (!fieldsToUpdate.other_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          other_details: [...fieldsToUpdate.other_details, key],
        }));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let res = null;
    const {
      is_returning_to_previous_employer: isReturningToPreviousEmployer,
      has_accomodation_in_finland: hasAccommodationInFinland,
      street_address: streetAddress,
      zip,
      city,
      additional_information: additionalInformation,
    } = otherDetails;
    const requestBody = {
      other_details: {
        is_returning_to_previous_employer: isReturningToPreviousEmployer,
        has_accomodation_in_finland: hasAccommodationInFinland,
        additional_information: additionalInformation,
        Addresses: [{ street_address: streetAddress, zip, city }],
      },
      fieldsToUpdate: JSON.stringify(fieldsToUpdate),
    };
    if (newOtherDetails) {
      res = updateWorkerDetails(workerId, requestBody);
    } else {
      res = addWorkerDetails(workerId, requestBody);
    }
    res
      .then(({ status }) => {
        if (status) handleSubmit(6);
      })
      .catch((err) => {
        Console.log(err);
      });
  };

  const setInitialValues = (newOtherDetailsFromArgs) => {
    const {
      is_returning_to_previous_employer: isReturningToPreviousEmployer,
      has_accomodation_in_finland: hasAccommodationInFinland,
      Addresses,
      additional_information: additionalInformation,
    } = newOtherDetailsFromArgs;
    setOtherDetails({
      is_returning_to_previous_employer: isReturningToPreviousEmployer,
      has_accomodation_in_finland: hasAccommodationInFinland,
      additional_information: additionalInformation,
      ...Addresses[0],
    });
  };

  useEffect(() => {
    if (newOtherDetails) {
      setInitialValues(newOtherDetails);
    }
  }, [newOtherDetails]);

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (newOtherDetails) {
        setInitialValues(newOtherDetails);
      } else {
        setOtherDetails(formToInitialValues(form));
      }
    }
  }, [resetForm.resetId]);

  return (
    <div>
      <Form className="scrollContainer" onSubmit={onSubmit}>
        <div className={`d-flex flex-column h-100 ${styles.otherContainer}`}>
          <div className="d-lg-flex justify-content-lg-between">
            <div className={`${styles.firstColumn}`}>
              <div className={`d-lg-flex ${styles.flexContainer}`}>
                <div>
                  <p className={`${styles.label}`}>
                    {form.is_returning_to_previous_employer.label}
                  </p>
                </div>
                <div>
                  <p className={`${styles.label}`}>
                    {form.is_returning_to_previous_employer.question}
                  </p>
                  <div
                    className={`d-flex justify-content-between justify-content-lg-start ${styles.flexDiv}`}
                  >
                    {BINARY_STATUS.map((option, index) => (
                      <div key={`is_returning_to_previous_employer${index}`}>
                        <label
                          htmlFor={`is_returning_to_previous_employer${index}`}
                          className="radioContainer"
                        >
                          {option
                            ? t('worker.details.work.fields.yes')
                            : t('worker.details.work.fields.no')}
                          <Form.Check.Input
                            checked={otherDetails.is_returning_to_previous_employer === option}
                            disabled={isFieldDisabled(
                              'other_details',
                              'is_returning_to_previous_employer'
                            )}
                            id={`is_returning_to_previous_employer${index}`}
                            name="isReturningToPreviousEmployer"
                            type="radio"
                            onChange={() => {
                              updateFields('is_returning_to_previous_employer');
                              setOtherDetails({
                                ...otherDetails,
                                is_returning_to_previous_employer: option === BINARY_STATUS[0],
                              });
                            }}
                          />
                          <span className="radioIcon" />
                        </label>
                      </div>
                    ))}
                  </div>
                  <p className={`${styles.label}`}>{form.has_accomodation_in_finland.question}</p>
                  <div
                    className={`d-flex justify-content-between justify-content-lg-start ${styles.flexDiv}`}
                  >
                    {BINARY_STATUS.map((option, index) => (
                      <div key={`has_accomodation_in_finland${index}`}>
                        <label
                          htmlFor={`has_accomodation_in_finland${index}`}
                          className="radioContainer"
                        >
                          {option
                            ? t('worker.details.work.fields.yes')
                            : t('worker.details.work.fields.no')}
                          <Form.Check.Input
                            checked={otherDetails.has_accomodation_in_finland === option}
                            disabled={isFieldDisabled(
                              'other_details',
                              'has_accomodation_in_finland'
                            )}
                            id={`has_accomodation_in_finland${index}`}
                            name="haveAccommodation"
                            type="radio"
                            onChange={() => {
                              updateFields('has_accomodation_in_finland');
                              setOtherDetails({
                                ...otherDetails,
                                has_accomodation_in_finland: option === BINARY_STATUS[0],
                              });
                            }}
                          />
                          <span className="radioIcon" />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {otherDetails.has_accomodation_in_finland === BINARY_STATUS[0] && (
                <div>
                  {' '}
                  <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
                    <div>
                      <p className={`${styles.label}`}>{form.street_address.label}</p>
                    </div>
                    <div>
                      <Form.Control
                        className={`inputField ${styles.inputField}`}
                        required
                        disabled={isFieldDisabled('other_details', 'street_address')}
                        value={otherDetails.street_address}
                        placeholder={form.street_address.placeholder}
                        onChange={(e) => {
                          updateFields('street_address');
                          setOtherDetails({
                            ...otherDetails,
                            street_address: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={`d-lg-flex align-items-lg-center ${styles.cityZipContainer}`}>
                    <div>
                      <p className={`${styles.label}`}>{form.zip.label}</p>
                    </div>

                    <div className="align-items-lg-center d-lg-flex justify-content-lg-between">
                      <div>
                        <Form.Control
                          disabled={isFieldDisabled('other_details', 'zip')}
                          className={`inputField ${styles.inputField}`}
                          required
                          type={form.zip.type}
                          value={otherDetails.zip}
                          placeholder={form.zip.placeholder}
                          onChange={(e) => {
                            updateFields('zip');
                            setOtherDetails({
                              ...otherDetails,
                              zip: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <p className={`${styles.label}`}>{form.city.label}</p>
                      <div>
                        <Form.Control
                          className={`inputField ${styles.inputField}`}
                          required
                          disabled={isFieldDisabled('other_details', 'city')}
                          value={otherDetails.city}
                          placeholder={form.city.placeholder}
                          onChange={(e) => {
                            updateFields('city');
                            setOtherDetails({
                              ...otherDetails,
                              city: e.target.value.replace(/[0-9]/g, ''),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={`${styles.secondColumn}`}>
              <p className={`${styles.label}`}>{form.additional_information.label}</p>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                as="textarea"
                disabled={isFieldDisabled('other_details', 'additional_information')}
                placeholder={form.additional_information.placeholder}
                value={otherDetails.additional_information}
                rows={8}
                onChange={(e) => {
                  updateFields('additional_information');
                  setOtherDetails({
                    ...otherDetails,
                    additional_information: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="btnContainer">
            <Button type="submit" variant="saveBtn">
              {t('buttons.save_and_continue')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

OtherDetails.propTypes = {
  workerId: PropTypes.string,
  otherDetails: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

OtherDetails.defaultProps = {
  workerId: null,
  otherDetails: null,
  handleSubmit() {},
  isFieldDisabled() {},
  resetForm: null,
};

export default OtherDetails;
