const TASK_DETAILS = {
  name: '',
  industry_id: '',
  work_type_id: '',
  no_of_workers: 0,
  contract_period_start: '',
  contract_period_end: '',
  daily_work_start: '',
  daily_work_end: '',
  hourly_rate: 0,
  add_workers: [],
  work_type_permissions: [],
};

export default TASK_DETAILS;
