/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import userLoginReducer from '../redux/features/users/userSlice';
import worker from '../redux/features/workers/worker';
import helper from '../redux/features/helper/helperSlice';

const store = configureStore({
  reducer: {
    userLogin: userLoginReducer.reducer,
    worker: worker.reducer,
    helper: helper.reducer,
  },
});

export default store;
