import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { v4 as uuid } from 'uuid';
import styles from './Home.module.scss';
import OverviewCard from '../../../components/overview-card';
import LastestJobs from '../../../components/latest-jobs';
import Tasklist from '../../../components/tasklist';
import EarningOverview from '../../../components/earning-overview';
import Gcalendar from '../../../components/g-calendar';
import SideLoader from '../../../components/side-loader';
import PageLoader from '../../../components/page-loader';
import useConstants from './useConstants';
import DropDown from '../../../components/dropdown';
import { getDateRange } from '../../../lib/dateLib';
import { getEvents } from '../../../services/googleCalendar';
import { getWorkerStats, getWorkers } from '../../../services/workers';
import { Console } from '../../../utils';

function Home() {
  const { t } = useTranslation('lang');
  const { filterOptions, OVERVIEW_CARD } = useConstants();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [overViewCards, setOverviewCards] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [dateRange, setDateRange] = useState(getDateRange());
  const [filterSelectedValue, setFilterSelectedValue] = useState(filterOptions[0].value);
  const [users, setUsers] = useState([]);

  const fetchAllGoogleCalendarEvents = async ({ users: newUsers, params }) => {
    setLoading(true);
    const newEvents = [];
    try {
      const calls = newUsers.map((user) => getEvents({ calendarId: user.email, ...params }));
      const data = await Promise.allSettled(calls);
      const filteredEvents = data
        .filter((d) => d.status === 'fulfilled')
        .map((ev) => ev.value.items);
      filteredEvents.forEach((event) => {
        event.forEach((ev) => {
          newEvents.push(ev);
        });
      });
    } catch (error) {
      Console.log(error);
    }
    setLoading(false);
    return newEvents;
  };

  const fetchAllGoogleCalendarEventsByDate = async (workers, params) => {
    setLoading(true);
    try {
      const eventsFromResponse = await fetchAllGoogleCalendarEvents({ users: workers, params });
      setEvents(eventsFromResponse);
    } catch (error) {
      Console.log(error);
    }
    setLoading(false);
  };

  const fetchDomainUsers = async () => {
    try {
      const limit = 1000;
      let workers = [];
      const initialWorkers = await getWorkers(1, limit, undefined, 'true');
      workers = [...workers, ...initialWorkers.data];
      const totalPages = Math.ceil(initialWorkers.meta.totalResults / limit);
      const calls = [];
      for (let i = 2; i <= totalPages; i += 1) {
        calls.push(getWorkers(i, limit));
      }
      const newWorkersResponses = await Promise.all(calls);
      newWorkersResponses.forEach((res) => {
        res.data.forEach((worker) => workers.push(worker));
      });
      const populatedWorkers = workers
        .map((worker) => ({ uuid: worker.uuid, email: worker.email, status: worker.status }))
        .filter((w) => w?.status?.toLowerCase() === 'active');
      setUsers(populatedWorkers);
      const eventsFromMethod = await fetchAllGoogleCalendarEvents({ users: populatedWorkers });
      setAllEvents(eventsFromMethod);
      fetchAllGoogleCalendarEventsByDate(populatedWorkers, getDateRange());
    } catch (error) {
      Console.log(error);
    }
    setPageLoading(false);
  };

  const getWorkerStatistics = async (filter = 'month') => {
    const stats = await getWorkerStats(filter);
    setOverviewCards(stats.data);
  };

  const getDateFromCalendar = (date) => {
    setDateRange(getDateRange(date));
    fetchAllGoogleCalendarEventsByDate(users, getDateRange(date));
  };

  useEffect(() => {
    getWorkerStatistics();
    fetchDomainUsers();
  }, []);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div className={`${styles.homePageContainer}`}>
      <Helmet>
        <title>Hiukka - {t('page_titles.dashboard')}</title>{' '}
      </Helmet>
      {loading && <SideLoader />}
      <div className={`${styles.pageTitleContainer} d-flex justify-content-between`}>
        <div>
          <EarningOverview />
        </div>
        <div>
          <DropDown
            placeholder={filterOptions[0].label}
            value={filterSelectedValue}
            options={filterOptions}
            onChange={(val) => {
              setFilterSelectedValue(val);
              getWorkerStatistics(val);
            }}
          />
        </div>
      </div>

      <div>
        <div className={`d-lg-flex  justify-content-between ${styles.overviewCardsContainer}`}>
          {overViewCards && overViewCards.length > 0
            ? overViewCards.map((card) => {
                const newKeyIndex = uuid();
                return (
                  <div key={newKeyIndex}>
                    <OverviewCard
                      title={OVERVIEW_CARD[card.name].title}
                      percentage={card.percentage}
                      color={OVERVIEW_CARD[card.name].color}
                      changeValue={card.name !== 'pending' && card.change ? card.change : 0}
                      count={card.count}
                      showCircularProgress={card.name !== 'pending'}
                    />
                  </div>
                );
              })
            : t('common_messages.no_data')}
        </div>
      </div>
      <div className={`${styles.calendarListContainer} d-lg-flex justify-content-lg-between`}>
        <div className={styles.calendarContainer}>
          <Gcalendar events={allEvents} getDateFromCalendar={getDateFromCalendar} />
        </div>
        <div className={styles.taskListContainer}>
          <Tasklist
            events={{ name: 'gevents', data: events }}
            selectedDate={dateRange.timeMin.substring(0, 10)}
          />
        </div>
      </div>
      <div className={`${styles.LatestJobsContainer}`}>
        <div>
          <LastestJobs />
        </div>
      </div>
    </div>
  );
}

export default Home;
