import { useTranslation } from 'react-i18next';

const useConstants = () => {
  const { t } = useTranslation('lang');
  const filterOptions = [
    { id: 1, label: t('home.dropdown.this_month'), value: 'month' },
    { id: 2, label: t('home.dropdown.this_year'), value: 'year' },
    { id: 3, label: t('home.dropdown.this_week'), value: 'week' },
    { id: 4, label: t('home.dropdown.today'), value: 'today' },
  ];

  const OVERVIEW_CARD = {
    active: {
      title: t('home.overview_cards.active_workers'),
      color: '#0090FF',
    },

    available: {
      title: t('home.overview_cards.available_workers'),
      color: '#3A36DB',
    },
    pending: {
      title: t('home.overview_cards.workers_pending_permit'),
      color: '#D56730',
    },
  };

  return { filterOptions, OVERVIEW_CARD };
};

export default useConstants;
