/* eslint-disable import/no-cycle */
import service from './wrapper';

export const login = async (email, password) => service.post('/auth/login', { email, password });

export const forgotPassword = async (email) => service.post('/auth/password/forgot', { email });

export const verifyLink = async (resetId) =>
  service.get(`/auth/password/reset`, { params: { reset_id: resetId } });

export const resetPassword = (password) => service.put('/auth/password/update', password);

export const updateInvitePassword = (data) => service.post('/users/invite/password-update', data);

export const getGoogleAuthUrl = async (inviteId) =>
  service.get(`/google/authorization-url/?inviteid=${inviteId}`);

export const getGoogleRefreshToken = async (code, workerId) =>
  service.get(`/google/token/?code=${code}&workerId=${workerId}`);

export const getNewAccessToken = async (workerId) => service.post(`/google/token/`, { workerId });
