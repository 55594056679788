import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

function Button(props) {
  const { children, variant, className, icon, disabled, onClick, type } = props;

  let btnVariantClass = '';

  switch (variant) {
    case 'saveBtn':
      btnVariantClass = styles.saveBtn;
      break;
    case 'activeBtn':
      btnVariantClass = styles.activeBtn;
      break;
    case 'warnBtn':
      btnVariantClass = styles.warnBtn;
      break;
    case 'addBtnBlue':
      btnVariantClass = styles.addBtnBlue;
      break;
    case 'addBtnOrange':
      btnVariantClass = styles.addBtnOrange;
      break;
    case 'backBtn':
      btnVariantClass = styles.backBtn;
      break;
    case 'addBtn':
      btnVariantClass = styles.addBtn;
      break;
    case 'editBtn':
      btnVariantClass = styles.editBtn;
      break;
    case 'delBtn':
      btnVariantClass = styles.delBtn;
      break;
    case 'addBtnLg':
      btnVariantClass = styles.addBtnLg;
      break;
    case 'iconAddBtn':
      btnVariantClass = styles.iconAddBtn;
      break;
    case 'iconEditBtn':
      btnVariantClass = styles.iconEditBtn;
      break;
    case 'iconDelBtn':
      btnVariantClass = styles.iconDelBtn;
      break;
    default:
      btnVariantClass = '';
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${styles.projectBtn} ${className} ${btnVariantClass} ${
        disabled ? styles.disabled : ''
      } `}
      disabled={disabled || false}
      onClick={onClick}
    >
      {icon || ''}
      {children}
    </button>
  );
}

Button.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  variant: null,
  className: null,
  disabled: false,
  icon: null,
  children: null,
  onClick() {},
  type: 'button',
};

export default Button;
