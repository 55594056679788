/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import Button from '../button';
import FormDatePicker from '../form-date-picker';
import useConstants from './useConstants';
import styles from './WorkContract.module.scss';
import { Console } from '../../utils';

function WorkContract({
  workerId,
  workContract: newWorkContractDetails,
  handleSubmit,
  isFieldDisabled,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const { BINARY_STATUS, EMPLOYER_DETAILS } = useConstants();

  const [workContract, setWorkContract] = useState({
    is_working_seasonally_in_finland_12_months: BINARY_STATUS[1],
    worker_employer_contracts: [],
  });

  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);

  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.work_contract_details) {
        fieldsToUpdate.work_contract_details = [];
      }
      if (!fieldsToUpdate.work_contract_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          work_contract_details: [...fieldsToUpdate.work_contract_details, key],
        }));
      }
    }
  };

  const updateWorked = (val) => {
    let employers = [];
    updateFields('is_working_seasonally_in_finland_12_months');
    updateFields('worker_employer_contracts');
    if (val === BINARY_STATUS[0]) {
      employers = [...employers, { ...EMPLOYER_DETAILS }];
    }
    setWorkContract({
      ...workContract,
      is_working_seasonally_in_finland_12_months: val,
      worker_employer_contracts: employers,
    });
  };

  const updateEmployer = (index, field, value) => {
    updateFields(field);
    const employers = [...workContract.worker_employer_contracts];
    employers[index][field] = value;
    setWorkContract({ ...workContract, worker_employer_contracts: employers });
  };

  const addEmployer = () => {
    const employers = [...workContract.worker_employer_contracts, { ...EMPLOYER_DETAILS }];
    setWorkContract({ ...workContract, worker_employer_contracts: employers });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let res = null;
    const requestBody = {
      work_contract_details: workContract,
      fieldsToUpdate: JSON.stringify(fieldsToUpdate),
    };
    if (newWorkContractDetails) {
      res = updateWorkerDetails(workerId, requestBody);
    } else {
      res = addWorkerDetails(workerId, requestBody);
    }
    res
      .then(({ status }) => {
        if (status) handleSubmit(5);
      })
      .catch((err) => {
        Console.log(err);
      });
  };
  useEffect(() => {
    if (newWorkContractDetails) {
      setWorkContract(newWorkContractDetails);
    }
  }, [newWorkContractDetails]);

  useEffect(() => {
    updateFields('is_working_seasonally_in_finland_12_months');
  }, []);

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (newWorkContractDetails) {
        setWorkContract(newWorkContractDetails);
      } else {
        setWorkContract({
          is_working_seasonally_in_finland_12_months: BINARY_STATUS[1],
          worker_employer_contracts: [],
        });
      }
    }
  }, [resetForm.resetId]);

  return (
    <div>
      <Form className="scrollContainer" onSubmit={onSubmit}>
        <div className={`d-flex flex-column h-100 ${styles.contractContainer}`}>
          <div className={`d-lg-flex ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{t('worker.details.work.fields.history')}</p>
            <div>
              <p className={`${styles.label}`}>{t('worker.details.work.fields.history_msg')}</p>
              <div
                className={`d-flex justify-content-between justify-content-lg-start ${styles.flexDiv}`}
              >
                {BINARY_STATUS.map((option, index) => (
                  <div key={index}>
                    <label
                      htmlFor={`is_working_seasonally_in_finland_12_months${index}`}
                      className="radioContainer"
                    >
                      {option
                        ? t('worker.details.work.fields.yes')
                        : t('worker.details.work.fields.no')}
                      <Form.Check.Input
                        checked={workContract.is_working_seasonally_in_finland_12_months === option}
                        name="is_working_seasonally_in_finland_12_months"
                        disabled={isFieldDisabled(
                          'work_contract_details',
                          'is_working_seasonally_in_finland_12_months'
                        )}
                        id={`is_working_seasonally_in_finland_12_months${index}`}
                        type="radio"
                        onChange={() => {
                          updateWorked(option === BINARY_STATUS[0]);
                        }}
                      />
                      <span className="radioIcon" />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {workContract.is_working_seasonally_in_finland_12_months === BINARY_STATUS[0] ? (
            <div>
              {workContract.worker_employer_contracts.map((employer, index) => (
                <div className="" key={index}>
                  <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
                    <p className={`${styles.label}`}>
                      {' '}
                      {t('worker.details.work.fields.history_mg2')}
                    </p>
                    <div>
                      <p className={`${styles.label}`}>
                        {t('worker.details.work.fields.employer')}
                      </p>
                      <div>
                        <Form.Control
                          className={`inputField ${styles.inputField}`}
                          required
                          disabled={isFieldDisabled('work_contract_details', 'employer_name')}
                          value={employer.employer_name}
                          placeholder="Yritys Oy"
                          onChange={(e) => updateEmployer(index, 'employer_name', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`d-lg-flex align-items-lg-center ${styles.dateMainContainer}`}>
                    <p className={`d-none d-lg-block ${styles.label}`}>
                      {t('worker.details.work.fields.start')}
                    </p>
                    <div
                      className={`d-flex justify-content-between align-items-lg-center ${styles.dateContainer}`}
                    >
                      <div>
                        <p className={`d-lg-none ${styles.label}`}>
                          {t('worker.details.work.fields.start')}
                        </p>
                        <div>
                          <FormDatePicker
                            disabled={isFieldDisabled('work_contract_details', 'start_date')}
                            className={`inputField ${styles.inputField}`}
                            value={employer.start_date}
                            onChange={(val) => updateEmployer(index, 'start_date', val)}
                          />
                        </div>
                      </div>
                      <div className="d-lg-flex align-items-lg-center justify-content-lg-between">
                        <p className={`${styles.label}`}> {t('worker.details.work.fields.end')}</p>
                        <div>
                          <FormDatePicker
                            disabled={isFieldDisabled('work_contract_details', 'end_date')}
                            className={`inputField ${styles.inputField}`}
                            value={employer.end_date}
                            onChange={(val) => updateEmployer(index, 'end_date', val)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {workContract.is_working_seasonally_in_finland_12_months === BINARY_STATUS[0] && (
            <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
              <p className={`d-none d-lg-block ${styles.label}`} />
              <div className={`${styles.addBtnContainer}`}>
                <Button
                  type="button"
                  onClick={() => addEmployer()}
                  disabled={isFieldDisabled(
                    'work_contract_details',
                    'is_working_seasonally_in_finland_12_months'
                  )}
                >
                  + {t('buttons.another')}
                </Button>
              </div>
            </div>
          )}
          <div className="btnContainer">
            <Button type="submit" variant="saveBtn">
              {t('buttons.save_and_continue')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

WorkContract.propTypes = {
  workerId: PropTypes.string,
  workContract: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

WorkContract.defaultProps = {
  workerId: null,
  workContract: null,
  handleSubmit() {},
  isFieldDisabled() {},
  resetForm: null,
};

export default WorkContract;
