/** @format */

import React from 'react';

function PersonPlusFillSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 124">
      <g id="Group_77" data-name="Group 77" transform="translate(0 0.299)">
        <g
          id="Ellipse_8"
          data-name="Ellipse 8"
          transform="translate(0 -0.299)"
          fill="none"
          stroke="#1ad598"
          strokeWidth="3"
        >
          <circle cx="62" cy="62" r="62" stroke="none" />
          <circle cx="62" cy="62" r="60.5" fill="none" />
        </g>
        <g
          id="Icon_ionic-md-person-add"
          data-name="Icon ionic-md-person-add"
          transform="translate(45.125 48.5)"
        >
          <path
            id="Path_75"
            data-name="Path 75"
            d="M21.375,18a6.75,6.75,0,1,0-6.75-6.75A6.77,6.77,0,0,0,21.375,18Zm0,3.375c-4.472,0-13.5,2.278-13.5,6.75V31.5h27V28.125C34.875,23.653,25.847,21.375,21.375,21.375Z"
            transform="translate(-1.125 -4.5)"
            fill="#1ad598"
          />
          <path
            id="Path_76"
            data-name="Path 76"
            d="M7.875,15.75v-4.5H5.625v4.5h-4.5V18h4.5v4.5h2.25V18h4.5V15.75Z"
            transform="translate(-1.125 -4.5)"
            fill="#1ad598"
          />
        </g>
      </g>
    </svg>
  );
}

export default PersonPlusFillSvg;
