import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tag.module.scss';

function Tag(props) {
  const { children, className, selected = false } = props;
  return (
    <div
      className={`${styles.tag}  ${styles.whiteTag} ${className} ${selected && styles.selected}`}
    >
      {children}
    </div>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
};

Tag.defaultProps = {
  className: null,
  selected: false,
};

export default Tag;
