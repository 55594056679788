/* eslint-disable import/no-cycle */
import service from './wrapper';

export const getWorkers = (page, limit, status, googleVerified) =>
  service.get('/workers', { params: { page, limit, worker_status: status, googleVerified } });

export const addWorker = (data) => service.post('/workers', data);

export const getWorker = (workerId) => service.get(`/workers/${workerId}`);

export const updateWorker = (id, data) => service.put(`/workers/${id}`, data);

export const deleteWorker = (id) => service.delete(`/workers/${id}`);

export const addWorkerDetails = (workerId, data) =>
  service.post(`/workers/${workerId}/details`, data);

export const getWorkerDetails = (workerId) => service.get(`/workers/${workerId}/details`);

export const updateWorkerDetails = (id, data) => service.put(`/workers/${id}/details`, data);

export const getWorkerStatus = () => service.get('/workers/status');

export const inviteWorker = (data) => service.post('/workers/invite', data);

export const getWorkerStats = (filter = 'month') => service.get(`/workers/stats/?filter=${filter}`);

export const getWorkerId = (uuid) => service.get(`/workers/${uuid}/get-user-id`);

export const getWorkerTasks = ({ uuid, date, page, limit, order, orderby, submitted }) =>
  service.get(`/workers/${uuid}/tasks`, {
    params: { date, page, limit, order, orderby, submitted },
  });

export const getWorkerTask = (workerId, taskId) =>
  service.get(`/workers/${workerId}/tasks/${taskId}`);

export const submitTimeSheet = (taskId, workerId, data) =>
  service.post(`/tasks/${taskId}/workers/${workerId}/time-entries`, data);

export const updateTimeSheet = (taskId, workerId, timesheetId, data) =>
  service.put(`/tasks/${taskId}/workers/${workerId}/time-entries/${timesheetId}`, data);

export const getTimeSheets = (taskId, workerId, from, to) =>
  service.get(`/tasks/${taskId}/workers/${workerId}/time-entries?from=${from}&to=${to}`);

export const getTimeSheetDetails = (taskId, workerId, timesheetId) =>
  service.get(`/tasks/${taskId}/workers/${workerId}/time-entries/${timesheetId}`);

export const getConsentStatus = (workerId) => service.get(`/workers/${workerId}/consent/status`);

export const mapWorkTypesToWorker = (workerId, data) =>
  service.post(`/workers/${workerId}/work-types`, data);

export const mapWorkTypePermissionsToWorker = (workerId, data) =>
  service.post(`/workers/${workerId}/work-type-permissions`, data);
