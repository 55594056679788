import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import DropDown from '../../../components/dropdown';
import Task from '../../../components/task';
import styles from './Project.module.scss';
import { getProject, addProject, updateProject } from '../../../services/project';
import { getCompany } from '../../../services/company';
import Button from '../../../components/button';
import PageLoader from '../../../components/page-loader';
import ClipboardPlusSvg from '../../../components/svgs/clipboard-plus';
import useConstants from './useConstants';

function Project() {
  const navigate = useNavigate();
  const { t } = useTranslation('lang');
  const { PROJECT_DETAILS, statuses, contractTypes } = useConstants();
  const { companyId, projectId } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState(PROJECT_DETAILS);
  const [company, setCompany] = useState({});

  const validateCompanyId = () => {
    getCompany(companyId)
      .then((res) => {
        if (!res.status) {
          toast.info(t('company.msgs.invalid'));
          navigate('/projects');
        }
        setCompany(res.data);
      })
      .catch(() => {
        navigate('/projects');
      });
  };

  const fetchProjectDetails = () => {
    setPageLoading(true);
    getProject(companyId, projectId)
      .then((res) => {
        if (res.status) {
          setProjectDetails({
            ...res.data,
            contract_type: res.data.contract_type.uuid,
          });
          setPageLoading(false);
        } else {
          toast.info(t('project.msgs.invalid'));
          navigate('/projects');
          setPageLoading(false);
        }
      })
      .catch(() => {
        setPageLoading(false);
      });
  };

  const handleProject = () => {
    if (projectDetails.name === '' || !projectDetails.name) {
      toast.error(t('project.view.msgs.name_required'));
    } else if (projectDetails.status === '' || !projectDetails.status) {
      toast.error(t('project.view.msgs.status_required'));
    } else if (projectDetails.contract_type === '' || !projectDetails.contract_type) {
      toast.error(t('project.view.msgs.contract_type_required'));
    } else if (projectId) {
      updateProject(companyId, projectId, projectDetails)
        .then((res) => {
          if (res.status) {
            toast.success(t('project.view.msgs.updated'));
            navigate('/projects');
          }
        })
        .catch(() => {});
    } else {
      addProject(companyId, projectDetails)
        .then((res) => {
          if (res.status) {
            toast.success(t('project.view.msgs.added'));
            navigate(`/companies/${companyId}/projects/${res.data.uuid}`);
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    validateCompanyId();
    if (projectId) {
      fetchProjectDetails();
    }
  }, [projectId]);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.projects')}</title>{' '}
      </Helmet>
      <p className="pageTitle">{projectDetails.name}</p>
      <p className="pageDec">{company.name}</p>
      <div className="tabsScrollMainContainer">
        <div className="scrollContainer">
          <div className="h-100 d-lg-flex flex-lg-column">
            <div className={`${styles.headerContainer}`}>
              <div className="d-lg-flex align-items-lg-center">
                <p className={`${styles.titleText}`}>{t('project.view.name')}</p>
                <div>
                  <FormControl
                    className={`inputField ${styles.inputField}`}
                    value={projectDetails.name}
                    onChange={(e) =>
                      setProjectDetails({
                        ...projectDetails,
                        name: e.target.value,
                      })
                    }
                    placeholder={t(`project.name_input`)}
                  />
                </div>
              </div>
              <div className="d-lg-flex align-items-lg-center">
                <p className={`${styles.titleText}`}>{t('project.view.requested')}</p>
                <p className={`${styles.headerDec}`}>
                  {projectId && projectDetails?.tasks?.length !== 0
                    ? t('project.view.msgs.requested')
                    : t('project.view.msgs.no_tasks')}
                </p>
              </div>
            </div>
            <div className={`d-lg-flex justify-content-lg-between ${styles.projectContainer}`}>
              <div className={`${styles.titleText}`} />
              <div>
                {projectId &&
                  projectDetails?.tasks?.map((task) => {
                    const newKeyIndex = uuid();
                    return (
                      <Task
                        key={newKeyIndex}
                        task={task}
                        companyId={companyId}
                        projectId={projectId}
                      />
                    );
                  })}
              </div>
              <div>
                <div className={`${styles.addTaskContainer}`}>
                  <div
                    className={`d-flex align-items-center justify-content-center ${
                      styles.addBtnContainer
                    } ${!projectId ? styles.disabledAddBtnContainer : ''}`}
                    onClick={() => {
                      if (projectId)
                        navigate(`/companies/${companyId}/projects/${projectId}/task/new`);
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <div className={`${styles.btnIcon}`}>
                      <ClipboardPlusSvg />
                    </div>
                    <p>{t('project.view.add_task')}</p>
                  </div>
                </div>
                <div className={`d-flex justify-content-between ${styles.taskDropdownContainer}`}>
                  <div>
                    <p>{t('project.view.status_p')} :</p>
                    <DropDown
                      placeholder={t('project.view.status_dropdown.select')}
                      options={statuses}
                      value={projectDetails.status}
                      onChange={(val) => setProjectDetails({ ...projectDetails, status: val })}
                    />
                  </div>
                  <div>
                    <p>{t('project.view.contract_p')}:</p>
                    <DropDown
                      placeholder={t('project.view.contract_dropdown.select')}
                      options={contractTypes}
                      value={projectDetails.contract_type}
                      onChange={(val) =>
                        setProjectDetails({
                          ...projectDetails,
                          contract_type: val,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`btnContainer d-lg-flex justify-content-between ${styles.btnContainer}`}
            >
              <div>
                <Button variant="saveBtn" onClick={() => handleProject()}>
                  {projectId ? t('buttons.update_and_save') : t('buttons.save')}
                </Button>
              </div>
              <div>
                <Button variant="backBtn" onClick={() => navigate('/projects')}>
                  {t('buttons.back')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
