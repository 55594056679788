/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import Button from '../button';
import useConstants from './useConstants';
import styles from './Consent.module.scss';
import { Console } from '../../utils';

const initialConsent = {
  has_given_consent: null,
  signature: 'Worker',
};

function Consent({ workerId, consent: consentDetails, handleSubmit, resetForm }) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const { BINARY_STATUS } = useConstants();

  const [consent, setConsent] = useState({ ...initialConsent });
  const userInfo = useSelector((state) => state.userLogin.userInfo.data);

  const onSubmit = async (e) => {
    e.preventDefault();
    let res = null;
    if (consentDetails) {
      await updateWorkerDetails(workerId, { consent_details: consent });
      navigate('/');
      return;
    }
    res = addWorkerDetails(workerId, { consent_details: consent });
    res
      .then(({ status }) => {
        if (status) handleSubmit(8);
        if (!userInfo.is_admin) navigate('/');
      })
      .catch((error) => {
        Console.log(error);
      });
  };
  useEffect(() => {
    if (consentDetails) setConsent(consentDetails);
  }, [consentDetails]);

  //  This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (consentDetails) {
        setConsent(consentDetails);
      } else {
        setConsent({ ...initialConsent });
      }
    }
  }, [resetForm.resetId]);

  return (
    <div className="scrollContainer">
      <Form className={`d-flex flex-column h-100 ${styles.consentContainer}`} onSubmit={onSubmit}>
        <div className={`${styles.labelContainer}`}>
          <p className={`${styles.label}`}>{t('worker.details.consent.msg')}</p>
          <p className={`${styles.label}`}>{t('worker.details.consent.msg1')}</p>
          <p className={`${styles.label}`}>{t('worker.details.consent.msg2')}</p>
        </div>
        <div
          className={`d-flex flex-wrap justify-content-between justify-content-lg-start ${styles.flexDiv}`}
        >
          <p className={`${styles.label}`}>{t('worker.details.consent.accept')}</p>
          {BINARY_STATUS.map((option, index) => (
            <div key={index}>
              <label htmlFor={`agreed${option}`} className="radioContainer text-capitalize">
                {option ? t('worker.details.consent.yes') : t('worker.details.consent.no')}
                <Form.Check.Input
                  checked={consent.has_given_consent === option}
                  name="agreed"
                  type="radio"
                  id={`agreed${option}`}
                  onChange={() =>
                    setConsent({
                      ...consent,
                      has_given_consent: option === BINARY_STATUS[0],
                    })
                  }
                />
                <span className="radioIcon" />
              </label>
            </div>
          ))}
        </div>
        <div className="btnContainer">
          <Button type="submit" variant="saveBtn" disabled={consent.has_given_consent === null}>
            {' '}
            {t('buttons.save_and_continue')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

Consent.propTypes = {
  workerId: PropTypes.string,
  consent: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

Consent.defaultProps = {
  workerId: null,
  consent: null,
  handleSubmit() {},
  resetForm: null,
};

export default Consent;
