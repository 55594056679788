import React from 'react';
import PropTypes from 'prop-types';

function FileEarmarkRuledFillSvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
      viewBox="0 0 16 16"
    >
      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1H6v2h7v1H6v2H5v-2H3v-1h2v-2H3V9z" />
    </svg>
  );
}

FileEarmarkRuledFillSvg.propTypes = {
  className: PropTypes.string,
};

FileEarmarkRuledFillSvg.defaultProps = {
  className: null,
};

export default FileEarmarkRuledFillSvg;
