/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import Button from '../button';
import FormDatePicker from '../form-date-picker';
import formToInitialValues from './helper';
import useConstants from './useConstants';
import styles from './VisaDetails.module.scss';
import { Console } from '../../utils';

function VisaDetails({
  workerId,
  visaDetails: newVisaDetails,
  handleSubmit,
  isFieldDisabled,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const { BINARY_STATUS, VISA_DETAILS: form } = useConstants();
  const [visaDetails, setVisaDetails] = useState(formToInitialValues(form));
  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (newVisaDetails) setVisaDetails(newVisaDetails);
  }, [newVisaDetails]);

  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.visa_details) {
        fieldsToUpdate.visa_details = [];
      }
      if (!fieldsToUpdate.visa_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          visa_details: [...fieldsToUpdate.visa_details, key],
        }));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let res = null;
    const requestBody = {
      visa_details: visaDetails,
      fieldsToUpdate: JSON.stringify(fieldsToUpdate),
    };
    if (newVisaDetails) {
      res = updateWorkerDetails(workerId, requestBody);
    } else {
      res = addWorkerDetails(workerId, requestBody);
    }
    res
      .then(({ status }) => {
        if (status) handleSubmit(4);
      })
      .catch((error) => {
        Console.log(error);
      });
  };

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (newVisaDetails) {
        setVisaDetails(newVisaDetails);
      } else {
        setVisaDetails(formToInitialValues(form));
      }
    }
  }, [resetForm.resetId]);

  return (
    <div>
      <Form className="scrollContainer" onSubmit={onSubmit}>
        <div className={`d-flex flex-column h-100 ${styles.visaContainer}`}>
          <div className={`d-lg-flex align-items-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{form.visa_number.label}</p>
            <div>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                required
                value={visaDetails.visa_number}
                placeholder={form.visa_number.placeholder}
                disabled={isFieldDisabled('visa_details', 'visa_number')}
                onChange={(e) => {
                  updateFields('visa_number');
                  setVisaDetails({
                    ...visaDetails,
                    visa_number: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={`d-lg-flex align-items-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{form.visa_valid_until.label}</p>
            <div>
              <FormDatePicker
                className={`inputField ${styles.inputField}`}
                value={visaDetails.visa_valid_until}
                disabled={isFieldDisabled('visa_details', 'visa_valid_until')}
                onChange={(val) => {
                  updateFields('visa_valid_until');
                  setVisaDetails({
                    ...visaDetails,
                    visa_valid_until: val,
                  });
                }}
              />
            </div>
          </div>
          <div className={`d-lg-flex align-items-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`} />
            <div>
              <p className={`mb-lg-2 ${styles.label}`}>{form.has_eu_working_permit.question}</p>
              <div
                className={`d-flex justify-content-between justify-content-lg-start ${styles.flexDiv}`}
              >
                {BINARY_STATUS.map((option, index) => (
                  <div key={index}>
                    <label htmlFor={`has_eu_working_permit${index}`} className="radioContainer">
                      {option
                        ? t('worker.details.family.placeholders.yes')
                        : t('worker.details.family.placeholders.no')}
                      <Form.Check.Input
                        checked={visaDetails.has_eu_working_permit === option}
                        name={index}
                        type="radio"
                        id={`has_eu_working_permit${index}`}
                        disabled={isFieldDisabled('visa_details', 'has_eu_working_permit')}
                        onChange={() => {
                          updateFields('has_eu_working_permit');
                          setVisaDetails({
                            ...visaDetails,
                            has_eu_working_permit: option === BINARY_STATUS[0],
                          });
                        }}
                      />
                      <span className="radioIcon" />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {visaDetails.has_eu_working_permit === BINARY_STATUS[0] ? (
            <div>
              <div className={`d-lg-flex align-items-center ${styles.flexContainer}`}>
                <p className={`${styles.label}`}>{form.country.label}</p>
                <div>
                  <Form.Control
                    className={`inputField ${styles.inputField}`}
                    required
                    disabled={isFieldDisabled('visa_details', 'country')}
                    value={visaDetails.country}
                    placeholder={form.country.placeholder}
                    onChange={(e) => {
                      updateFields('country');
                      setVisaDetails({
                        ...visaDetails,
                        country: e.target.value.replace(/[0-9]/g, ''),
                      });
                    }}
                  />
                </div>
              </div>
              <div
                className={`d-lg-flex align-items-center justify-content-between justify-content-lg-start ${styles.dateContainer}`}
              >
                <p className={`d-none d-lg-block ${styles.label}`}>{form.issued_on.label}</p>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className={`d-lg-none ${styles.label}`}>{form.issued_on.label}</p>
                    <div>
                      <FormDatePicker
                        className={`inputField ${styles.inputField}`}
                        value={visaDetails.issued_on}
                        disabled={isFieldDisabled('visa_details', 'issued_on')}
                        onChange={(val) => {
                          updateFields('issued_on');
                          setVisaDetails({
                            ...visaDetails,
                            issued_on: val,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-lg-flex align-items-center">
                    <p className={`${styles.label}`}>{form.valid_until.label}</p>
                    <div>
                      <FormDatePicker
                        className={`inputField ${styles.inputField}`}
                        value={visaDetails.valid_until}
                        disabled={isFieldDisabled('visa_details', 'valid_until')}
                        onChange={(val) => {
                          updateFields('valid_until');
                          setVisaDetails({
                            ...visaDetails,
                            valid_until: val,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="btnContainer">
            <Button type="submit" variant="saveBtn">
              {t('buttons.save_and_continue')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

VisaDetails.propTypes = {
  workerId: PropTypes.string,
  visaDetails: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

VisaDetails.defaultProps = {
  workerId: null,
  visaDetails: null,
  handleSubmit() {},
  isFieldDisabled() {},
  resetForm: null,
};

export default VisaDetails;
