import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { getProjectStatus } from '../../../services/project';
import getContractTypes from '../../../services/contractTypes';

const useConstants = () => {
  const { t } = useTranslation('lang');
  const [statuses, setStatuses] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);

  const PROJECT_DETAILS = {
    name: '',
    requested_info: '',
    notes: '',
    status: 'active',
    contract_type: '',
  };

  const fetchProjectStatuses = () => {
    getProjectStatus().then((res) => {
      if (res.status) {
        setStatuses(
          res.data.map((d) => ({
            id: uuid(),
            label: t(`project.view.status_dropdown.${d.toLowerCase()}`),
            value: d,
          }))
        );
      }
    });
  };

  const fetchContractTypes = () => {
    getContractTypes().then((res) => {
      if (res.status) {
        setContractTypes(
          res.data.map(({ uuid: contractTypeUuid, name }) => ({
            id: contractTypeUuid,
            label: t(`project.view.contract_dropdown.${name.toLowerCase()}`),
            value: contractTypeUuid,
          }))
        );
      }
    });
  };

  useEffect(() => {
    fetchProjectStatuses();
    fetchContractTypes();
  }, []);

  return {
    PROJECT_DETAILS,
    statuses,
    contractTypes,
  };
};

export default useConstants;
