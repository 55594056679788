import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './Status.module.scss';

function Status(props) {
  const { children, className, variant, type } = props;
  const { t } = useTranslation('lang');
  let statusClassName;
  let typeClassName;
  switch (variant.toLowerCase()) {
    case 'active':
      statusClassName = styles.active;
      break;
    case 'inactive':
      statusClassName = styles.inactive;
      break;
    case 'pending':
      statusClassName = styles.pending;
      break;
    case 'completed':
      statusClassName = styles.completed;
      break;
    case 'invoiced':
      statusClassName = styles.invoice;
      break;
    case 'new':
      statusClassName = styles.new;
      break;
    case 'admin':
      statusClassName = styles.admin;
      break;
    case 'assigned':
      statusClassName = styles.assigned;
      break;
    case 'in-progress':
      statusClassName = styles.inProgress;
      break;
    case 'available':
      statusClassName = styles.available;
      break;
    case 'archived':
      statusClassName = styles.archived;
      break;
    default:
      statusClassName = '';
  }
  switch (type?.toLowerCase()) {
    case 'small':
      typeClassName = styles.small;
      break;
    case 'text':
      typeClassName = styles.text;
      break;
    default:
      typeClassName = '';
  }

  return (
    <div className={` ${typeClassName} ${styles.commonStatus} ${statusClassName} ${className}`}>
      {t(`status.${children?.toLowerCase()}`)}
    </div>
  );
}

Status.propTypes = {
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

Status.defaultProps = {
  className: null,
  type: null,
};

export default Status;
