import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import User from '../../../../components/user';
import styles from './Profile.module.scss';
import ProfileForm from '../../../../components/profile-form';
import { getImageSrcUrl } from '../../../../utils';

function Profile() {
  const { t } = useTranslation('lang');
  const [profileDetails, setProfileDetails] = useState({});

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (userInfo) {
      setProfileDetails(userInfo?.data);
    }
  }, [userInfo]);

  return (
    <div className="tabsScrollMainContainer">
      <Helmet>
        <title>Hiukka - {t('page_titles.profile')}</title>
      </Helmet>
      <div className={`scrollContainer d-lg-flex ${styles.profileContainer}`}>
        <div>
          <div>
            <User
              profilePic={getImageSrcUrl(profileDetails)}
              name={`${profileDetails.firstname} ${profileDetails.lastname}`}
              status={profileDetails.status ? profileDetails.status : ''}
              tags={profileDetails.workTypes}
              specialTags={profileDetails.workTypePermissions}
              alignImage="left"
              editBtn={false}
            />
          </div>
          {/* <div className={`${styles.tagContainer}`}>
          </div> */}
        </div>
        <div>
          <ProfileForm formData={profileDetails} />
        </div>
      </div>
    </div>
  );
}

export default Profile;
