import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './InvoiceCard.module.scss';
import Button from '../button';
import { getDate } from '../../lib/dateLib';
import ThreeDotSvg from '../svgs/three-dots';

function InvoiceCard({
  invoiceId,
  invoice,
  companyName,
  projectName,
  taskName,
  totalHours,
  started,
  finished,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('lang');

  return (
    <div className={`d-lg-flex align-items-center ${styles.invoicingCardContainer}`}>
      <div className={`d-flex flex-wrap flex-lg-nowrap ${styles.cardContent}`}>
        <div>
          <p className={`${styles.contentTitle}`}>{t('invoice.card.company_name')}</p>
          <p className={`${styles.contentData}`}>{companyName}</p>
        </div>
        <div>
          <p className={`${styles.contentTitle}`}>{t('invoice.card.project_name')}</p>
          <p className={`${styles.contentData}`}>{projectName}</p>
        </div>
        <div className={`${styles.taskContainer}`}>
          <p className={`${styles.contentTitle}`}>{t('invoice.card.task_name')}</p>
          <p className={`${styles.contentData}`}>{taskName}</p>
        </div>
        <div className={`${styles.hourContainer}`}>
          <p className={`${styles.contentTitle}`}>{t('invoice.card.total_hours')}</p>
          <p className={`${styles.contentData}`}>{totalHours}</p>
        </div>
        <div>
          <p className={`${styles.contentTitle}`}>{t('invoice.card.started')}</p>
          <p className={`${styles.contentData}`}>{getDate(started)}</p>
        </div>
        <div>
          <p className={`${styles.contentTitle}`}>{t('invoice.card.finished')}</p>
          <p className={`${styles.contentData}`}>{getDate(finished)}</p>
        </div>
      </div>
      <div className={`${styles.contentBtnContainer}`}>
        <div className={`${styles.menuIconContainer}`}>
          <ThreeDotSvg className={`${styles.menuIcon}`} />
        </div>
        <div className={`${styles.btnContainer}`}>
          <Button
            variant="editBtn"
            onClick={() =>
              navigate(
                `/invoice/company/${invoice.project.company.uuid}/project/${invoice.project.uuid}/task/${invoiceId}`
              )
            }
          >
            {t('buttons.invoice_details')}
          </Button>
        </div>
      </div>
    </div>
  );
}

InvoiceCard.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoice: PropTypes.instanceOf(Object).isRequired,
  companyName: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
  totalHours: PropTypes.number.isRequired,
  started: PropTypes.instanceOf(Date).isRequired,
  finished: PropTypes.instanceOf(Date).isRequired,
};

export default InvoiceCard;
