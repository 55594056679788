import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Pagination } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import InvoiceCard from '../../../components/invoice-card';
import { getInvoices } from '../../../services/invoice';
import PageLoader from '../../../components/page-loader';
import styles from './InvoiceList.module.scss';

const PAGINATION_DEFAULT_DATA = {
  total: 0,
  page: 1,
  limit: 10,
};

function InvoiceList() {
  const { t } = useTranslation('lang');
  const [invoices, setInvoices] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [pagination, setPagination] = useState(PAGINATION_DEFAULT_DATA)

  const fetchInvoices = async (page, limit = pagination.limit) => {
    setPageLoading(true);
    getInvoices(page, limit).then(({ is_success: isSuccess, data }) => {
      if (isSuccess) {
        setInvoices(data?.items?.rows);
        setPagination({ ...pagination, page: data.page, total: data.total });
      } else {
        setInvoices([]);
        setPagination({ ...PAGINATION_DEFAULT_DATA });
      }
      setPageLoading(false);
    });
  };

  const changePage = (page) => {
    fetchInvoices(page, pagination.limit);
  };

  useEffect(() => {
    const { page, limit } = pagination
    fetchInvoices(page, limit)
  }, []);

  useEffect(() => {
    console.log({ pagination })
  }, [pagination])

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className={`${styles.invoicingMainContainer}`}>
      <Helmet>
        <title>Hiukka - {t('page_titles.invoicing')}</title>
      </Helmet>
      <div>
        <p className="pageTitle">{t('invoice.title')}</p>
      </div>
      <div className={`${styles.cardMainContainer}`}>
        {invoices.map((invoice) => {
          const newKeyIndex = uuid();
          return (
            <InvoiceCard
              key={newKeyIndex}
              invoiceId={invoice.uuid}
              invoice={invoice}
              companyName={invoice.project.company.name}
              projectName={invoice.project.name}
              taskName={invoice.name}
              totalHours={invoice.total_hours}
              started={new Date(invoice.contract_period_start)}
              finished={new Date(invoice.contract_period_end)}
            />
          );
        })}
      </div>
      {Math.ceil(pagination.total / pagination.limit) > 1 ? (
        <div>
          <Pagination>
            <Pagination.Prev
              onClick={() => changePage(+pagination.page - 1)}
              disabled={+pagination.page === 1}
            />
            {Array(Math.ceil(pagination.total / pagination.limit))
              .fill(0)
              .map((_, index) => {
                const newUuid = uuid();
                return (
                  <Pagination.Item
                    key={newUuid}
                    active={index + 1 === +pagination.page - '0'}
                    onClick={() => changePage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}
            <Pagination.Next
              onClick={() => changePage(+pagination.page + 1)}
              disabled={Math.ceil(pagination.total / pagination.limit) === +pagination.page}
            />
          </Pagination>
        </div>
      ) : null}
    </div>
  );
}

export default InvoiceList;
