export const companyStatus = [
  { id: 1, label: 'All', value: 'all' },
  { id: 2, label: 'Active', value: 'active' },
  { id: 3, label: 'Inactive', value: 'inactive' },
];

export const statusOptions = [
  { id: 1, label: 'Active', value: 'active' },
  { id: 2, label: 'Completed', value: 'completed' },
  { id: 3, label: 'Invoiced', value: 'invoiced' },
  { id: 4, label: 'Archived', value: 'archived' },
];
export const contractOptions = [
  { id: 1, label: 'Single', value: 'single' },
  { id: 2, label: 'Seasonal', value: 'seasonal' },
  { id: 3, label: 'Predefined', value: 'predefined' },
  { id: 4, label: 'Continuos', value: 'continuos' },
];

// export const homePageFilterDropDownOptions = [
//   { id: 1, label: 'This Month', value: 'monthly' },
//   { id: 2, label: 'This Year', value: 'annually' },
//   { id: 3, label: 'This Week', value: 'weekly' },
//   { id: 4, label: 'Today', value: 'daily' },
// ];

export const eventColors = {
  1: {
    background: '#a4bdfc',
    foreground: '#1d1d1d',
  },
  2: {
    background: '#7ae7bf',
    foreground: '#1d1d1d',
  },
  3: {
    background: '#dbadff',
    foreground: '#1d1d1d',
  },
  4: {
    background: '#ff887c',
    foreground: '#1d1d1d',
  },
  5: {
    background: '#fbd75b',
    foreground: '#1d1d1d',
  },
  6: {
    background: '#ffb878',
    foreground: '#1d1d1d',
  },
  7: {
    background: '#46d6db',
    foreground: '#1d1d1d',
  },
  8: {
    background: '#e1e1e1',
    foreground: '#1d1d1d',
  },
  9: {
    background: '#5484ed',
    foreground: '#1d1d1d',
  },
  10: {
    background: '#51b749',
    foreground: '#1d1d1d',
  },
  11: {
    background: '#dc2127',
    foreground: '#1d1d1d',
  },
};

export const industriesDropDown = [
  { id: 1, label: 'Metal', value: 'metal' },
  { id: 2, label: 'Agricultural', value: 'agricultural' },
  { id: 3, label: 'Constructions', value: 'constructions' },
];

export const workTypeDropDown = [
  { id: 1, label: 'Cleaner', value: 'cleaner' },
  { id: 2, label: 'Carpentar', value: 'carpentar' },
  { id: 3, label: 'Electrician', value: 'electrician' },
];

export const workTypePermissionsList = [
  { id: 1, label: 'Work Safety', value: 'worksafety' },
  { id: 2, label: 'Fire Safety', value: 'firesafety' },
  { id: 3, label: 'Other Safety', value: 'othersafety' },
];

export const dateFormats = [
  { name: 'DD/MM/YYYY', uuid: 'dd/MM/yyyy' },
  { name: 'MM/DD/YYYY', uuid: 'MM/dd/yyyy' },
  { name: 'YYYY/DD/MM', uuid: 'yyyy/dd/MM' },
];

export const timeFormats = [
  { name: '24H', uuid: '24H' },
  { name: '12H (AM/PM)', uuid: '12H' },
];

export const languages = [
  { name: 'Suomi', uuid: 'finnish' },
  { name: 'Русский', uuid: 'russian' },
  { name: 'English', uuid: 'english' },
];

export const defaultUserProfilePicture = '/images/demo-dp.png';
