import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Logo from '../../components/logo';
import styles from './index.module.scss';

function AuthView() {
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        limit={10}
      />
      <div
        className={`d-flex flex-column align-items-center justify-content-center ${styles.loginContainer}`}
      >
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.formMainContainer}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthView;
