import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Button from '../button';

const videoConstraints = {
  width: 100,
  height: 100,
  facingMode: 'environment',
};

function CameraImageUpload({ openCamera, setOpenCamera, onSave }) {
  const { t } = useTranslation('lang');

  const [image, setImage] = useState('');
  const [file, setFile] = useState({});
  const webcamRef = useRef(null);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n) {
      u8arr[n] = bstr.charCodeAt(n);
      n -= 1;
    }
    return { uuid: uuid(), file: new File([u8arr], filename, { type: mime }) };
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);

    setFile(dataURLtoFile(imageSrc, `image-${Date.now()}.png`));
  }, [webcamRef]);

  return (
    <>
      {openCamera && image === '' && (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={videoConstraints}
        />
      )}

      {openCamera && image === '' && (
        <Button variant="saveBtn" className="mt-2" onClick={capture}>
          {t('buttons.capture')}
        </Button>
      )}
      {image !== '' && (
        <>
          <img src={image} alt="captured" />
          <Button
            variant="warnBtn"
            className="mt-2"
            onClick={() => {
              setOpenCamera(true);
              setImage('');
              setFile({});
            }}
          >
            {t('buttons.retake')}
          </Button>
          <Button
            variant="saveBtn"
            className="mt-2"
            onClick={() => {
              onSave(file);
              setOpenCamera(false);
              setImage('');
              setFile({});
            }}
          >
            {t('buttons.save')}
          </Button>
        </>
      )}

      {(openCamera || image !== '') && (
        <Button
          onClick={() => {
            setOpenCamera(false);
            setImage('');
          }}
        >
          Back
        </Button>
      )}
    </>
  );
}

CameraImageUpload.propTypes = {
  openCamera: PropTypes.bool,
  setOpenCamera: PropTypes.func,
  onSave: PropTypes.func,
};

CameraImageUpload.defaultProps = {
  openCamera: false,
  setOpenCamera() {},
  onSave() {},
};

export default CameraImageUpload;
