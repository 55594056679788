import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function MultiSelectDropDown({
  isCreatable,
  loading,
  isDisabled,
  options,
  selectedOptions,
  placeholder,
  handleChange,
  handleCreate,
  isMulti,
  isSearchable,
  label,
  value,
  noOptionsMessage,
}) {
  const customStyle = {
    control: (provided) => ({
      ...provided,
      flexDirection: 'row-reverse',
    }),
  };
  const { t } = useTranslation('lang');

  return isCreatable ? (
    <CreatableSelect
      className="multiSelectDropDown"
      classNamePrefix="multiSelect"
      styles={customStyle}
      isDisabled={loading || isDisabled}
      isLoading={loading}
      placeholder={placeholder || t('multi_select_dropdown.search')}
      createOptionPosition="last"
      onCreateOption={handleCreate}
      options={options}
      isMulti={isMulti}
      onChange={handleChange}
      isSearchable={isSearchable}
      value={selectedOptions}
      getOptionLabel={(option) => option[label] ?? option.label}
      getOptionValue={(option) => option[value] ?? option.value}
      noOptionsMessage={() => noOptionsMessage || t('multi_select_dropdown.no_options')}
    />
  ) : (
    <Select
      className="multiSelectDropDown"
      classNamePrefix="multiSelect"
      styles={customStyle}
      isDisabled={loading || isDisabled}
      isLoading={loading}
      placeholder={placeholder || t('multi_select_dropdown.search')}
      options={options}
      isMulti={isMulti}
      onChange={handleChange}
      isSearchable={isSearchable}
      value={selectedOptions}
      getOptionLabel={(option) => option[label]}
      getOptionValue={(option) => option[value]}
      noOptionsMessage={() => noOptionsMessage || t('multi_select_dropdown.no_options')}
    />
  );
}

MultiSelectDropDown.propTypes = {
  isCreatable: PropTypes.bool,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(Object),
  selectedOptions: PropTypes.oneOfType([PropTypes.arrayOf(Object), PropTypes.instanceOf(Object)]),
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleCreate: PropTypes.func,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  noOptionsMessage: PropTypes.string,
};

MultiSelectDropDown.defaultProps = {
  isCreatable: true,
  loading: false,
  isDisabled: false,
  options: [],
  selectedOptions: [],
  placeholder: null,
  handleChange() {},
  handleCreate() {},
  isMulti: false,
  isSearchable: false,
  label: 'name',
  value: 'uuid',
  noOptionsMessage: '',
};

export default MultiSelectDropDown;
