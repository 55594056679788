import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ListSvg from '../../components/svgs/list';
import XLgSvg from '../../components/svgs/x-lg';
import SearchSvg from '../../components/svgs/search-svg';
import AdminSideMenu from '../admin-side-menu';
import { handleStoreChanges } from '../../lib/dateLib';
import styles from './AdminMobileHeader.module.scss';
import SearchBox from '../../components/search-box';
import { getImageSrcUrl } from '../../utils';
import BellSvg from '../../components/svgs/bell-svg';
import EnvelopeSvg from '../../components/svgs/envelope-svg';

function AdminMobileHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { t } = useTranslation('lang');
  const handleSearch = () => {};
  const userInfo = useSelector((state) => state.userLogin.userInfo.data);

  useEffect(() => {
    handleStoreChanges();
  }, []);

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${styles.mobHeaderContainer}`}
    >
      <div className={`${styles.openCloseIcon}`}>
        {isMenuOpen ? (
          <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={() => setIsMenuOpen(false)}>
            <XLgSvg />
          </div>
        ) : (
          <div role="button" tabIndex={-1} onKeyDown={() => {}} onClick={() => setIsMenuOpen(true)}>
            <ListSvg />
          </div>
        )}
      </div>
      {isMenuOpen ? (
        <div className={`${styles.menuMainContainer}`}>
          <AdminSideMenu onSelect={() => setIsMenuOpen(false)} />
        </div>
      ) : null}
      <div className={`d-flex align-items-center ${styles.mobHeader}`}>
        {!isSearchOpen ? (
          <div className={`d-flex align-items-center ${styles.profileContainer}`}>
            <div className={`${styles.profileImgContainer}`}>
              <img
                className={`${styles.profileImg}`}
                src={getImageSrcUrl(userInfo?.data)}
                alt="profile"
              />
            </div>
            <div>
              <p>{t('header.logged_in_as')}</p>
              <p>
                {userInfo
                  ? `${userInfo.firstname} ${userInfo.lastname}`
                  : t('common_messages.no_user_info')}
              </p>
            </div>
          </div>
        ) : null}
        <div className={`d-flex align-items-center ${styles.profileContent}`}>
          {!isSearchOpen ? (
            <div className={`${styles.iconContainer}`}>
              <EnvelopeSvg />
            </div>
          ) : null}

          {!isSearchOpen ? (
            <div className={`${styles.iconContainer}`}>
              <BellSvg />
            </div>
          ) : null}

          <div className={`d-flex align-items-center ${styles.searchContainer}`}>
            {isSearchOpen ? (
              <div className={`${styles.searchBar}`}>
                <SearchBox onSearch={handleSearch} />
              </div>
            ) : null}
            <div>
              {isSearchOpen ? (
                <div
                  role="button"
                  tabIndex={-2}
                  onKeyDown={() => {}}
                  className={`${styles.iconContainer}`}
                  onClick={() => setIsSearchOpen(false)}
                >
                  <XLgSvg />
                </div>
              ) : (
                <div
                  role="button"
                  tabIndex={-3}
                  onKeyDown={() => {}}
                  className={`${styles.iconContainer}`}
                  onClick={() => setIsSearchOpen(true)}
                >
                  <SearchSvg />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMobileHeader;
