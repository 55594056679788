import React from 'react';
import PropTypes from 'prop-types';

function DownloadIconSvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="currentColor"
      viewBox="0 0 21 25.5"
    >
      <path
        id="Icon_material-file-download"
        data-name="Icon material-file-download"
        d="M28.5,13.5h-6v-9h-9v9h-6L18,24ZM7.5,27v3h21V27Z"
        transform="translate(-7.5 -4.5)"
      />
    </svg>
  );
}

DownloadIconSvg.propTypes = {
  className: PropTypes.string,
};

DownloadIconSvg.defaultProps = {
  className: null,
};

export default DownloadIconSvg;
