import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import LogOutSvg from '../../components/svgs/box-arrow-left-svg';
import EditSvg from '../../components/svgs/pencil-square-fill-svg';
import SettingsSvg from '../../components/svgs/gear-fill-svg';
import { updateWorker } from '../../services/workers';
import { getUserProfile, logOut } from '../../redux/features/users/userSlice';
import styles from './AdminSideMenu.module.scss';

function AdminSideMenu({ onSelect }) {
  const { t } = useTranslation('lang');
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userLogin.userInfo.data);
  const navigate = useNavigate();

  const changeLanguage = (lang) => {
    if (userInfo && userInfo.uuid) {
      updateWorker(userInfo.uuid, {
        preferred_language: lang,
      }).then(() => {
        dispatch(getUserProfile());
        toast.success(t('settings.msgs.saved'));
      });
    }
  };

  return (
    <div className={`d-flex flex-column ${styles.menuContainer}`}>
      <div className={`${styles.menuItemContainer}`}>
        <div
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          className={`d-flex align-items-center ${styles.menuItem}`}
          onClick={() => {
            navigate('/settings');
            onSelect();
          }}
        >
          <div>
            <SettingsSvg />
          </div>
          <p>{t('sidebar.footer.setting')}</p>
        </div>
        <div className={`d-flex align-items-center ${styles.menuItem}`}>
          <div>
            <EditSvg />
          </div>
          <p> {t('sidebar.footer.edit_profile')}</p>
        </div>
      </div>
      <div className={`${styles.menuBottomContainer}`}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.menuImgContainer}`}
        >
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('english')}
            >
              <img src="/images/flag1.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('finnish')}
            >
              <img src="/images/flag2.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('russian')}
            >
              <img src="/images/flag3.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
        </div>
        <div
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          className={`d-flex align-items-center ${styles.logBtnContainer}`}
          onClick={() => {
            dispatch(logOut());
            navigate('/auth/login');
          }}
        >
          <div>
            <LogOutSvg />
          </div>
          <p>{t('sidebar.footer.log_out')}</p>
        </div>
      </div>
    </div>
  );
}

AdminSideMenu.propTypes = {
  onSelect: PropTypes.func,
};

AdminSideMenu.defaultProps = {
  onSelect() {},
};

export default AdminSideMenu;
