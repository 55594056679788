import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HouseDoorFillSvg from '../../components/svgs/house-door-fill-svg';
import BriefcaseFillSvg from '../../components/svgs/briefcase-fill-svg';
import PeopleSvg from '../../components/svgs/people-svg';
import FileEarmarkRuledFillSvg from '../../components/svgs/file-earmark-ruled-fill-svg';
import styles from './AdminBottomNav.module.scss';

function AdminBottomNav() {
  const [activeTab, setActiveTab] = useState('home');
  const { t } = useTranslation('lang');
  const navigate = useNavigate();

  const onSelect = (tab, path) => {
    setActiveTab(tab);
    navigate(path);
  };

  return (
    <div className={`d-lg-none d-flex justify-content-between ${styles.tabMainContainer}`}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className={`${activeTab === 'home' ? styles.activeTab : null}`}
        onClick={() => onSelect('home', '/')}
      >
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${styles.tabContainer}`}
        >
          <div className={`${styles.tabIconContainer}`}>
            <HouseDoorFillSvg className={`${styles.tabIcon}`} />
          </div>
          {activeTab === 'home' ? <p>{t('sidebar.dashboard')}</p> : null}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
        className={`${activeTab === 'projects' ? styles.activeTab : null}`}
        onClick={() => onSelect('projects', '/projects')}
      >
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${styles.tabContainer}`}
        >
          <div className={`${styles.tabIconContainer}`}>
            <BriefcaseFillSvg className={`${styles.tabIcon}`} />
          </div>
          {activeTab === 'projects' ? <p>{t('sidebar.projects')}</p> : null}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-2}
        onKeyDown={() => {}}
        className={`${activeTab === 'workers' ? styles.activeTab : null}`}
        onClick={() => onSelect('workers', '/workers')}
      >
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${styles.tabContainer}`}
        >
          <div className={`${styles.tabIconContainer}`}>
            <PeopleSvg className={`${styles.tabIcon}`} />
          </div>
          {activeTab === 'workers' ? <p>{t('sidebar.workers')}</p> : null}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-3}
        onKeyDown={() => {}}
        className={`${activeTab === 'invoice' ? styles.activeTab : null}`}
        onClick={() => onSelect('invoice', '/invoice')}
      >
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${styles.tabContainer}`}
        >
          <div className={`${styles.tabIconContainer}`}>
            <FileEarmarkRuledFillSvg className={`${styles.tabIcon}`} />
          </div>
          {activeTab === 'invoice' ? <p>{t('sidebar.invoicing')}</p> : null}
        </div>
      </div>
    </div>
  );
}

export default AdminBottomNav;
