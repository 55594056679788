import service from './wrapper';

export const getProjects = (companyId) => service.get(`/companies/${companyId}/projects`);

export const addProject = (companyId, data) =>
  service.post(`/companies/${companyId}/projects`, data);

export const getProject = async (companyId, projectId) =>
  service.get(`/companies/${companyId}/projects/${projectId}`);

export const updateProject = (companyId, projectId, data) =>
  service.put(`/companies/${companyId}/projects/${projectId}`, data);

export const deleteProject = (companyId, projectId) =>
  service.delete(`/companies/${companyId}/projects/${projectId}`);

export const getProjectStatus = () => service.get(`/projects/status`);
