import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Status from '../status';
import Button from '../button';
import styles from './LatestJobAccordion.module.scss';
import UpArrowIcon from '../svgs/up-arrow-icon';
import DownArrowIcon from '../svgs/down-arrow-icon';

function LatestJobAccordion(props) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const { id, client, start, price, worker, status, total } = props;

  return (
    <div className={`d-lg-none ${styles.accordionContainer}`}>
      <div className={`${styles.btnContainer} d-flex align-items-center`}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          className={`${styles.accordionBtn}`}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          {isActive ? (
            <UpArrowIcon className={`${styles.arrowIcons}`} />
          ) : (
            <DownArrowIcon className={`${styles.arrowIcons}`} />
          )}
        </div>
        <div>
          <div className={`d-flex align-items-center ${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>CLIENT :</p>
            <p className={`${styles.accordionData}`}>{client}</p>
          </div>
          <div className={`d-flex align-items-center ${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>ID :</p>
            <p className={`${styles.accordionData}`}>...{id.substring(id.length - 5)}</p>
          </div>
        </div>
      </div>
      <div className={`${styles.accordionContent} ${!isActive && 'd-none'}`} id={id}>
        <div className={`${styles.accordionRow} d-flex`}>
          <div className={`${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>START</p>
            <p className={`${styles.accordionData}`}>{start}</p>
          </div>
          <div className={`${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>PRICE /h</p>
            <p className={`${styles.accordionData}`}>{price}</p>
          </div>
        </div>
        <div className={`${styles.accordionRow} d-flex`}>
          <div className={`${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>WORKER</p>
            <p className={`${styles.accordionData}`}>{worker}</p>
          </div>
          <div className={`${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>STATUS</p>
            <div className={`${styles.accordionDataBtn}`}>
              <Status variant={status} type="small">
                {status}
              </Status>
            </div>
          </div>
        </div>
        <div className={`${styles.accordionRow}`}>
          <div className={`${styles.titleContainer}`}>
            <p className={`${styles.accordionLabel}`}>TOTAL h</p>
          </div>
          <div
            className={` d-flex align-items-center justify-content-between ${styles.titleContainer}`}
          >
            <p className={`${styles.accordionData}`}>{total}</p>
            <div className={`${styles.detailsBtnContainer}`}>
              <Button
                className={`${styles.detailsBtn}`}
                type="button"
                onClick={() => navigate(`/latestJob/${id}`)}
              >
                Details
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LatestJobAccordion.propTypes = {
  id: PropTypes.string,
  client: PropTypes.string,
  start: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  worker: PropTypes.string,
  status: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LatestJobAccordion.defaultProps = {
  id: null,
  client: null,
  start: null,
  price: null,
  worker: null,
  status: null,
  total: null,
};

export default LatestJobAccordion;
