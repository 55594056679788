/** @format */

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './SidebarLink.module.scss';
import ChevronDownSvg from '../svgs/chevron-down';
import ChevronRightSvg from '../svgs/chevron-right';

function SidebarLink(props) {
  const { link, svg: Svg, isActive, title, onClick } = props;
  return (
    <NavLink
      to={link}
      onClick={onClick}
      className={`d-flex align-items-center justify-content-between ${styles.sidebarLink} ${
        isActive ? styles.active : ''
      }`}
    >
      <div className="d-flex align-items-center">
        <div className={`${styles.svgContainer}`}>
          <Svg className={styles.svgIcon} />
        </div>
        <p>{title}</p>
      </div>
      <div className={styles.svgContainer}>
        {isActive ? (
          <ChevronRightSvg className={styles.svgIcon} />
        ) : (
          <ChevronDownSvg className={styles.svgIcon} />
        )}
      </div>
    </NavLink>
  );
}

SidebarLink.propTypes = {
  link: PropTypes.string.isRequired,
  svg: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarLink;
