import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import WorkerForm from '../../../../components/worker-form';

function AddWorker() {
  const { t } = useTranslation('lang');
  return (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.workers')}</title>{' '}
      </Helmet>
      <p className="pageTitle">{t('worker.add_title')}</p>
      <div className="tabsScrollMainContainer">
        <WorkerForm />
      </div>
    </div>
  );
}

export default AddWorker;
