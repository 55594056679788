/** @format */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchBox from '../../components/search-box';
import BellSvg from '../../components/svgs/bell-svg';
import EnvelopeSvg from '../../components/svgs/envelope-svg';
import styles from './Header.module.scss';
import { handleStoreChanges } from '../../lib/dateLib';
import { getImageSrcUrl } from '../../utils';

function Header() {
  const { t } = useTranslation('lang');
  const handleSearch = () => {};
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    handleStoreChanges();
  }, []);

  return (
    <div className={`d-flex align-items-center justify-content-between ${styles.header}`}>
      <div className={styles.inputContainer}>
        <SearchBox onSearch={handleSearch} />
      </div>
      <div className={`d-flex align-items-center ${styles.headerContent}`}>
        <div className={styles.messages}>
          <EnvelopeSvg className={styles.mailBellImg} />
        </div>
        <div className={styles.notification}>
          <BellSvg className={styles.mailBellImg} />
        </div>
        <div className={styles.profileName}>
          <p>{t('header.logged_in_as')}</p>
          <p>
            {userInfo.data
              ? `${userInfo.data.firstname} ${userInfo.data.lastname}`
              : t('common_messages.no_user_info')}
          </p>
        </div>
        <div className={styles.profileImg}>
          <img src={getImageSrcUrl(userInfo?.data)} className={styles.profileImage} alt="profile" />
        </div>
      </div>
    </div>
  );
}

export default Header;
