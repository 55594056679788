import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EditSvg from '../svgs/pencil-square-fill-svg';
import LogoutSvg from '../svgs/box-arrow-left-svg';
import SettingsSvg from '../svgs/gear-fill-svg';
import styles from './Footer.module.scss';
import { logOut } from '../../redux/features/users/userSlice';

function Footer({ editProfile, onSelect }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('lang');

  return (
    <div className={`d-flex justify-content-between ${styles.footer}`}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className={styles.svgContainer}
        onClick={() => {
          navigate('/settings');
          if (onSelect) {
            onSelect();
          }
        }}
      >
        <SettingsSvg className={styles.svgIcon} />
        <p>{t('sidebar.footer.setting')}</p>
      </div>
      {editProfile && (
        <div className={styles.svgContainer}>
          <EditSvg className={styles.svgIcon} />
          <p>{t('sidebar.footer.edit_profile')}</p>
        </div>
      )}
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className={styles.svgContainer}
        onClick={() => {
          dispatch(logOut());
          navigate('/auth/login');
        }}
      >
        <LogoutSvg className={styles.svgIcon} />
        <p>{t('sidebar.footer.log_out')}</p>
      </div>
    </div>
  );
}

Footer.propTypes = {
  editProfile: PropTypes.bool,
  onSelect: PropTypes.func,
};

Footer.defaultProps = {
  editProfile: true,
  onSelect() {},
};

export default Footer;
