import React from 'react';
import PropTypes from 'prop-types';

function DownArrowIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`${className}`} viewBox="0 0 39.034 39.034">
      <path
        id="Icon_ionic-ios-arrow-dropdown-circle"
        data-name="Icon ionic-ios-arrow-dropdown-circle"
        d="M3.375,22.892A19.517,19.517,0,1,0,22.892,3.375,19.514,19.514,0,0,0,3.375,22.892ZM30.5,18.82a1.818,1.818,0,0,1,2.562,0,1.789,1.789,0,0,1,.525,1.276,1.82,1.82,0,0,1-.535,1.285L24.206,30.2a1.809,1.809,0,0,1-2.5-.056l-8.98-8.952a1.811,1.811,0,1,1,2.562-2.562l7.61,7.685Z"
        transform="translate(-3.375 -3.375)"
        fill="#878793"
      />
    </svg>
  );
}

DownArrowIcon.propTypes = {
  className: PropTypes.string,
};

DownArrowIcon.defaultProps = {
  className: null,
};

export default DownArrowIcon;
