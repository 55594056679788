import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function ConfirmationModal({ show, handleClose, onConfirm, message, title }) {
  const { t } = useTranslation('lang');
  return (
    <Modal className="text-dark" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('modal.close')}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {t('modal.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  show: false,
  handleClose() {},
  onConfirm() {},
  message: null,
  title: null,
};

export default ConfirmationModal;
