import React from 'react';
import PropTypes from 'prop-types';
import styles from './OverviewCard.module.scss';
import CircularProgressBar from '../circular-progress-bar';
import ArrowUpRightCircleFill from '../svgs/arrow-up-right-circle-fill';
import ArrowDownLeftCircleFill from '../svgs/arrow-down-left-circle-fill';

function OverviewCard(props) {
  const { title, percentage, color, changeValue, count, showCircularProgress } = props;
  const colorFlag = changeValue && changeValue.toString().includes('-') ? true : null;
  return (
    <div
      className={`d-flex align-items-center align-items-lg-start justify-content-between fontProximaNova ${styles.overviewCardContainer}`}
    >
      <div className={`order-0 order-lg-1 ${styles.contentContainer}`}>
        <div className={`${styles.overviewCount}`}>
          <p>{count}</p>
        </div>
        <p className={`${styles.overviewTitle}`}>{title}</p>
        {changeValue && (
          <div className={`${styles.ChangeValue} ${colorFlag && styles.decreasedValue} `}>
            {!colorFlag ? (
              <ArrowUpRightCircleFill className={`${styles.changeValueIcon}`} />
            ) : (
              <ArrowDownLeftCircleFill className={`${styles.changeValueIcon}`} />
            )}
            {!colorFlag && '+'}
            {changeValue}%
          </div>
        )}
      </div>
      {showCircularProgress ? (
        <div className={`order-1 order-lg-0 ${styles.graphContainer}`}>
          <CircularProgressBar percentage={percentage} color={color} />
        </div>
      ) : null}
    </div>
  );
}

OverviewCard.propTypes = {
  changeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentage: PropTypes.number,
  count: PropTypes.number,
  showCircularProgress: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
};

OverviewCard.defaultProps = {
  changeValue: null,
  percentage: null,
  count: null,
  showCircularProgress: true,
  title: null,
  color: null,
};

export default OverviewCard;
