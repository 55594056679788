/* eslint-disable import/no-cycle */
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../store/store';
import { logOut } from '../redux/features/users/userSlice';
import getTranslatedMessage from '../lib/i18n';

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

service.interceptors.request.use((request) => {
  const token = localStorage.getItem('token');
  if (token) request.headers['access-token'] = token;
  return request;
});

service.interceptors.response.use(
  (response) => response.data,
  (err) => {
    if ([400, 422, 401, 403].includes(err.response.status)) {
      if (err.response.status === 401) {
        store.dispatch(logOut());
      } else {
        toast.error(getTranslatedMessage(err.response.data.message ?? 'Something Went Wrong'));
      }
    } else {
      toast.error(getTranslatedMessage(err.response.data.message ?? 'Something Went Wrong'));
    }
    return Promise.reject(err.response.data);
  }
);

export default service;
