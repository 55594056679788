import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './ProjectSettings.module.scss';
import MultiSelectDropDown from '../../../../components/multi-select-dropdown';
import SelectableMultiTags from '../../../../components/selectable-multi-tags';
import InputSaveModal from '../../../../components/input-save-modal';
import SideLoader from '../../../../components/side-loader';
import {
  getIndustries,
  addIndustry,
  updateIndustryById,
  deleteIndustry,
} from '../../../../services/industries';
import {
  getWorkTypes,
  addWorkType,
  updateWorkTypeById,
  deleteWorkType,
} from '../../../../services/workTypes';
import {
  getWorkTypePermissions,
  addWorkTypePermission,
  updateWorkTypePermission,
  deleteWorkTypePermission,
} from '../../../../services/workTypePermissions';
import Button from '../../../../components/button';
import PlusSvg from '../../../../components/svgs/plus-svg';
import PencilSquareFillSvg from '../../../../components/svgs/pencil-square-fill-svg';
import TrashSvg from '../../../../components/svgs/trash-svg';
import ConfirmationModal from '../../../../components/confirmation-modal';

function ProjectSettings({ resetForm }) {
  const { t } = useTranslation('lang');
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [industriesMdl, setIndustriesMdl] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [newIndustryName, setNewIndustryName] = useState({ name: '' });
  const [workTypes, setWorkTypes] = useState([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [workTypeMdl, setWorkTypeMdl] = useState(false);
  const [newWorkTypeName, setNewWorkTypeName] = useState({ name: '' });
  const [workTypePermissions, setWorkTypePermissions] = useState([]);
  const [selectedWorkTypePermissions, setSelectedWorkTypePermissions] = useState([]);
  const [workTypePermissionsMdl, setWorkTypePermissionsMdl] = useState(false);
  const [newWorkTypePermission, setNewWorkTypePermission] = useState({
    name: '',
  });
  const [confirmModal, setConfirmModal] = useState({ show: false, target: null });

  const fetchIndustries = () => {
    setLoading(true);
    getIndustries()
      .then((response) => {
        if (response.status) setIndustries(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchWorkTypes = () => {
    setLoading(true);
    getWorkTypes(selectedIndustries[0].uuid)
      .then((response) => {
        if (response.status) setWorkTypes(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchWorkTypePermissions = () => {
    setLoading(true);
    getWorkTypePermissions()
      .then((response) => {
        if (response.status) setWorkTypePermissions(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addNewIndustry = (value) => {
    setLoading(true);
    addIndustry({ name: value })
      .then(() => {
        toast.success(t('settings.msgs.industry_added'));
        fetchIndustries();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    setIndustriesMdl(false);
  };

  const addNewWorkType = (value) => {
    setLoading(true);
    addWorkType(selectedIndustries[0].uuid, { name: value })
      .then(() => {
        fetchWorkTypes();
        setLoading(false);
        toast.success(t('settings.msgs.work_type_added'));
      })
      .catch(() => {
        setLoading(false);
      });

    setWorkTypeMdl(false);
  };

  const addNewWorkTypePermission = (value) => {
    setLoading(true);
    addWorkTypePermission({ name: value })
      .then(() => {
        setLoading(false);
        fetchWorkTypePermissions();
        toast.success(t('settings.msgs.work_type_per_added'));
      })
      .catch(() => {
        setLoading(false);
      });
    setSelectedWorkTypePermissions([]);
    setWorkTypePermissionsMdl(false);
  };

  const updateIndustry = (value) => {
    setLoading(true);
    updateIndustryById(value.uuid, value)
      .then(() => {
        setSelectedIndustries([]);
        toast.success(t('settings.msgs.industry_updated'));
        fetchIndustries();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setIndustriesMdl(false);
  };

  const updateWorkType = (value) => {
    setLoading(true);
    updateWorkTypeById(selectedIndustries[0].uuid, value.uuid, value)
      .then(() => {
        setSelectedWorkTypes([]);
        setLoading(false);
        fetchWorkTypes();
        toast.success(t('settings.msgs.work_type_updated'));
      })
      .catch(() => {
        setLoading(false);
      });
    setWorkTypeMdl(false);
  };

  const updateWorkTypePermissions = (value) => {
    setLoading(true);
    updateWorkTypePermission(value.uuid, { name: value.name })
      .then(() => {
        setLoading(false);
        fetchWorkTypePermissions();
        toast.success(t('settings.msgs.work_type_per_updated'));
      })
      .catch(() => {
        setLoading(false);
      });
    setSelectedWorkTypePermissions([]);
    setWorkTypePermissionsMdl(false);
  };

  const deleteIndustries = () => {
    setLoading(true);
    Promise.all(
      selectedIndustries.length !== 0 &&
        selectedIndustries.map((eachIndustry) => deleteIndustry(eachIndustry.uuid))
    )
      .then(() => {
        setLoading(false);
        toast.success(t('settings.msgs.industry_deleted'));
        setSelectedIndustries([]);
        fetchIndustries();
      })
      .catch(() => {
        setLoading(false);
        selectedIndustries([]);
      });

    setConfirmModal({
      show: false,
      target: null,
    });
  };

  const deleteWorkTypes = () => {
    setLoading(true);
    Promise.all(
      selectedWorkTypes.length !== 0 &&
        selectedWorkTypes.map((eachWorkType) =>
          deleteWorkType(selectedIndustries[0].uuid, eachWorkType.uuid)
        )
    )
      .then(() => {
        setLoading(false);
        toast.success(t('settings.msgs.work_type_deleted'));
        setSelectedWorkTypes([]);
        fetchWorkTypes();
      })
      .catch(() => {
        setLoading(false);
        selectedWorkTypes([]);
      });

    setConfirmModal({
      show: false,
      target: null,
    });
  };

  const deleteWorkTypesPermissions = () => {
    setLoading(true);
    Promise.all(
      selectedWorkTypePermissions.length !== 0 &&
        selectedWorkTypePermissions.map((eachWorkTypePermission) =>
          deleteWorkTypePermission(eachWorkTypePermission.uuid)
        )
    )
      .then(() => {
        setLoading(false);
        toast.success(t('settings.msgs.work_type_per_deleted'));
        fetchWorkTypePermissions();
      })
      .catch(() => {
        setLoading(false);
      });
    setSelectedWorkTypePermissions([]);
    setConfirmModal({
      show: false,
      target: null,
    });
  };

  const handleIndustry = (value) => {
    if (value.uuid) {
      updateIndustry(value);
    } else {
      addNewIndustry(value);
    }
  };

  const handleWorkType = (value) => {
    if (selectedIndustries[0].uuid) {
      if (value.uuid) {
        updateWorkType(value);
      } else {
        addNewWorkType(value);
      }
    } else {
      toast.error(t('common_messages.tag_select_msg'));
    }
  };

  const handleWorkTypePermission = (value) => {
    if (value.uuid) {
      updateWorkTypePermissions(value);
    } else {
      addNewWorkTypePermission(value);
    }
  };

  const handleIndustriesChange = (selectedOptions) => {
    setSelectedIndustries(selectedOptions);
  };

  const handleWorkTypeChange = (selectedOptions) => {
    setSelectedWorkTypes(selectedOptions);
  };

  const handleIndustryCreate = (value) => {
    setNewIndustryName({ name: value });
    setIndustriesMdl(true);
  };

  const handleWorkTypeCreate = (value) => {
    setNewWorkTypeName({ name: value });
    setWorkTypeMdl(true);
  };

  const handleWorkTypeTag = (selectedTags) => {
    setSelectedWorkTypePermissions(selectedTags);
  };

  const fetchDatasForPage = () => {
    setLoading(true);
    Promise.all([fetchWorkTypePermissions(), fetchIndustries()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getConfirmModalMessage = () => {
    if (confirmModal.target === 'industry') {
      return t('settings.msgs.industry_delete');
    }
    if (confirmModal.target === 'work_type') {
      return t('settings.msgs.work_type_delete');
    }
    if (confirmModal.target === 'work_type_permission') {
      return t('settings.msgs.work_type_permission_delete');
    }
    return undefined;
  };

  const onConfirmFromConfirmModal = () => {
    if (confirmModal.target === 'industry') {
      return deleteIndustries();
    }
    if (confirmModal.target === 'work_type') {
      return deleteWorkTypes();
    }
    if (confirmModal.target === 'work_type_permission') {
      return deleteWorkTypesPermissions();
    }
    return undefined;
  };

  useEffect(() => {
    fetchDatasForPage();
  }, []);

  useEffect(() => {
    if (selectedIndustries.length === 1) {
      fetchWorkTypes();
    } else {
      setSelectedWorkTypes([]);
      setWorkTypes([]);
    }
  }, [selectedIndustries.length]);

  useEffect(() => {
    if (resetForm.reset) {
      setSelectedIndustries([]);
      setSelectedWorkTypes([]);
      setSelectedWorkTypePermissions([]);
      setNewIndustryName({ name: '' });
      setNewWorkTypeName({ name: '' });
      setNewWorkTypePermission({ name: '' });
    }
  }, [resetForm.resetId]);

  return (
    <div className="scrollContainer">
      {loading && <SideLoader />}
      <InputSaveModal
        show={industriesMdl}
        title={t('settings.msgs.add_industry_title')}
        field={t('settings.msgs.add_industry_name')}
        value={newIndustryName}
        handleClose={() => setIndustriesMdl(false)}
        handleConfirm={handleIndustry}
      />
      <InputSaveModal
        show={workTypeMdl}
        title={t('settings.msgs.add_work_type_title')}
        field={t('settings.msgs.add_work_type_name')}
        value={newWorkTypeName}
        handleClose={() => setWorkTypeMdl(false)}
        handleConfirm={handleWorkType}
      />
      <InputSaveModal
        show={workTypePermissionsMdl}
        title={t('settings.msgs.add_work_type_per_title')}
        field={t('settings.msgs.add_work_type_per_name')}
        value={newWorkTypePermission}
        handleClose={() => setWorkTypePermissionsMdl(false)}
        handleConfirm={handleWorkTypePermission}
      />

      <ConfirmationModal
        show={confirmModal.show}
        title={t('modal.warning')}
        message={getConfirmModalMessage()}
        handleClose={() => setConfirmModal({ show: false, target: null })}
        onConfirm={onConfirmFromConfirmModal}
      />

      <div className="h-100 d-lg-flex flex-lg-column">
        <p className={`${styles.settingTitle}`}>{t('settings.project.heading')}</p>
        <div className={`${styles.projectSettingsContent} d-lg-flex flex-wrap`}>
          <div className="dropdownContainer d-lg-flex justify-content-lg-between">
            <p className="dropdownLabel text-center">{t('settings.project.industries')}</p>
            <div className="dropdownDiv">
              <div>
                <MultiSelectDropDown
                  loading={loading}
                  selectedOptions={selectedIndustries}
                  options={industries}
                  handleChange={handleIndustriesChange}
                  handleCreate={handleIndustryCreate}
                  isSearchable
                  isMulti
                  placeholder={t('settings.project.select_industry')}
                />
              </div>
              <div
                className={`${styles.dropdownBtnContainer} d-flex align-items-center justify-content-between`}
              >
                <div>
                  <Button
                    variant="iconAddBtn"
                    onClick={() => {
                      setIndustriesMdl(true);
                      setNewIndustryName({ name: '' });
                    }}
                  >
                    <PlusSvg />
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={selectedIndustries.length > 1 || selectedIndustries.length < 1}
                    variant="iconEditBtn"
                    onClick={() => {
                      setNewIndustryName({
                        name: selectedIndustries[0].name,
                        uuid: selectedIndustries[0].uuid,
                      });
                      setIndustriesMdl(true);
                    }}
                  >
                    <PencilSquareFillSvg />
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={selectedIndustries.length < 1}
                    variant="iconDelBtn"
                    onClick={() =>
                      setConfirmModal({
                        show: true,
                        target: 'industry',
                      })
                    }
                  >
                    <TrashSvg />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdownContainer d-lg-flex justify-content-lg-between">
            <p className={`dropdownLabel ${styles.textRight}`}>{t('settings.project.work_type')}</p>
            <div className="dropdownDiv">
              <div
                role="button"
                onKeyDown={() => {}}
                tabIndex={0}
                onClick={() => {
                  if (selectedIndustries.length === 0)
                    toast.error(t('common_messages.tag_select_msg'));
                }}
              >
                <MultiSelectDropDown
                  isDisabled={selectedIndustries.length === 0}
                  placeholder={t('settings.project.select_work_type')}
                  selectedOptions={selectedWorkTypes}
                  options={workTypes}
                  handleChange={handleWorkTypeChange}
                  handleCreate={handleWorkTypeCreate}
                  isSearchable
                  isMulti
                  loading={loading}
                />
              </div>
              <div
                className={`${styles.dropdownBtnContainer} d-flex align-items-center justify-content-between`}
              >
                <div>
                  <Button
                    variant="iconAddBtn"
                    disabled={selectedIndustries.length !== 1}
                    onClick={() => {
                      setWorkTypeMdl(true);
                      setNewWorkTypeName({ name: '' });
                    }}
                  >
                    <PlusSvg />
                  </Button>
                </div>
                <div>
                  <Button
                    variant="iconEditBtn"
                    disabled={selectedWorkTypes.length !== 1}
                    onClick={() => {
                      setNewWorkTypeName({
                        name: selectedWorkTypes[0].name,
                        uuid: selectedWorkTypes[0].uuid,
                      });
                      setWorkTypeMdl(true);
                    }}
                  >
                    <PencilSquareFillSvg />
                  </Button>
                </div>
                <div>
                  <Button
                    variant="iconDelBtn"
                    disabled={selectedWorkTypes.length < 1}
                    onClick={() =>
                      setConfirmModal({
                        show: true,
                        target: 'work_type',
                      })
                    }
                  >
                    <TrashSvg />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="dropdownContainer d-lg-flex">
            <p className={`dropdownLabel ${styles.textRight}`}>
              {t('settings.project.work_type_per')}
            </p>
            <div className={`${styles.workTypeMainDiv} workTypeMainDiv workTypePositionDiv`}>
              <div className={`${styles.workTypeDiv} workTypeDiv`}>
                <SelectableMultiTags
                  label="name"
                  id="uuid"
                  list={workTypePermissions}
                  onSelect={handleWorkTypeTag}
                  selectedTags={selectedWorkTypePermissions}
                />
              </div>
              <div
                className={`${styles.dropdownBtnContainer} d-flex align-items-center justify-content-between`}
              >
                <div>
                  <Button
                    variant="iconAddBtn"
                    onClick={() => {
                      setWorkTypePermissionsMdl(true);
                      setNewWorkTypePermission({ name: '' });
                    }}
                  >
                    <PlusSvg />
                  </Button>
                </div>
                <div>
                  <Button
                    variant="iconEditBtn"
                    disabled={selectedWorkTypePermissions.length !== 1}
                    onClick={() => {
                      setNewWorkTypePermission({
                        name: selectedWorkTypePermissions[0].name,
                        uuid: selectedWorkTypePermissions[0].uuid,
                      });
                      setWorkTypePermissionsMdl(true);
                    }}
                  >
                    <PencilSquareFillSvg />
                  </Button>
                </div>
                <div>
                  <Button
                    variant="iconDelBtn"
                    disabled={selectedWorkTypePermissions.length < 1}
                    onClick={() =>
                      setConfirmModal({
                        show: true,
                        target: 'work_type_permission',
                      })
                    }
                  >
                    <TrashSvg />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='btnContainer'>
          <Button variant='saveBtn'>{t('buttons.save')}</Button>
        </div> */}
      </div>
    </div>
  );
}

ProjectSettings.propTypes = {
  resetForm: PropTypes.instanceOf(Object),
};

ProjectSettings.defaultProps = {
  resetForm: {},
};

export default ProjectSettings;
