import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../../containers/header';
import Sidebar from '../../containers/sidebar';
import Home from './home';
// import Dashboard from './dashboard';
import { AddCompany, Companies, CompanyView, EditCompany } from './companies';
import Settings from './settings';
import { Project, ProjectView } from './projects';
import PageNotFound from '../../components/page-not-found';
import Task from './task';
import WorkerHome from './workers/home';
import Profile from './workers/profile';
import {
  Dashboard as WorkerDashboard,
  AddWorker,
  EditWorker,
  WorkersList,
  WorkersView,
} from './workers/dashboard';
import TaskDetails from './workers/task-details';
import WorkerNavigationMenu from '../../containers/worker-navigation-menu';
import ErrorBoundary from '../../lib/ErrorBoundary';
import { InvoiceView, InvoiceList, Invoice } from './invoicing';
import { getConsentStatus } from '../../services/workers';
import WorkerSettings from './workers/settings/Settings';
import AdminBottomNav from '../../containers/admin-bottom-nav';
import AdminMobileHeader from '../../containers/admin-mobile-header';

function AppView() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.data.uuid && !userInfo.data.is_admin) {
      const validateConsent = async () => {
        try {
          const res = await getConsentStatus(userInfo.data.uuid);
          if (res.message === 'pending') {
            navigate(`/workers/${userInfo.data.uuid}`);
          }
        } catch (error) {
          navigate('/');
        }
      };
      validateConsent();
    }
  }, [navigate]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
      <div className="d-lg-flex appMainContainer">
        {userInfo?.data?.is_admin && (
          <div className="sidebarContainer d-none d-lg-block">
            <Sidebar />
          </div>
        )}
        <div className="mainContentContainer">
          {userInfo?.data?.is_admin && (
            <>
              <div className="headerContainer d-none d-lg-block">
                <Header />
              </div>
              <div className="d-lg-none">
                <AdminMobileHeader />
              </div>
            </>
          )}
          <div className="d-lg-none">{!userInfo?.data?.is_admin && <WorkerNavigationMenu />}</div>
          <div className="ContentContainer">
            <Routes>
              {userInfo?.data?.is_admin && (
                <Route path="" element={<Outlet />}>
                  <Route
                    path="/"
                    element={
                      <ErrorBoundary>
                        <Home />
                      </ErrorBoundary>
                    }
                  />
                  {/* <Route
                    path='/dashboard'
                    element={
                      <ErrorBoundry>
                        <Dashboard />
                      </ErrorBoundry>
                    }
                  /> */}

                  <Route
                    path="/settings"
                    element={
                      <ErrorBoundary>
                        <Settings />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/projects"
                    element={
                      <ErrorBoundary>
                        <Companies />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/workers"
                    element={
                      <ErrorBoundary>
                        <WorkersView />
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      path=""
                      element={
                        <ErrorBoundary>
                          <WorkersList />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="add"
                      element={
                        <ErrorBoundary>
                          <AddWorker />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path=":workerId"
                      element={
                        <ErrorBoundary>
                          <EditWorker />
                        </ErrorBoundary>
                      }
                    />
                  </Route>

                  <Route
                    path="/invoice"
                    element={
                      <ErrorBoundary>
                        <InvoiceView />
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      path=""
                      element={
                        <ErrorBoundary>
                          <InvoiceList />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="company/:companyId/project/:projectId/task/:taskId"
                      element={
                        <ErrorBoundary>
                          <Invoice />
                        </ErrorBoundary>
                      }
                    />
                  </Route>

                  <Route
                    path="companies"
                    element={
                      <ErrorBoundary>
                        <CompanyView />
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      path=""
                      element={
                        <ErrorBoundary>
                          <Companies />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="create"
                      element={
                        <ErrorBoundary>
                          <AddCompany />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path=":companyId"
                      element={
                        <ErrorBoundary>
                          <EditCompany />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path=":companyId/projects"
                      element={
                        <ErrorBoundary>
                          <ProjectView />
                        </ErrorBoundary>
                      }
                    >
                      <Route
                        path=":projectId"
                        element={
                          <ErrorBoundary>
                            <Project />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="create"
                        element={
                          <ErrorBoundary>
                            <Project />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path=":projectId/task/new"
                        element={
                          <ErrorBoundary>
                            <Task />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path=":projectId/task/:taskId"
                        element={
                          <ErrorBoundary>
                            <Task />
                          </ErrorBoundary>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              )}
              <Route
                path="/workers"
                element={
                  <ErrorBoundary>
                    <WorkersView />
                  </ErrorBoundary>
                }
              >
                <Route
                  path=":workerId"
                  element={
                    <ErrorBoundary>
                      <EditWorker />
                    </ErrorBoundary>
                  }
                />
              </Route>
              {!userInfo?.data?.is_admin && (
                <Route path="" element={<Outlet />}>
                  <Route
                    path="/"
                    element={
                      <ErrorBoundary>
                        <WorkerHome />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <ErrorBoundary>
                        <Profile />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="dashboard"
                    element={
                      <ErrorBoundary>
                        <WorkerDashboard />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <ErrorBoundary>
                        <WorkerSettings />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="task/:taskId"
                    element={
                      <ErrorBoundary>
                        <TaskDetails />
                      </ErrorBoundary>
                    }
                  />
                </Route>
              )}

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
        {userInfo?.data?.is_admin ? <AdminBottomNav /> : null}
      </div>
    </div>
  );
}

export default AppView;
