import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Image } from 'react-bootstrap';
import styles from './projectDetails.module.scss';
import Status from '../status';
import { getDate } from '../../lib/dateLib';
import { getImageSrcUrl } from '../../utils';

function ProjectDetails({ companyId, project }) {
  const navigate = useNavigate();
  const { t } = useTranslation('lang');
  const [workers, setWorkers] = useState([]);
  const handleWorkersArray = () => {
    if (project && project.tasks && project.tasks.length !== 0) {
      const allWorkersArr = project.tasks.map((task) => task.workers);
      const allWorkers = [];
      allWorkersArr.map((workersArr) => workersArr.map((worker) => allWorkers.push(worker)));
      setWorkers(allWorkers);
    }
  };

  useEffect(() => {
    handleWorkersArray();
  }, []);

  return (
    project && (
      <div className={`d-flex justify-content-between flex-wrap fontSegoe ${styles.accordionBody}`}>
        <div className={styles.CardColumn}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className={`${styles.columnTitle} ${styles.hoverLink}`}
            onClick={() => navigate(`/companies/${companyId}/projects/${project.uuid}`)}
          >
            {project.name}
          </div>
          {project.tasks && project.tasks.length !== 0
            ? project.tasks.slice(0, 3).map((task) => {
                const newKeyIndex = uuid();
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    className={styles.hoverLink}
                    onClick={() =>
                      navigate(`/companies/${companyId}/projects/${project.uuid}/task/${task.uuid}`)
                    }
                    key={newKeyIndex}
                  >
                    {task.name}
                  </div>
                );
              })
            : null}

          {project.tasks &&
            project.tasks.length > 3 &&
            `+${(project.tasks.length - 3).toString()} ${t('project.msgs.more')}`}
        </div>
        <div className={styles.CardColumn}>
          <p className={styles.columnTitle}>{t('project.accordian.assigned_workers')}</p>
          <div>
            {workers.length !== 0
              ? workers.map(({ worker }) => {
                  const newKeyIndexForWorker = uuid();
                  return (
                    <Image
                      roundedCircle
                      width={30}
                      height={30}
                      key={newKeyIndexForWorker}
                      src={getImageSrcUrl(worker)}
                    />
                  );
                })
              : t('project.msgs.no_workers')}
          </div>
        </div>
        <div className={styles.CardColumn}>
          <p className={styles.columnTitle}>{t('project.accordian.contract')}</p>
          <p>
            <span>{t('project.accordian.start')}:</span>
            {project.contract_start ? getDate(project.contract_start) : t('common_messages.na')}
          </p>
          <p>
            <span>{t('project.accordian.end')}:</span>
            {project.contract_end ? getDate(project.contract_end) : t('common_messages.na')}
          </p>
        </div>
        <div className={styles.CardColumn}>
          <div className={styles.columnTitle}>{t('project.accordian.project_status')}</div>
          <div className={styles.projectStatus}>
            <Status variant={project.status ? project.status.toLowerCase() : 'NA'} type="text">
              {project.status || t('common_messages.na')}
            </Status>
          </div>
        </div>
      </div>
    )
  );
}

ProjectDetails.propTypes = {
  companyId: PropTypes.string.isRequired,
  project: PropTypes.objectOf(Object).isRequired,
};

export default ProjectDetails;
