import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import styles from './User.module.scss';
import Status from '../status';
import Button from '../button';
import Tag from '../tag';
import SpecialTag from '../tag/SpecialTag';
import PriceTag from '../tag/PriceTag';
import XCircleSvg from '../svgs/x-circle';
import Check2CircleSvg from '../svgs/check2-circle';
import ActionMenu from '../action-menu/ActionMenu';

function User(props) {
  const {
    id,
    className,
    profilePic,
    pricePerHour,
    name,
    profession,
    area,
    status,
    tags,
    specialTags,
    alignImage,
    editBtn,
    deleteBtn,
    onDelete,
    onEdit,
    selected,
    onSelect,
    actions,
    actionMenu,
  } = props;

  const { t } = useTranslation('lang');

  return (
    <div className={`${className} ${styles.userCardContainer}`}>
      <Card className={`fontProximaNova ${styles.userCard}`}>
        <Card.Body
          className={`${styles.userCardBody} ${
            alignImage && alignImage === 'left' && styles.assignedWorkersCard
          }`}
        >
          {actionMenu && (
            <div className={`${styles.editBtnContainer}`}>
              <ActionMenu actions={actions} id={id} />
            </div>
          )}
          <div
            className={`d-flex flex-wrap ${styles.userCardContent}`}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={onSelect && onSelect}
          >
            <div className={styles.userImgContainer}>
              <Image
                className={styles.userImg}
                roundedCircle
                width={100}
                src={profilePic}
                fluid={alignImage === 'left'}
                alt={name}
              />
            </div>
            <div className={styles.userCardData}>
              <div className={styles.userTagContainer}>
                {specialTags &&
                  specialTags.length > 0 &&
                  specialTags.map((tag) => {
                    const newKeyIndex = uuid();
                    return <SpecialTag key={newKeyIndex}>{tag.name}</SpecialTag>;
                  })}
              </div>
              <div className={styles.userDetailsContainer}>
                <Card.Title className={styles.userName}>{name}</Card.Title>
                <Card.Subtitle className={styles.userProfession}>{profession}</Card.Subtitle>
                <Card.Subtitle className={styles.userArea}>{area}</Card.Subtitle>
              </div>

              {tags && tags.length > 0 && (
                <div className={`d-flex flex-wrap ${styles.userTagContainer}`}>
                  {tags.map((tag) => {
                    const newKeyIndex = uuid();
                    return <Tag key={newKeyIndex}>{tag.name}</Tag>;
                  })}
                </div>
              )}

              <div className={styles.userTagContainer}>
                {pricePerHour ? <PriceTag pricePerHour={pricePerHour} /> : null}
              </div>
            </div>
          </div>
          <div
            className={`d-flex align-items-center justify-content-center ${styles.statusBtnContainer}`}
          >
            <div>
              <Status variant={status}>{status}</Status>
            </div>
            {editBtn && (
              <div>
                <Button variant="editBtn" onClick={() => onEdit(id)}>
                  {t('buttons.edit')}
                </Button>
              </div>
            )}
          </div>
          {deleteBtn && (
            <div
              className={styles.BtnContainer}
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              onClick={() => onDelete(id)}
            >
              <XCircleSvg className={styles.delIcon} />
            </div>
          )}

          {selected && (
            <div className={styles.BtnContainer}>
              <Check2CircleSvg className={styles.selectedIcon} />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

User.propTypes = {
  pricePerHour: PropTypes.number,
  tags: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(Object)]),
  specialTags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(Object),
  ]),
  profilePic: PropTypes.string,
  name: PropTypes.string,
  profession: PropTypes.string,
  area: PropTypes.string,
  status: PropTypes.string,
  alignImage: PropTypes.oneOf(['left', 'top']),
  editBtn: PropTypes.bool,
  deleteBtn: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  actions: PropTypes.arrayOf(Object),
  actionMenu: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
};

User.defaultProps = {
  pricePerHour: null,
  tags: [],
  specialTags: [],
  editBtn: true,
  deleteBtn: false,
  onDelete() {},
  onEdit() {},
  selected: false,
  onSelect() {},
  actions: [],
  actionMenu: false,
  id: null,
  className: null,
  profilePic: null,
  name: null,
  profession: null,
  area: null,
  status: null,
  alignImage: 'top',
};

export default User;
