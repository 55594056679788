import service from './wrapper';

export const getTasks = (companyId, projectId) =>
  service.get(`/companies/${companyId}/projects/${projectId}/tasks`);

export const addTask = (companyId, projectId, data) =>
  service.post(`/companies/${companyId}/projects/${projectId}/tasks`, data);

export const getTask = (companyId, projectId, taskId) =>
  service.get(`/companies/${companyId}/projects/${projectId}/tasks/${taskId}`);

export const updateTask = (companyId, projectId, taskId, data) =>
  service.put(`/companies/${companyId}/projects/${projectId}/tasks/${taskId}`, data);

export const deleteTask = (companyId, projectId, taskId) =>
  service.delete(`/companies/${companyId}/projects/${projectId}/tasks/${taskId}`);

export const todaysTasks = async (dateRange) =>
  service.get(
    `/tasks?from=${dateRange.timeMin.substring(0, 10)}&to=${dateRange.timeMax.substring(0, 10)}`
  );

export const getAllTasks = async () => service.get('/tasks');

export const assignWorkersToTask = (companyId, projectId, taskId, data) =>
  service.post(`/companies/${companyId}/projects/${projectId}/tasks/${taskId}/workers`, data);

export const unAssignWorkersToTask = (companyId, projectId, taskId, data) =>
  service.delete(`/companies/${companyId}/projects/${projectId}/tasks/${taskId}/workers`, {
    data,
  });
