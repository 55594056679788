import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CircularProgressBar.module.scss';
import { handleProgressBar, getProgressBarRandomClasses } from './CircularProgressBarHelper';

function CircularProgressBar({ percentage, color }) {
  const classes = getProgressBarRandomClasses();
  useEffect(() => {
    handleProgressBar(percentage, color, classes);
  }, [percentage, color, classes]);
  return (
    <div className={`${styles.circularProgress} ${classes[0]}`}>
      <div className={`${styles.valueContainer} ${classes[1]}`}>0%</div>
    </div>
  );
}

CircularProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default CircularProgressBar;
