/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function ThreeDotSvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
      viewBox="0 0 16 16"
    >
      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </svg>
  );
}

ThreeDotSvg.propTypes = {
  className: PropTypes.string,
};

ThreeDotSvg.defaultProps = {
  className: null,
};

export default ThreeDotSvg;
