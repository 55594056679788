import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from '../button';
import styles from './FeedbackForm.module.scss';

function FeedbackForm({ title, feedback, setFeedback, postFeedback, btnTitle, placeholder }) {
  const { t } = useTranslation('lang');

  const onSubmit = (e) => {
    e.preventDefault();
    postFeedback();
  };

  return (
    <Form onSubmit={onSubmit}>
      {title && title}
      <div>
        <Form.Control
          className={`inputField ${styles.inputField}`}
          placeholder={placeholder}
          as="textarea"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          row={4}
          required
        />
      </div>
      <div className={`${styles.btnContainer}`}>
        <Button variant="saveBtn" type="submit">
          {t(`buttons.${btnTitle}`)}
        </Button>
      </div>
    </Form>
  );
}

FeedbackForm.propTypes = {
  title: PropTypes.node,
  feedback: PropTypes.string,
  setFeedback: PropTypes.func,
  postFeedback: PropTypes.func,
  btnTitle: PropTypes.string,
  placeholder: PropTypes.string,
};

FeedbackForm.defaultProps = {
  title: 'Feedback',
  feedback: '',
  btnTitle: 'save',
  placeholder: 'Write a comment here...',
  setFeedback() {},
  postFeedback() {},
};

export default FeedbackForm;
