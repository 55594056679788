import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import MenuTabs from '../../../components/menu-tabs';
import TaskDetailsForm from '../../../components/task-details-form';
import TaskWorkersForm from '../../../components/task-workers-form';
import { getCompany } from '../../../services/company';
import { getProject } from '../../../services/project';
import TaskFeedback from '../../../components/task-feedback';
import TaskAttachmentForm from '../../../components/task-attachment-form/TaskAttachmentForm';
import PageLoader from '../../../components/page-loader';

function Task() {
  const { companyId, projectId, taskId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('lang');
  const [currentTab, setCurrentTab] = useState(0);
  const [project, setProject] = useState({});
  const [company, setCompany] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [resetForm, setResetForm] = useState({
    reset: false,
    resetId: null,
  });

  const validateCompanyIdAndProjectId = () => {
    getCompany(companyId).then((res) => {
      if (res.status) {
        setCompany(res.data);
        getProject(companyId, projectId).then((response) => {
          if (!response.status) {
            toast.info(t('project.msgs.invalid'));
            navigate('/companies');
          } else {
            setProject(response.data);
            setPageLoading(false);
          }
        });
      } else {
        toast.info(t('company.msgs.invalid'));
        navigate('/companies');
      }
    });
  };

  const onTabChange = (key) => {
    setCurrentTab(key);
    setResetForm({
      reset: true,
      resetId: uuid(),
    });
  };

  useEffect(() => {
    setPageLoading(true);
    validateCompanyIdAndProjectId();
  }, []);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.task')}</title>
      </Helmet>
      <p className="pageTitle">{project?.name}</p>
      <p>{company?.name}</p>
      <div className="tabsScrollMainContainer">
        <MenuTabs
          page="task"
          currentTab={currentTab}
          onTabChange={onTabChange}
          components={[
            {
              tabIndex: 0,
              tabName: t('task.details.title'),
              component: (
                <TaskDetailsForm
                  taskId={taskId}
                  companyId={companyId}
                  projectId={projectId}
                  company={company}
                  project={project}
                  handleSubmit={onTabChange}
                  resetForm={resetForm}
                />
              ),
            },
            {
              tabIndex: 1,
              tabName: t('task.workers.title'),
              disabled: !taskId,
              component: <TaskWorkersForm handleSubmit={onTabChange} resetForm={resetForm} />,
            },
            {
              tabIndex: 2,
              tabName: t('task.attachments'),
              disabled: !taskId,
              component: <TaskAttachmentForm handleSubmit={onTabChange} resetForm={resetForm} />,
            },
            {
              tabIndex: 3,
              tabName: t('task.feedback.title'),
              disabled: !taskId,
              component: <TaskFeedback resetForm={resetForm} />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Task;
