import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styles from './PageNotFound.module.scss';

function PageNotFound() {
  const { t } = useTranslation('lang');
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${styles.notFoundContainer}`}
    >
      <Helmet>
        <title>Hiukka - {t('page_titles.not_found')}</title>
      </Helmet>
      <p>Looks like you are lost..!</p>
      <p>404 Page Not Found</p>
      <Link className={`${styles.backHomeBtn}`} to="/">
        Back To Home
      </Link>
    </div>
  );
}

export default PageNotFound;
