import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { v4 as uuid } from 'uuid';
import ProjectSettings from './project-settings';
import MenuTabs from '../../../components/menu-tabs';
import BasicSettings from './basic-settings';

function Settings() {
  const { t } = useTranslation('lang');
  const [activeTab, setActiveTab] = useState(0);
  const [resetForm, setResetForm] = useState({ reset: false, resetId: uuid() });

  const onTabChange = (key) => {
    if (activeTab !== parseInt(key, 10)) {
      setResetForm({
        reset: true,
        resetId: uuid(),
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.settings')}</title>{' '}
      </Helmet>
      <p className="pageTitle">{t('settings.title')}</p>
      <div className="tabsScrollMainContainer">
        <MenuTabs
          currentTab={activeTab}
          onTabChange={onTabChange}
          components={[
            {
              tabIndex: 0,
              tabName: t('settings.basic.title'),
              component: (
                <BasicSettings
                  setActiveTab={setActiveTab}
                  resetForm={resetForm}
                  setResetForm={setResetForm}
                />
              ),
            },
            {
              tabIndex: 1,
              tabName: t('settings.project.title'),
              component: <ProjectSettings setActiveTab={setActiveTab} resetForm={resetForm} />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Settings;
