import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { getCompanyStatuses } from '../../../services/company';

const useConstants = () => {
  const [statuses, setStatuses] = useState([]);
  const { t } = useTranslation('lang');

  const fetchCompanyStatuses = () => {
    getCompanyStatuses().then((res) => {
      if (res.status) {
        setStatuses([
          { id: uuid(), label: t('project.dropdown.all'), value: 'all' },
          ...res.data.map((d) => ({
            id: uuid(),
            label: t(`project.dropdown.${d.toLowerCase()}`),
            value: d,
          })),
        ]);
      }
    });
  };

  useEffect(() => {
    fetchCompanyStatuses();
  }, []);

  return { statuses };
};

export default useConstants;
