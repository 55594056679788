const COMPANY_DETAILS = {
  name: '',
  vat: '',
  contact_person: '',
  phone: '',
  type: 'company',
  url: '',
  address: '',
  zip: '',
  city: '',
  e_invoicing_address: '',
  operator_id: '',
};

export default COMPANY_DETAILS;
