import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import PropTypes from 'prop-types';
import moment from 'moment';
import { eventColors } from '../../data/index';
import GcalendarToolbar from '../g-calendar-toolbar/GcalendarToolbar';

const localizer = momentLocalizer(moment);

function Gcalendar({ events, getDateFromCalendar }) {
  const newEvents = events.map((event) => {
    if (event.status?.toLowerCase() !== 'cancelled') {
      const endDate = event.end.dateTime ? new Date(event.end.dateTime) : new Date(event.end.date);

      endDate.setDate(endDate.getDate() - 1);
      return {
        id: event.id,
        title: event.summary,
        start: event.start.dateTime ? new Date(event.start.dateTime) : new Date(event.start.date),
        end: endDate,
        desc: event.description,
        color:
          event.colorId && eventColors[event.colorId]
            ? eventColors[event.colorId].background
            : '#D56730',
        htmlLink: event.htmlLink,
        allDay: !!event.start.date,
      };
    }
    return undefined;
  });

  const handleClick = (val) => {
    getDateFromCalendar(new Date(val));
  };

  return (
    <div>
      <Calendar
        className="projectCalendar"
        localizer={localizer}
        views={[Views.MONTH]}
        components={{ toolbar: GcalendarToolbar }}
        events={newEvents || []}
        selectable
        onNavigate={handleClick}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: {
            backgroundColor: event.color,
            width: '10px',
            height: '10px',
            marginLeft: '5px',
            marginBottom: '2px',
            '@media(minWidth: 992px)': {
              marginLeft: '10px',
            },
          },
        })}
      />
    </div>
  );
}

Gcalendar.propTypes = {
  events: PropTypes.arrayOf(Object),
  getDateFromCalendar: PropTypes.func,
};

Gcalendar.defaultProps = {
  events: [],
  getDateFromCalendar() {},
};

export default Gcalendar;
