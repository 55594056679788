import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListSvg from '../../components/svgs/list';
import XLgSvg from '../../components/svgs/x-lg';
import styles from './WorkerNAvigationMenu.module.scss';
import { getDate, handleStoreChanges } from '../../lib/dateLib';
import Footer from '../../components/footer';
import { getUserProfile } from '../../redux/features/users/userSlice';
import { updateWorker } from '../../services/workers';

function WorkerNavigationMenu() {
  const { t } = useTranslation('lang');
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('');
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { data } = userInfo;
  const helper = useSelector((state) => state.helper);

  const closeMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (lang) => {
    updateWorker(data?.uuid, {
      preferred_language: lang,
    }).then((res) => {
      if (res.status) {
        dispatch(getUserProfile());
        return toast.success(t('settings.msgs.saved'));
      }
      return undefined;
    });
  };

  useEffect(() => {
    handleStoreChanges();
    if (pathname === '/' || pathname === '/dashboard') {
      setActiveTab(getDate(new Date()));
    } else if (pathname === '/profile') {
      setActiveTab(t(`worker_nav.my_profile`));
    } else if (pathname.includes('task')) {
      setActiveTab(
        <div>
          <p>{helper?.workerTaskViewPageTitle}</p>
          <p>{t('worker_nav.task_details')}</p>
        </div>
      );
    } else if (pathname.includes('settings')) {
      setActiveTab(t('sidebar.footer.setting'));
    } else {
      setActiveTab('');
    }
  }, [pathname, helper]);

  return (
    <div className={`${styles.mobileMenuContainer}`}>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}
      >
        <div className={`${styles.headerText}`}>
          <div>{activeTab}</div>
        </div>
        {!isMenuOpen && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className={styles.menuIcon}
            onClick={closeMenu}
          >
            <ListSvg />
          </div>
        )}
      </div>
      <div className={`d-flex flex-column ${styles.menuContent} ${!isMenuOpen && `d-none`}`}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          className={styles.closeIcon}
          onClick={closeMenu}
        >
          <XLgSvg />
        </div>
        <div className={`${styles.linkContainer}`}>
          <p className={`${styles.menuText}`}>{t('worker_nav.menu')}</p>
          <Link className={`${styles.menuLink}`} to="/" onClick={closeMenu}>
            {t('worker_nav.dashboard')}
          </Link>
          <Link className={`${styles.menuLink}`} to="/dashboard" onClick={closeMenu}>
            {t('worker_nav.task_list')}
          </Link>
          <Link className={`${styles.menuLink}`} to="/profile" onClick={closeMenu}>
            {t('worker_nav.my_profile')}
          </Link>
        </div>
        <div className={`d-flex align-items-center justify-content-center ${styles.imgContainer}`}>
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('english')}
            >
              <img src="/images/flag1.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('finnish')}
            >
              <img src="/images/flag2.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
          <div>
            <button
              className={`${styles.imgBtn}`}
              type="button"
              onClick={() => changeLanguage('russian')}
            >
              <img src="/images/flag3.png" className={styles.flagImg} alt="flag" />
            </button>
          </div>
        </div>
        <div className={`${styles.footerContainer}`}>
          <Footer editProfile={false} onSelect={closeMenu} />
        </div>
      </div>
    </div>
  );
}

export default WorkerNavigationMenu;
