/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function ChevronLeft({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  );
}

ChevronLeft.propTypes = {
  className: PropTypes.string,
};

ChevronLeft.defaultProps = {
  className: null,
};

export default ChevronLeft;
