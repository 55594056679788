import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import EllipsisSolid from '../svgs/ellipsis-solid';
import styles from './ActionMenu.module.scss';

function CustomToggle() {
  // eslint-disable-next-line react/prop-types
  return React.forwardRef(({ onClick }, ref) => (
    <div
      role="button"
      ref={ref}
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <EllipsisSolid className={`${styles.actionMenuIcon}`} />
    </div>
  ));
}
function ActionMenu({ actions = [], className, id }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle()} />
      <Dropdown.Menu className={`fontSegoe ${className} ${styles.actionMenuContainer}`}>
        {actions.map((action) => {
          const newKeyIndex = uuid();
          return (
            <Dropdown.Item
              className={`${styles.actionMenuLink}`}
              key={newKeyIndex}
              disabled={action.disabled ?? false}
              onClick={() => action.onClick(id)}
            >
              <span>{action.actionLabel}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(Object),
  className: PropTypes.string,
  id: PropTypes.string,
};

ActionMenu.defaultProps = {
  actions: [],
  className: null,
  id: null,
};

export default ActionMenu;
