import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './WorkerTaskOverview.module.scss';
import TelephoneSvg from '../svgs/telephone';
import PersonSvg from '../svgs/person';
import GeoAltSvg from '../svgs/geo-alt';
import ClockSvg from '../svgs/clock';

function WorkerTaskOverview({
  title,
  contactPerson,
  contactNumber,
  address,
  date,
  startTime,
  endTime,
  jobDescription,
  onClick,
  color,
}) {
  const { t } = useTranslation('lang');

  return (
    <div
      style={color && { background: color }}
      className={`${styles.companyDetailsContainer}`}
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
      onClick={onClick}
    >
      {title && <p className={`${styles.companyTitle}`}>{title}</p>}

      {contactPerson && (
        <div className={`d-flex align-items-center ${styles.contentContainer}`}>
          <div>
            <PersonSvg />
          </div>
          <p>{contactPerson}</p>
        </div>
      )}

      {contactNumber && (
        <div className={`d-flex align-items-center ${styles.contentContainer}`}>
          <div>
            <TelephoneSvg />
          </div>
          <p>{contactNumber}</p>
        </div>
      )}

      {address && (
        <div className={`d-flex align-items-center ${styles.contentContainer}`}>
          <div>
            <GeoAltSvg />
          </div>
          <p>{address}</p>
        </div>
      )}
      {startTime && endTime && (
        <div className={`d-flex align-items-center ${styles.contentContainer}`}>
          <div>
            <ClockSvg />
          </div>
          <p>
            {date && <span>{date} , </span>}
            <span>{startTime}</span> -<span>{endTime}</span>
          </p>
        </div>
      )}
      {jobDescription && (
        <div className={`${styles.contentContainer}`}>
          <p className={`${styles.companyTitle}`}>
            {t('worker_home.worker_task_overview.job_description')} :
          </p>
          <p className={`${styles.companyDec}`}>{jobDescription}</p>
        </div>
      )}
    </div>
  );
}

WorkerTaskOverview.propTypes = {
  title: PropTypes.string,
  contactPerson: PropTypes.string,
  contactNumber: PropTypes.string,
  address: PropTypes.string,
  date: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  jobDescription: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

WorkerTaskOverview.defaultProps = {
  title: null,
  contactPerson: null,
  contactNumber: null,
  address: null,
  date: null,
  startTime: null,
  endTime: null,
  jobDescription: null,
  onClick() {},
  color: null,
};

export default WorkerTaskOverview;
