import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClockSvg from '../svgs/clock';
import styles from './WorkerTaskTimer.module.scss';
import FormTimePicker from '../form-time-picker';

const timeType = PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]);

function WorkerTaskTimer({
  startTime,
  onStart,
  onStartChange,
  endTime,
  onEnd,
  onEndChange,
  confirmTimeChange,
  setConfirmTimeChange,
}) {
  const { t } = useTranslation('lang');

  const onTimeUnlock = () => {
    if (confirmTimeChange) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common_messages.please_confirm'))) {
        setConfirmTimeChange(false);
      }
    }
  };

  return (
    <div className={`${styles.timerContainer}`}>
      <div className="d-flex flex-column align-items-center">
        <p> {t('worker_home.worker_task_time.start_time')}</p>
        <div className={`d-flex align-items-center ${styles.clockContainer}`}>
          <div role="button" onKeyDown={() => {}} tabIndex={0} onClick={onStart}>
            <ClockSvg />
          </div>
          <div role="button" onKeyDown={() => {}} tabIndex={-1} onClick={onTimeUnlock}>
            <FormTimePicker
              className={`inputField ${styles.inputField}`}
              required
              value={startTime}
              readOnly={confirmTimeChange}
              onChange={onStartChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <p> {t('worker_home.worker_task_time.finish_time')}</p>
        <div className={`d-flex align-items-center ${styles.clockContainer}`}>
          <div
            className={`${styles.finishTime}`}
            role="button"
            onKeyDown={() => {}}
            tabIndex={-2}
            onClick={onEnd}
          >
            <ClockSvg />
          </div>
          <div role="button" onKeyDown={() => {}} tabIndex={-3} onClick={onTimeUnlock}>
            <FormTimePicker
              className={`inputField ${styles.inputField}`}
              required
              value={endTime}
              readOnly={confirmTimeChange}
              onChange={onEndChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

WorkerTaskTimer.propTypes = {
  startTime: timeType,
  onStart: PropTypes.func,
  onStartChange: PropTypes.func,
  endTime: timeType,
  onEnd: PropTypes.func,
  onEndChange: PropTypes.func,
  confirmTimeChange: PropTypes.bool,
  setConfirmTimeChange: PropTypes.func,
};

WorkerTaskTimer.defaultProps = {
  startTime: '',
  onStart() {},
  onStartChange() {},
  endTime: '',
  onEnd() {},
  onEndChange() {},
  confirmTimeChange: false,
  setConfirmTimeChange() {},
};

export default WorkerTaskTimer;
