import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import styles from './Home.module.scss';
import {
  getCurrentDate,
  getGreetings,
  getTime,
  getCurrentTime,
  getTimeFormattedIn24Hours,
} from '../../../../lib/dateLib';
import WorkerTaskOverview from '../../../../components/worker-task-overview';
import WorkerTaskTimer from '../../../../components/worker-task-timer';
import WorkerTaskCostInputs from '../../../../components/worker-task-cost-inputs';
import WorkerTaskAttachments from '../../../../components/worker-task-attachments';
import Button from '../../../../components/button';
import { getWorkerTasks, submitTimeSheet, getTimeSheets } from '../../../../services/workers';
import PageLoader from '../../../../components/page-loader';
import { TimeSheetForm, lunchMinutes } from './constants';
import { uploadAttachment } from '../../../../services/attachments';
import { isEmpty, Console } from '../../../../utils';
import Loader from '../../../../components/side-loader';
import { getEvent } from '../../../../services/googleCalendar';

function Home() {
  const { t } = useTranslation('lang');
  const userInfo = useSelector((state) => state.userLogin.userInfo.data);
  const [form, setForm] = useState(TimeSheetForm);
  const [taskDetails, setTaskDetails] = useState({});
  const [confirmTimeChange, setConfirmTimeChange] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [todaysTasks, setTodaysTasks] = useState([]);
  const [event, setEvent] = useState(null);

  const checkExistingTimeSheet = async (taskId, userId) => {
    try {
      const { data: timeSheets } = await getTimeSheets(
        taskId,
        userId,
        getCurrentDate(),
        getCurrentDate()
      );
      return timeSheets?.items?.length > 0;
    } catch (error) {
      Console.log(error);
    }
    return undefined;
  };

  const validateNextTaskAndUpdate = async (tasks) => {
    setIsPageLoading(true);
    const task = tasks[0];
    const result = await checkExistingTimeSheet(task.uuid, userInfo.uuid);
    if (result) {
      tasks.shift();
      setTodaysTasks([...tasks]);
      setTaskDetails({});
      setForm(TimeSheetForm);
      setIsPageLoading(false);
      if (tasks.length !== 0) {
        validateNextTaskAndUpdate(tasks);
      }
    } else {
      setForm({
        ...TimeSheetForm,
        end_time: task?.daily_work_end ?? getCurrentTime(),
        start_time: task?.daily_work_start ?? getCurrentTime(),
      });
      setTaskDetails(task);
      setIsPageLoading(false);
    }
  };

  const fetchWorkerTask = async () => {
    try {
      const { data } = await getWorkerTasks({
        uuid: userInfo.uuid,
        date: getCurrentDate(false, 'YYYY-MM-DD'),
        limit: 50,
        submitted: false,
      });
      const tasks = data.items
        .map((task) => task.task)
        .filter((task) => {
          const startDate = getCurrentDate(false, false, task.contract_period_start);
          const endDate = getCurrentDate(false, false, task.contract_period_end);
          const currentDate = getCurrentDate();
          return moment(currentDate).isBetween(startDate, endDate, null, '[]');
        })
        .sort((a, b) => {
          const prev = moment(`${getCurrentDate()} ${a.daily_work_start}`);
          const curr = moment(`${getCurrentDate()} ${b.daily_work_start}`);
          return prev - curr;
        });

      setTodaysTasks(tasks);
      if (tasks.length !== 0) {
        validateNextTaskAndUpdate(tasks);
      }
    } catch (error) {
      Console.log(error);
    }
    setIsPageLoading(false);
  };

  const onClockTap = () => getTimeFormattedIn24Hours(getTime(moment().toDate()));

  const onStartClockTap = () => {
    if (confirmTimeChange) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common_messages.please_confirm'))) {
        setConfirmTimeChange(false);
        return onClockTap();
      }
    }

    return onClockTap();
  };

  const onEndClockTap = () => {
    if (confirmTimeChange) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common_messages.please_confirm'))) {
        setConfirmTimeChange(false);
        return onClockTap();
      }
    }

    return onClockTap();
  };

  const onDelete = (id) => {
    setForm({
      ...form,
      attachments: form.attachments.filter((f) => f.uuid !== id),
    });
  };

  const getLunchEndTime = () =>
    moment(form.start_time, ['HH:mm']).add(lunchMinutes, 'minutes').format('HH:mm').toString();

  const onAttachmentsUploadAndDelete = async (id, attachments) => {
    setLoading(true);
    try {
      const filesToUpload = attachments.map((a) => a.file);
      await Promise.all([
        ...filesToUpload.map((file) => {
          const formData = new FormData();
          formData.append('', file);
          return uploadAttachment('time-entries', id, formData);
        }),
      ]);
      setLoading(false);
      validateNextTaskAndUpdate(todaysTasks);
      toast.success(t('worker.timesheet.msgs.submitted_success'));
    } catch (error) {
      setLoading(false);
    }
  };

  const onSubmit = () => {
    if (!form.start_time) {
      return toast.error(t('worker.timesheet.msgs.invalid_start_time'));
    }

    if (!form.end_time) {
      return toast.error(t('worker.timesheet.msgs.invalid_end_time'));
    }

    const startTime = moment(`${getCurrentDate()} ${form.start_time}`);
    const endTime = moment(`${getCurrentDate()} ${form.end_time}`);
    const currTime = moment(`${getCurrentDate()} ${getCurrentTime()}`);

    if (endTime > currTime) {
      return toast.error(t('worker.timesheet.msgs.advanced_end_time'));
    }
    if (startTime > endTime) {
      return toast.error(t('worker.timesheet.msgs.advanced_start_time'));
    }

    const duration = moment.duration(endTime.diff(startTime));
    const totalTimeLogMinutes = duration.asMinutes();
    let totalExtraBreakMinutes = 0;
    if (form.had_extra_break) {
      const extraBreakStartTime = moment(`${getCurrentDate()} ${form.extra_break.start_time}`);
      const extraBreakEndTime = moment(`${getCurrentDate()} ${form.extra_break.end_time}`);
      const newDuration = moment.duration(extraBreakEndTime.diff(extraBreakStartTime));
      totalExtraBreakMinutes = newDuration.asMinutes();
      if (totalTimeLogMinutes < totalExtraBreakMinutes) {
        return toast.error(t('worker.timesheet.msgs.invalid_extra_break'));
      }
      if (extraBreakStartTime > extraBreakEndTime) {
        return toast.error(t('worker.timesheet.msgs.advanced_start_time'));
      }
    }
    if (form.had_lunch) {
      if (form.had_extra_break) {
        if (
          totalTimeLogMinutes < lunchMinutes ||
          totalTimeLogMinutes - totalExtraBreakMinutes < lunchMinutes
        ) {
          return toast.error(t('worker.timesheet.msgs.invalid_lunch_and_break'));
        }
      }
      if (totalTimeLogMinutes < lunchMinutes) {
        return toast.error(t('worker.timesheet.msgs.invalid_lunch'));
      }
    }

    const { attachments } = form;
    if (!form.had_lunch) delete form.lunch_break;
    if (!form.had_extra_break) delete form.extra_break;
    // delete form.attachments;
    // delete form.had_lunch;
    // delete form.had_extra_break;

    const confirmed = window.confirm(t('worker.timesheet.msgs.confirm'));
    if (confirmed) {
      return submitTimeSheet(taskDetails.uuid, userInfo.uuid, form).then((res) => {
        if (res.is_success) {
          onAttachmentsUploadAndDelete(res.data.uuid, attachments);
        }
      });
    }

    return undefined;
  };

  const fetchGoogleEvent = async () => {
    try {
      const res = await getEvent(userInfo.email, taskDetails?.event_id);
      setEvent(res);
    } catch (error) {
      Console.log({ error });
    }
  };

  useEffect(() => {
    fetchWorkerTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskDetails && taskDetails.event_id) {
      fetchGoogleEvent();
    }
  }, [taskDetails]);

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div className={`${styles.workerMainContainer}`}>
      <Helmet>
        <title>Hiukka - {t('page_titles.dashboard')}</title>
      </Helmet>
      <p className={`${styles.workerTitle}`}>
        {t(`worker_home.greetings.${getGreetings()}`)}
        {', '}
        {userInfo?.firstname} !
      </p>
      {isEmpty(taskDetails) ? (
        <div className={`${styles.messagesBg}`}>
          <p>{t('worker.timesheet.msgs.no_assignment')}</p>
        </div>
      ) : (
        <div>
          {loading && <Loader />}
          <p className={`${styles.workerTitle}`}>{t(`worker_home.assignment_message`)}:</p>
          <div className={`${styles.taskCardContainer}`}>
            <div>
              <WorkerTaskOverview
                title={taskDetails.name}
                contactPerson={taskDetails?.project?.company?.contact_person}
                contactNumber={taskDetails?.project?.company?.phone}
                startTime={
                  taskDetails?.daily_work_start ? getTime(taskDetails.daily_work_start) : null
                }
                endTime={taskDetails?.daily_work_end ? getTime(taskDetails.daily_work_end) : null}
                jobDescription={event?.description}
              />
            </div>

            <p className={`${styles.workerTitle}`}>{t('worker_home.ready_to_start_work')}</p>
            <div>
              <WorkerTaskTimer
                startTime={form.start_time}
                onStart={() => setForm({ ...form, start_time: onStartClockTap() })}
                onEnd={() => setForm({ ...form, end_time: onEndClockTap() })}
                confirmTimeChange={confirmTimeChange}
                setConfirmTimeChange={setConfirmTimeChange}
                endTime={form.end_time}
                onStartChange={(val) =>
                  setForm({
                    ...form,
                    start_time: getTimeFormattedIn24Hours(getTime(val)),
                  })
                }
                onEndChange={(val) =>
                  setForm({
                    ...form,
                    end_time: getTimeFormattedIn24Hours(getTime(val)),
                  })
                }
              />
            </div>
            <div className={`${styles.checkBoxMainContainer}`}>
              <p>Did you have breaks ?</p>
              <div>
                <label className="checkboxContainer" htmlFor="lunch_break">
                  <Form.Check.Input
                    type="checkbox"
                    id="lunch_break"
                    checked={form.had_lunch}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        had_lunch: e.target.checked,
                        lunch_break: {
                          start_time: form.start_time,
                          end_time: getLunchEndTime(),
                        },
                      })
                    }
                  />
                  <span className="checkIcon" />
                  <div className={`${styles.labelText}`}>
                    {t('worker.timesheet.msgs.had_lunch', {
                      min: lunchMinutes,
                    })}
                  </div>
                </label>
              </div>
              <div>
                <label className="checkboxContainer" htmlFor="had_extra_break">
                  <Form.Check.Input
                    type="checkbox"
                    id="had_extra_break"
                    checked={form.had_extra_break}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        had_extra_break: e.target.checked,
                      })
                    }
                  />
                  <span className="checkIcon" />
                  <div className={`${styles.labelText}`}>
                    {t('worker.timesheet.msgs.had_break')}
                  </div>
                </label>
                {form.had_extra_break && (
                  <div>
                    <div>
                      <WorkerTaskTimer
                        startTime={form.extra_break.start_time}
                        onStart={() =>
                          setForm({
                            ...form,
                            extra_break: {
                              ...form.extra_break,
                              start_time: onStartClockTap(),
                            },
                          })
                        }
                        onEnd={() =>
                          setForm({
                            ...form,
                            extra_break: {
                              ...form.extra_break,
                              end_time: onEndClockTap(),
                            },
                          })
                        }
                        confirmTimeChange={false}
                        endTime={form.extra_break.end_time}
                        onStartChange={(val) =>
                          setForm({
                            ...form,
                            extra_break: {
                              ...form.extra_break,
                              start_time: getTimeFormattedIn24Hours(getTime(val)),
                            },
                          })
                        }
                        onEndChange={(val) =>
                          setForm({
                            ...form,
                            extra_break: {
                              ...form.extra_break,
                              end_time: getTimeFormattedIn24Hours(getTime(val)),
                            },
                          })
                        }
                      />
                    </div>
                    <div className={`${styles.breakContainer}`}>
                      <Form.Control
                        className="inputField"
                        as="textarea"
                        value={form.extra_break.reason}
                        placeholder={t('worker_home.reason_for_break')}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            extra_break: {
                              ...form.extra_break,
                              reason: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <WorkerTaskCostInputs
                kmDriven={form.distance_driven}
                otherCosts={form.other_costs}
                onKmDrivenChange={(val) => setForm({ ...form, distance_driven: parseInt(val, 10) })}
                onOtherCostsChange={(val) => setForm({ ...form, other_costs: parseInt(val, 10) })}
              />
            </div>
            <p className={`${styles.workerTitle}`}>{t('worker_home.files_photos')}</p>
            <div>
              <WorkerTaskAttachments
                files={form.attachments}
                setFiles={(val) => setForm({ ...form, attachments: val })}
                onDelete={onDelete}
              />
            </div>
            <div className={`${styles.feedbackContainer}`}>
              <p className={`${styles.workerTitle}`}>{t('worker_home.feedback')}</p>
              <p className={`${styles.workerTitle}`}>{t('worker_home.feedback_message')}</p>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                type="text"
                value={form.feedback}
                placeholder={t('worker_home.feedback_comment')}
                onChange={(e) => setForm({ ...form, feedback: e.target.value })}
              />
              <div className={`${styles.workerBtnContainer}`}>
                <Button onClick={onSubmit} variant="saveBtn">
                  {t(`buttons.submit`)}
                </Button>
              </div>
            </div>
          </div>
          <p className={`${styles.workerDec}`}>{t('worker_home.form_warning')}</p>
        </div>
      )}
    </div>
  );
}

export default Home;
