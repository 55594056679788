import axios from 'axios';
import { getNewAccessToken } from './auth';

const googleService = axios.create({
  baseURL: process.env.REACT_APP_GOOGLE_CALENDAR_BASE_URL,
});

googleService.interceptors.request.use(async (request) => {
  const { accessToken, expiryDate } = JSON.parse(localStorage.getItem('accessToken') || '{}');
  let token = accessToken;
  if (!accessToken || (expiryDate - Date.now()) / 1000 <= 120) {
    const user = JSON.parse(localStorage.getItem('userInfo') || '{}');
    const { token: data } = await getNewAccessToken(user.data.uuid);
    const { access_token: newAccessToken, expiry_date: newExpiryDate } = data;
    localStorage.setItem(
      'accessToken',
      JSON.stringify({ accessToken: newAccessToken, expiryDate: newExpiryDate })
    );
    token = newAccessToken;
  }
  request.headers.common.Authorization = `Bearer ${token}`;
  return request;
});

googleService.interceptors.response.use(
  (response) => response.data,
  (err) => Promise.reject(err.response.data)
);

export default googleService;
