import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import PersonSvg from '../../../../components/svgs/person';
import Button from '../../../../components/button';
import FeedbackForm from '../../../../components/feedback-form';
import FeedbackList from '../../../../components/feedback-list';
import WorkerTaskOverview from '../../../../components/worker-task-overview';
import TelephoneSvg from '../../../../components/svgs/telephone';
import GeoAltSvg from '../../../../components/svgs/geo-alt';
import styles from './TaskDetails.module.scss';
import PageLoader from '../../../../components/page-loader';
import { getWorkerTask } from '../../../../services/workers';
import { getCurrentDate, getTime, getDate } from '../../../../lib/dateLib';
import { addFeedback } from '../../../../services/feedbacks';
import { updateWorkerTaskViewPageTitle } from '../../../../redux/features/helper/helperSlice';
import { Console } from '../../../../utils';

function TaskDetails() {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('lang');
  const [pageLoading, setPageLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState(null);
  const [feedback, setFeedback] = useState('');
  const userInfo = useSelector((state) => state.userLogin.userInfo.data);

  const getCompany = (task) => task?.project?.company;

  const getAddress = (company) => {
    let address = '';
    if (company) {
      address += company?.address ? `${company.address}, ` : '';
      address += company?.city ? `${company.city}, ` : '';
      address += company?.zip ? `${company.zip}` : '';
    }
    return address;
  };

  const fetchTaskDetails = async () => {
    try {
      const res = await getWorkerTask(userInfo.uuid, taskId);
      if (res.data) {
        setTaskDetails({ ...res.data });
        dispatch(updateWorkerTaskViewPageTitle(getCompany(res.data)?.name ?? res.data.name));
      }
      setPageLoading(false);
    } catch (error) {
      navigate('/dashboard');
    }
  };

  const openMap = (company) => {
    window.open(`${process.env.REACT_APP_GOOGLE_MAPS_BASE_URL}&query=${getAddress(company)}`);
  };

  const postFeedback = async () => {
    try {
      await addFeedback(
        getCompany(taskDetails).uuid,
        taskDetails?.project?.uuid,
        taskDetails?.uuid,
        { feedback }
      );
      await getWorkerTask(userInfo.uuid, taskId);
      await fetchTaskDetails();
      setFeedback('');
      toast.success(t('worker.task.note_added'));
    } catch (error) {
      Console.log(error);
    }
  };

  useEffect(() => {
    setPageLoading(true);
    fetchTaskDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(updateWorkerTaskViewPageTitle(null));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (pageLoading) {
    return <PageLoader />;
  }

  if (!taskDetails) {
    return (
      <>
        <Helmet>
          <title>Hiukka - {t('page_titles.task')}</title>
        </Helmet>
        <div>{t('worker.task.no_task_message')}</div>
      </>
    );
  }

  return (
    <div className={`${styles.clientMainContainer}`}>
      <Helmet>
        <title>Hiukka - {t('page_titles.task')}</title>
      </Helmet>
      <div className={`d-flex align-items-center justify-content-between ${styles.clientHeader}`}>
        <div>
          <PersonSvg />
        </div>
        <div>
          <p>{t('worker.task.client')}</p>
          <p>{getCompany(taskDetails)?.contact_person ?? getCompany(taskDetails)?.name}</p>
        </div>
        <div className="d-flex justify-content-between">
          {/* Call option will only be visible if task details have client phone number */}
          {getCompany(taskDetails)?.phone && (
            <div>
              <a
                className={`d-flex align-items-center justify-content-center ${styles.phoneIconContainer}`}
                href={`tel:${getCompany(taskDetails)?.phone}`}
              >
                <div>
                  <TelephoneSvg className={`${styles.phoneIcon}`} />
                </div>
                <p>{t('worker.task.call')}</p>
              </a>
            </div>
          )}
          {/* Map option will be visible if task details have address fields */}
          {getAddress(getCompany(taskDetails)) && (
            <div>
              <Button
                className="d-flex align-items-center justify-content-center"
                variant="addBtnLg"
                icon={<GeoAltSvg />}
                onClick={() => openMap(getCompany(taskDetails))}
              >
                {t('worker.task.map')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div>
        <WorkerTaskOverview
          contactNumber={getCompany(taskDetails)?.phone}
          address={getAddress(getCompany(taskDetails))}
          date={getCurrentDate()}
          startTime={taskDetails?.daily_work_start ? getTime(taskDetails?.daily_work_start) : null}
          endTime={taskDetails?.daily_work_end ? getTime(taskDetails?.daily_work_end) : null}
        />
      </div>
      <div className={`d-flex justify-content-between ${styles.periodContainer}`}>
        <div>
          <p>{t('worker.task.contract_period')}</p>
          <p>
            {taskDetails?.contract_period_start
              ? getDate(taskDetails?.contract_period_start)
              : 'NA'}{' '}
            - {taskDetails?.contract_period_end ? getDate(taskDetails?.contract_period_end) : 'NA'}
          </p>
        </div>
        <div>
          <p>{t('worker.task.daily_work_time')}</p>
          <p>
            {taskDetails?.daily_work_start ? getTime(taskDetails?.daily_work_start) : 'NA'} -{' '}
            {taskDetails?.daily_work_end ? getTime(taskDetails?.daily_work_end) : 'NA'}
          </p>
        </div>
      </div>
      {taskDetails?.description ? (
        <div className={`${styles.decContainer}`}>
          <p>{t('worker.task.description')}</p>
          <p>{taskDetails?.description}</p>
        </div>
      ) : null}
      <div className={`${styles.feedContainer}`}>
        <div>
          <FeedbackForm
            feedback={feedback}
            placeholder={t('worker.task.placeholder')}
            setFeedback={setFeedback}
            postFeedback={postFeedback}
            title={
              <div className="d-flex align-items-center justify-content-between">
                <p>{t('worker.task.notes')}</p>
                <p className={`${styles.dayText}`}>{t('worker.task.feedback_msg')}</p>
              </div>
            }
            btnTitle="post_note"
          />
        </div>
        <div>
          <FeedbackList
            feedbacks={taskDetails?.feedback?.length !== 0 ? taskDetails.feedback : []}
          />
        </div>
      </div>
    </div>
  );
}

export default TaskDetails;
