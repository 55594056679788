import service from './wrapper';

export const getWorkTypes = (industryId) => service.get(`/industries/${industryId}/work-types`);

export const getWorkTypeById = (industryId, id) =>
  service.get(`/industries/${industryId}/work-types/${id}`);

export const addWorkType = (industryId, data) =>
  service.post(`/industries/${industryId}/work-types`, data);

export const updateWorkTypeById = (industryId, id, data) =>
  service.put(`/industries/${industryId}/work-types/${id}`, data);

export const deleteWorkType = (industryId, id) =>
  service.delete(`/industries/${industryId}/work-types/${id}`);

export const getWorkTypeSkills = () => service.get(`/industries/work-type/skills`);
