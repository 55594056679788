/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Form, Image } from 'react-bootstrap';
import DropZone from '../dropzone';
import Button from '../button';
import MultiSelectDropDown from '../multi-select-dropdown';
import FormDatePicker from '../form-date-picker';
import styles from './BasicDetails.module.scss';
import {
  addWorker,
  inviteWorker,
  updateWorker,
  mapWorkTypesToWorker,
  mapWorkTypePermissionsToWorker,
} from '../../services/workers';
import useConstants from './useConstants';
import FILE_TYPES from '../dropzone/constants';
import { downloadAttachment, uploadAttachment } from '../../services/attachments';
import { getWorkTypeSkills } from '../../services/workTypes';
import { getWorkTypePermissions } from '../../services/workTypePermissions';
import SideLoader from '../side-loader';
import { Console } from '../../utils';

function BasicDetails({
  workerId,
  basicDetails,
  setBasicDetails,
  handleSubmit,
  isFieldDisabled,
  preloadedWorkTypes,
  preloadedWorkTypePermissions,
  workerOldState,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const { WORKER_BASIC_DETAILS: form } = useConstants();
  const [workerBasicDetails, setWorkerBasicDetails] = useState(null);
  const { workerStatus } = useSelector((state) => state.worker);
  const [file, setFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);
  const [skills, setSkills] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [profileImageOldState, setProfileImageOldState] = useState(null);
  const [loading, setLoading] = useState(false);

  const convertOptions = () => [
    ...workerStatus.map((status) => ({
      name: t(`worker.details.basic.status_dropdown.${status.toLowerCase()}`).toUpperCase(),
      value: status,
    })),
  ];

  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.basic_details) {
        fieldsToUpdate.basic_details = [];
      }
      if (!fieldsToUpdate.basic_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          basic_details: [...fieldsToUpdate.basic_details, key],
        }));
      }
    }
  };

  const getProfileImage = () => {
    downloadAttachment('users', workerId, workerBasicDetails.profile_pic.uuid).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      setProfileImage(url);
      setProfileImageOldState(url);
    });
  };

  const uploadImage = async (wid) => {
    let profileId = workerBasicDetails?.profile_pic?.uuid;
    if (file) {
      const reqData = new FormData();
      reqData.append('', file);
      const { is_success: isSuccess, data } = await uploadAttachment('users', wid, reqData);
      if (isSuccess) profileId = data[0].uuid;
    }
    return profileId;
  };

  const updateWorkTypeAndWorkTypePermissions = async (workerUuid, formValues) => {
    try {
      const { workTypes, workTypePermissions } = formValues;

      const workTypesToAdd = workTypes.filter(
        (workType) =>
          preloadedWorkTypes.findIndex(
            (preloadedWorkType) => preloadedWorkType.uuid === workType.uuid
          ) === -1
      );

      const workTypesToRemove = preloadedWorkTypes.filter(
        (preloadedWorkType) =>
          workTypes.findIndex((workType) => workType.uuid === preloadedWorkType.uuid) === -1
      );

      const workTypePermissionsToAdd = workTypePermissions.filter(
        (workTypePermission) =>
          preloadedWorkTypePermissions.findIndex(
            (preloadedWorkTypePermission) =>
              preloadedWorkTypePermission.uuid === workTypePermission.uuid
          ) === -1
      );

      const workTypePermissionsToRemove = preloadedWorkTypePermissions.filter(
        (preloadedWorkTypePermission) =>
          workTypePermissions.findIndex(
            (workTypePermission) => workTypePermission.uuid === preloadedWorkTypePermission.uuid
          ) === -1
      );

      await mapWorkTypesToWorker(workerUuid, {
        work_types_to_add: workTypesToAdd,
        work_types_to_remove: workTypesToRemove,
      });
      await mapWorkTypePermissionsToWorker(workerUuid, {
        work_type_permissions_to_add: workTypePermissionsToAdd,
        work_type_permissions_to_remove: workTypePermissionsToRemove,
      });
    } catch (error) {
      Console.log(error);
    }
  };

  const sendInvite = () => {
    const { email, firstname, lastname } = workerBasicDetails;
    if (!email) {
      toast.error(t('worker.details.basic.msgs.invite_email_invalid'));
      return;
    }
    if (!firstname) {
      toast.error(t('worker.details.basic.msgs.invite_firstname_invalid'));
      return;
    }
    if (!lastname) {
      toast.error(t('worker.details.basic.msgs.invite_lastname_invalid'));
      return;
    }
    setLoading(true);
    inviteWorker({ email, firstname, lastname })
      .then(async (data) => {
        if (data.status) {
          try {
            const profileId = await uploadImage(data.data.uuid);
            const requestBody = {};
            Object.entries(workerBasicDetails).forEach(([key, value]) => {
              if (value) requestBody[key] = value;
            });
            await updateWorker(data.data.uuid, {
              ...requestBody,
              worker_status: workerBasicDetails.work_status.status,
              profile_pic_attachment_id: profileId,
              fieldsToUpdate: JSON.stringify(fieldsToUpdate),
            });
            await updateWorkTypeAndWorkTypePermissions(data.data.uuid, workerBasicDetails);
            toast.success(t('worker.details.basic.msgs.invite_sent'));
            setLoading(false);
            navigate(`/workers/${data.data.uuid}`);
          } catch (error) {
            setLoading(false);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
    setBasicDetails(workerBasicDetails);
  };
  const onUpload = (files) => {
    const f = files[0];
    setFile(f);
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    reader.readAsDataURL(f);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (workerId) {
        const profileId = await uploadImage(workerId);
        await updateWorker(workerId, {
          ...workerBasicDetails,
          worker_status: workerBasicDetails.work_status.status,
          profile_pic_attachment_id: profileId,
          fieldsToUpdate: JSON.stringify(fieldsToUpdate),
        });

        await updateWorkTypeAndWorkTypePermissions(workerId, workerBasicDetails);
        handleSubmit(0);
      } else {
        const data = await addWorker(workerBasicDetails);

        if (data.is_success) {
          const workerIdFromResponse = data.data.uuid;
          const profileId = await uploadImage(workerIdFromResponse);
          if (profileId)
            await updateWorker(workerIdFromResponse, {
              profile_pic_attachment_id: profileId,
            });

          await updateWorkTypeAndWorkTypePermissions(workerIdFromResponse, workerBasicDetails);

          toast.success(t('worker.details.basic.msgs.worker_added'));
          navigate(`/workers/${workerIdFromResponse}`);
        }
      }
    } catch (error) {
      Console.log(error);
    }
    setBasicDetails(workerBasicDetails);
  };

  const fetchWorkerSkills = async () => {
    try {
      const { data } = await getWorkTypeSkills();
      if (data) setSkills(data);
    } catch (error) {
      Console.log(error);
    }
  };

  const fetchWorkTypePermissions = async () => {
    try {
      const { data } = await getWorkTypePermissions();
      if (data) setPermissions(data);
    } catch (error) {
      Console.log(error);
    }
  };

  useEffect(() => {
    fetchWorkerSkills();
    fetchWorkTypePermissions();
  }, []);

  useEffect(() => {
    if (basicDetails) {
      setWorkerBasicDetails(basicDetails);
    }
  }, [basicDetails]);

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (workerOldState.basicDetails) {
        setWorkerBasicDetails(workerOldState.basicDetails);
        setProfileImage(profileImageOldState);
      } else {
        setWorkerBasicDetails(form);
        setProfileImage(null);
      }
      setFile(null);
    }
  }, [resetForm.resetId]);

  useEffect(() => {
    if (workerId && workerBasicDetails && workerBasicDetails.profile_pic?.uuid) {
      getProfileImage();
    }
  }, [workerBasicDetails, workerId]);

  return (
    workerBasicDetails && (
      <div className="scrollContainer">
        {loading && <SideLoader />}
        <Form onSubmit={onSubmit}>
          <div className={`d-flex flex-column flex-lg-row ${styles.detailContainer}`}>
            <div className={`order-2 order-lg-0 ${styles.formContainer}`}>
              {Object.keys(form).map((key, index) => {
                const field = form[key];
                return (
                  <div key={index}>
                    {field.type === 'radio' && (
                      <div className={`d-lg-flex ${styles.selectMainRadio}`}>
                        <p className={`${styles.label}`}>{field.label}</p>
                        <div
                          className={`d-flex flex-wrap justify-content-between justify-content-lg-start ${styles.selectRadio}`}
                        >
                          {field.options.map((option, indx) => (
                            <div key={`radioOptions${option}${indx}`}>
                              <label
                                htmlFor={`radioOptions${option}${indx}`}
                                className="radioContainer"
                              >
                                {option === 'Male' || option === 'Female'
                                  ? t(`worker.details.basic.placeholders.${option}`)
                                  : option}
                                <Form.Check.Input
                                  checked={(workerBasicDetails[key] ?? field.options[0]) === option}
                                  type="radio"
                                  id={`radioOptions${option}${indx}`}
                                  key={option}
                                  name={key}
                                  disabled={isFieldDisabled('basic_details', key)}
                                  onChange={() => {
                                    updateFields(key);
                                    setWorkerBasicDetails({
                                      ...workerBasicDetails,
                                      [key]: option,
                                    });
                                  }}
                                />
                                <span className="radioIcon" />
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {field.type === 'date' && (
                      <div className={`d-lg-flex ${styles.inputDiv}`}>
                        <p className={`${styles.label}`}>{field.label}</p>
                        <div>
                          <FormDatePicker
                            className={`inputField ${styles.inputField}`}
                            value={workerBasicDetails[key]}
                            disabled={isFieldDisabled('basic_details', key)}
                            onChange={(val) => {
                              updateFields(key);
                              setWorkerBasicDetails({
                                ...workerBasicDetails,
                                [key]: val,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {field.type === 'text' && (
                      <div className={`d-lg-flex ${styles.inputDiv}`}>
                        <p className={`${styles.label}`}>{field.label}</p>
                        <div>
                          <Form.Control
                            className={`inputField ${styles.inputField}`}
                            required
                            disabled={isFieldDisabled('basic_details', key)}
                            value={workerBasicDetails[key] ?? ''}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              updateFields(key);
                              setWorkerBasicDetails({
                                ...workerBasicDetails,
                                [key]: e.target.value.replace(/[0-9]/g, ''),
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {userInfo?.data?.is_admin ? (
                <div className={`d-lg-flex ${styles.inputDiv}`}>
                  <p className={`${styles.label}`}>
                    {t('worker.details.basic.fields.send_invitation')}
                  </p>
                  <div className={`d-lg-flex ${styles.inviteContainer}`}>
                    <div>
                      <Form.Control
                        className={`inputField ${styles.inputField}`}
                        required
                        type="email"
                        disabled={isFieldDisabled('basic_details', 'email')}
                        value={workerBasicDetails.email ?? ''}
                        placeholder="user@email.com"
                        onChange={(e) => {
                          updateFields('email');
                          setWorkerBasicDetails({
                            ...workerBasicDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={`${styles.inputBtn}`}>
                      <Button
                        variant="warnBtn"
                        type="button"
                        onClick={() => sendInvite()}
                        disabled={
                          userInfo?.data?.is_admin
                            ? !!(workerId && !(workerBasicDetails.work_status.status === 'pending'))
                            : true
                        }
                      >
                        {workerId && workerBasicDetails.work_status.status === 'pending'
                          ? t('buttons.re_send')
                          : t('buttons.invite')}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={`order-1 order-lg-1 ${styles.statusContainer}`}>
              {userInfo?.data?.is_admin ? (
                <>
                  <p className={`${styles.label}`}>
                    {t('worker.details.basic.placeholders.active_status')}
                  </p>
                  <div>
                    <MultiSelectDropDown
                      placeholder={t('worker.details.basic.placeholders.active_status')}
                      options={convertOptions()}
                      selectedOptions={
                        workerBasicDetails.work_status
                          ? convertOptions().filter(
                              (ind) => ind.value === workerBasicDetails.work_status.status
                            )[0]
                          : []
                      }
                      handleChange={(val) => {
                        updateFields('work_status');
                        setWorkerBasicDetails({
                          ...workerBasicDetails,
                          work_status: { status: val.value },
                        });
                      }}
                      value="value"
                    />
                  </div>
                </>
              ) : null}

              <p className={`${styles.label}`}>
                {t('worker.details.basic.placeholders.skills')} :{' '}
              </p>
              <div>
                <MultiSelectDropDown
                  options={skills}
                  isDisabled={isFieldDisabled('basic_details', 'workTypes')}
                  selectedOptions={workerBasicDetails.workTypes ?? []}
                  handleChange={(values) => {
                    updateFields('workTypes');
                    setWorkerBasicDetails({ ...workerBasicDetails, workTypes: values });
                  }}
                  label="name"
                  value="uuid"
                  isMulti
                  isSearchable
                  isCreatable={false}
                />
              </div>

              <p className={`${styles.label}`}>
                {t('worker.details.basic.placeholders.work_type_permissions')} :{' '}
              </p>
              <div>
                <MultiSelectDropDown
                  options={permissions}
                  isDisabled={isFieldDisabled('basic_details', 'workTypePermissions')}
                  selectedOptions={workerBasicDetails?.workTypePermissions ?? []}
                  handleChange={(values) => {
                    updateFields('workTypePermissions');
                    setWorkerBasicDetails({
                      ...workerBasicDetails,
                      workTypePermissions: values,
                    });
                  }}
                  label="name"
                  value="uuid"
                  isMulti
                  isSearchable
                  isCreatable={false}
                />
              </div>
            </div>
            <div className={`order-0 order-lg-2 ${styles.uploadContainer}`}>
              <div className={`${styles.imgContainer}`}>
                {profileImage ? (
                  <Image
                    className={`${styles.uploadImg}`}
                    rounded
                    src={profileImage}
                    alt="Profile Image"
                  />
                ) : (
                  <p>{t('worker.details.basic.placeholders.image_preview')}</p>
                )}
              </div>
              <div>
                <DropZone
                  title={t('worker.details.basic.placeholders.dropzone_title')}
                  text={t('worker.details.basic.placeholders.dropzone_text')}
                  onUpload={onUpload}
                  accept={{ ...FILE_TYPES.IMAGE }}
                  multiple={false}
                />
              </div>
              <div className={`${styles.btnContainer}`}>
                <Button type="submit" variant="saveBtn">
                  {t('buttons.save_and_continue')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  );
}

BasicDetails.propTypes = {
  workerId: PropTypes.string,
  basicDetails: PropTypes.objectOf(Object),
  setBasicDetails: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  preloadedWorkTypes: PropTypes.arrayOf(Object),
  preloadedWorkTypePermissions: PropTypes.arrayOf(Object),
  workerOldState: PropTypes.objectOf(Object),
  resetForm: PropTypes.objectOf(Object),
};

BasicDetails.defaultProps = {
  workerId: null,
  basicDetails: null,
  handleSubmit() {},
  isFieldDisabled() {},
  preloadedWorkTypes: [],
  setBasicDetails() {},
  preloadedWorkTypePermissions: [],
  workerOldState: null,
  resetForm: null,
};

export default BasicDetails;
