import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Attachment from '../attachment';
import Button from '../button';
import DropZone from '../dropzone';
import styles from './AttachmentForm.module.scss';
import ConfirmationModal from '../confirmation-modal';
import { downloadFile } from '../../utils';
import FILE_TYPES from '../dropzone/constants';

function AttachmentForm({ files, setFiles, onSave, holderId: taskId, holder, saveButtonEnabled }) {
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const { t } = useTranslation('lang');

  const downloadAttachment = (index) => {
    const file = files[index];
    downloadFile(holder, taskId, file.uuid, file.name);
  };
  const onRemove = (index) => {
    setFileToDelete({ ...files[index], index });
    setShowModal(true);
  };
  const onUpload = (newFiles) => {
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);
  };
  const deleteFile = () => {
    setShowModal(false);
    const updatedFiles = [...files];
    updatedFiles.splice(fileToDelete.index, 1);
    setFiles(updatedFiles);
  };
  return (
    <div className="scrollContainer">
      <div className="h-100 d-lg-flex flex-lg-column">
        <div
          className={`d-flex flex-column flex-lg-row justify-content-lg-between ${styles.attachmentContainer}`}
        >
          <div className="order-1 order-lg-0">
            {files.map((attachment, index) => {
              const newKeyIndex = uuid();
              return (
                <Attachment
                  className={`${styles.attachmentContent}`}
                  key={newKeyIndex}
                  file={attachment}
                  onDelete={() => onRemove(index)}
                  onDownload={() => downloadAttachment(index)}
                />
              );
            })}
          </div>
          <div className="order-0 order-lg-1">
            <DropZone
              onUpload={onUpload}
              accept={{
                ...FILE_TYPES.IMAGE,
                ...FILE_TYPES.DOCUMENT,
                ...FILE_TYPES.EXCEL,
              }}
            />
          </div>
        </div>

        <div className="btnContainer">
          <Button variant="saveBtn" disabled={!saveButtonEnabled} onClick={() => onSave()}>
            {t('buttons.save_and_continue')}
          </Button>
        </div>
      </div>
      <ConfirmationModal
        title={t('task.msgs.delete_attachment')}
        message={
          <span>
            {t('task.msgs.delete_attachment_confirm')} <b>{fileToDelete?.name}</b>?
          </span>
        }
        show={showModal}
        onConfirm={() => deleteFile()}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
}

AttachmentForm.propTypes = {
  files: PropTypes.arrayOf(Object),
  setFiles: PropTypes.func,
  onSave: PropTypes.func,
  holderId: PropTypes.string,
  holder: PropTypes.string,
  saveButtonEnabled: PropTypes.bool,
};

AttachmentForm.defaultProps = {
  files: [],
  setFiles() {},
  onSave() {},
  holderId: null,
  holder: null,
  saveButtonEnabled: true,
};

export default AttachmentForm;
