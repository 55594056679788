import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import InvoiceItemsForm from '../../../components/invoice-items-form';
import TimeSheet from '../../../components/time-sheet/TimeSheet';
import {
  getWorkerInvoiceData,
  markInvoiced,
  addProduct,
  updateProduct,
  printInvoice,
} from '../../../services/invoice';
import { getDate } from '../../../lib/dateLib';
import Button from '../../../components/button';
import { downloadPdf } from '../../../utils';
import SideLoader from '../../../components/side-loader';
import PageLoader from '../../../components/page-loader';
import styles from './Invoice.module.scss';

function Invoice() {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { companyId, projectId, taskId } = useParams();
  const [additionalItems, setAdditionalItems] = useState([]);
  const [saveBtnState, setSaveBtnState] = useState(true);
  const [formValidated, setFormValidate] = useState(false);

  const getCompanyAddress = (data) => {
    let address = '';
    address += `${data?.project?.company?.address ?? ''} 
                ${data?.project?.company?.city ?? ''}
                ${data?.project?.company?.zip ?? ''}`;

    return address;
  };

  const getCompany = (data) => data?.task?.project?.company;

  const onInvoiceMark = async () => {
    setLoading(true);
    try {
      await markInvoiced(taskId);
      toast.success('Invoice marked successfully');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getInvoiceData = async () => {
    setLoading(true);
    try {
      const invoiceDataResponse = await getWorkerInvoiceData(companyId, projectId, taskId);
      setInvoiceData(invoiceDataResponse?.data?.items);
      setAdditionalItems(
        invoiceDataResponse?.data?.items?.additional_items?.map((itm) => ({
          ...itm,
          preloaded: true,
          updated: false,
        })) ?? []
      );
      setPageLoading(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
      navigate('/invoice');
    }
  };

  const onPrintInvoice = () => {
    setLoading(true);
    printInvoice(invoiceData)
      .then((res) => {
        downloadPdf(res, 'invoice.pdf');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSave = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity()) {
      setFormValidate(true);
      setLoading(true);
      try {
        const itemsToUpdate = additionalItems.filter((item) => item.updated);
        await Promise.all(
          itemsToUpdate.map((itm) => {
            const data = {
              name: itm.name,
              price: itm.price,
              quantity: itm.quantity,
            };
            if (itm.preloaded) {
              return updateProduct(taskId, itm.uuid, data);
            }
            return addProduct(taskId, data);
          })
        );
        setSaveBtnState(true);
        getInvoiceData();
        setLoading(false);
        toast.success(t('invoice_items.messages.saved'));
      } catch (error) {
        setLoading(false);
      }
    }
    setFormValidate(false);
  };

  useEffect(() => {
    setPageLoading(true);
    getInvoiceData();
  }, []);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div className={`${styles.invoiceMainContainer}`}>
      <div>
        <Helmet>
          <title>Hiukka - {t('page_titles.invoicing')}</title>
        </Helmet>
        {loading && <SideLoader />}
        <p className="pageTitle">{t('page_titles.invoicing')}</p>
      </div>
      {invoiceData && (
        <div className={`d-lg-flex ${styles.invoiceContainer}`}>
          <div className={`${styles.firstColumn}`}>
            <div className={`d-flex flex-wrap ${styles.titleContainer}`}>
              <div>
                <p>{t('invoice.panel.invoice_to')}</p>
                <p> {getCompanyAddress(invoiceData?.task)} </p>
              </div>
              <div>
                <p>{t('invoice.panel.vat_id')}</p>
                <p>{getCompany(invoiceData)?.vat}</p>
              </div>
              <div>
                <p>{t('invoice.panel.client_reference')}</p>
                <p>{getCompany(invoiceData)?.contact_person}</p>
              </div>
              <div>
                <p>{t('invoice.panel.task')}</p>
                <p>{invoiceData?.task?.name}</p>
              </div>
              <div>
                <p>{t('invoice.panel.company')}</p>
                <p>{getCompany(invoiceData)?.name}</p>
              </div>
              <div>
                <p>{t('invoice.panel.project')}</p>
                <p>{invoiceData?.task?.project?.name}</p>
              </div>
            </div>
            <div className={`d-flex d-lg-block ${styles.timeSheetMainContainer}`}>
              <TimeSheet enteries={invoiceData?.timesheet} />
            </div>
          </div>
          <div className={`${styles.secondColumn}`}>
            <div
              className={`d-flex flex-wrap flex-lg-nowrap justify-content-lg-between ${styles.titleContainer}`}
            >
              <div>
                <p>{t('invoice.panel.total_hours')}</p>
                <p>{invoiceData?.total_working_hours}</p>
              </div>
              <div>
                <p>{t('invoice.panel.date_started')}</p>
                <p>{getDate(invoiceData?.task?.contract_period_start)}</p>
              </div>
              <div>
                <p>{t('invoice.panel.date_ended')}</p>
                <p>{getDate(invoiceData?.task?.contract_period_end)}</p>
              </div>
            </div>
            <div className={`${styles.invoiceItemMainContainer}`}>
              <div>
                <InvoiceItemsForm
                  billing={invoiceData?.billing}
                  serviceItems={invoiceData?.service_items}
                  additionalItems={additionalItems}
                  setAdditionalItems={setAdditionalItems}
                  onSave={onSave}
                  isSaveBtnDisabled={saveBtnState}
                  setSaveBtnState={setSaveBtnState}
                  formValidated={formValidated}
                />
              </div>
              <div className={`d-lg-flex justify-content-end ${styles.btnContainer}`}>
                <div>
                  <Button onClick={onPrintInvoice} variant="warnBtn">
                    {t('invoice.buttons.print_timesheet')}
                  </Button>
                </div>
                <div>
                  <Button variant="saveBtn" onClick={onInvoiceMark}>
                    {t('invoice.buttons.marked_as_invoiced')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Invoice;
