import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Button from '../../../components/button';
import { resetPassword, updateInvitePassword, verifyLink } from '../../../services/auth';
import styles from './PasswordReset.module.scss';
import { changeLanguageOnLoginPage } from '../../../lib/dateLib';

function PasswordReset() {
  const navigate = useNavigate();
  const { t } = useTranslation('lang');
  const { workerInviteUuid } = useParams();
  const [searchParams] = useSearchParams();
  const resetId = searchParams.get('reset_id');

  const [password, setPassword] = useState({
    password: '',
    confirm_password: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password.password !== password.confirm_password) {
      return toast.error(t('login.password_mismatch'));
    }

    if (workerInviteUuid) {
      return updateInvitePassword({
        inviteId: workerInviteUuid,
        password: password.password,
        confirmPassword: password.confirm_password,
      }).then(() => {
        toast.success(t('login.password_updated'));
        navigate('/auth/login');
      });
    }

    return resetPassword(password).then(() => {
      localStorage.removeItem('token');
      setPassword({ password: '', confirm_password: '' });
      toast.success(t('login.password_changed'));
      navigate('/auth/login');
    });
  };

  const validateResetPasswordLink = async (newResetId) => {
    try {
      const res = await verifyLink(newResetId);
      if (res.token) localStorage.setItem('token', res.token);
    } catch (error) {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    if (resetId) validateResetPasswordLink(resetId);
  }, [resetId]);

  useEffect(() => {
    if (!workerInviteUuid && !resetId) navigate('/auth/login');
  }, [workerInviteUuid, resetId]);

  return (
    <div className={styles.formContainer}>
      <Helmet>
        <title>Hiukka - {t('page_titles.reset_password')}</title>{' '}
      </Helmet>
      <Form onSubmit={onSubmit}>
        <Form.Group className={styles.inputContainer}>
          <Form.Control
            className={styles.inputField}
            required
            type="password"
            placeholder={t('login.password')}
            value={password.password}
            onChange={(e) => setPassword({ ...password, password: e.target.value })}
          />
          <Form.Text />
        </Form.Group>
        <Form.Group className={styles.inputContainer}>
          <Form.Control
            className={styles.inputField}
            required
            type="password"
            placeholder={t('login.confirm_password')}
            value={password.confirm_password}
            onChange={(e) => setPassword({ ...password, confirm_password: e.target.value })}
          />
          <Form.Text />
        </Form.Group>
        <Button type="submit" className={styles.loginBtn}>
          {workerInviteUuid ? t('buttons.submit') : t('login.reset_pass')}
        </Button>
      </Form>
      <div className={styles.forgotPasswordContainer}>
        <Link className={styles.forgotPasswordLink} to="/auth/login">
          {t('login.go_to_login')}
        </Link>
      </div>
      <div className={`d-flex align-items-center justify-content-center ${styles.flagContainer}`}>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('en')}
          >
            <img className={styles.flagImg} src="/images/flag1.png" alt="flag" />
          </button>
        </div>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('fi')}
          >
            <img className={styles.flagImg} src="/images/flag2.png" alt="flag" />
          </button>
        </div>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('ru')}
          >
            <img className={styles.flagImg} src="/images/flag3.png" alt="flag" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
