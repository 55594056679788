/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './Dropzone.module.scss';

function DropZone({ onUpload, className, title, text, accept, multiple }) {
  const { t } = useTranslation('lang');
  const onDrop = useCallback(
    (acceptedFiles) => {
      onUpload(acceptedFiles);
    },
    [onUpload]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <div {...getRootProps()} className={`${styles.dropZoneContainer} ${className}`} role="button">
      <input {...getInputProps()} />
      <p className={`${styles.dropZoneText}`}>{title ?? t('common_messages.add_file')}</p>
      <div className={`${styles.cloudImgContainer}`}>
        <img
          alt="cloud"
          className="img-fluid"
          src="https://cdn-icons-png.flaticon.com/512/568/568717.png"
        />
      </div>
      <div className="d-none d-lg-block">
        <p>{text ?? t('common_messages.drag_file')}</p>
        <p>Or</p>
      </div>
      <div className={`${styles.chooseFile}`}>{t('common_messages.choose_file')}</div>
    </div>
  );
}

DropZone.propTypes = {
  onUpload: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  accept: PropTypes.instanceOf(Object),
  multiple: PropTypes.bool,
};

DropZone.defaultProps = {
  onUpload() {},
  className: null,
  title: null,
  text: null,
  accept: {},
  multiple: true,
};

export default DropZone;
