import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import styles from './WorkerTaskAttachments.module.scss';
import CameraImageUpload from '../camera-image-upload';
import CameraSvg from '../svgs/camera';
import FileEarmarkPlusSvg from '../svgs/file-earmark-plus';
import TrashSvg from '../svgs/trash-svg';

function WorkerTaskAttachments({ files, setFiles, onDelete }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('lang');
  const onFilesSelect = (e) => {
    setFiles([...files, ...[...e.target.files].map((f) => ({ uuid: uuid(), file: f }))]);
  };

  return (
    <div className={`${styles.UploadMainContainer}`}>
      <div className={`${styles.UploadContainer}`}>
        <p>{t('worker_home.worker_task_attachment.send_file_new_image')}</p>
        <div>
          {isOpen ? (
            <CameraImageUpload
              openCamera={isOpen}
              setOpenCamera={setIsOpen}
              onSave={(file) => setFiles([...files, file])}
            />
          ) : (
            <div className={`d-flex justify-content-between ${styles.flexDiv}`}>
              <div>
                <label htmlFor="fileInput">
                  <div>
                    <FileEarmarkPlusSvg />
                  </div>
                  <div>
                    <input
                      className="w-100"
                      style={{ visibility: 'hidden' }}
                      id="fileInput"
                      type="file"
                      multiple
                      onChange={onFilesSelect}
                    />
                  </div>
                </label>
              </div>
              <div role="button" onKeyDown={() => {}} tabIndex={0} onClick={() => setIsOpen(true)}>
                <CameraSvg />
              </div>
            </div>
          )}
        </div>

        <div>
          {files.length !== 0 &&
            files.map((f) => (
              <div
                className={`d-flex align-items-center justify-content-between ${styles.UploadedFileContainer}`}
                key={f.uuid}
              >
                <div>
                  <p>{f.file.name}</p>
                </div>
                <div
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex={0}
                  onClick={() => onDelete(f.uuid)}
                >
                  <TrashSvg />
                </div>
              </div>
            ))}
        </div>
      </div>
      <p className={`${styles.contentDec}`}>{t('worker_home.worker_task_attachment.message')}</p>
    </div>
  );
}

WorkerTaskAttachments.propTypes = {
  files: PropTypes.arrayOf(Object),
  setFiles: PropTypes.func,
  onDelete: PropTypes.func,
};

WorkerTaskAttachments.defaultProps = {
  files: [],
  setFiles() {},
  onDelete() {},
};

export default WorkerTaskAttachments;
