import React from 'react';
import styles from './Logo.module.scss';

function Logo() {
  return (
    <div className={styles.logoContainer}>
      <img src="/images/logo.png" className={styles.logoImg} alt="logo" />
    </div>
  );
}

export default Logo;
