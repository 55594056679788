import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { deleteAttachment, getAttachments, uploadAttachment } from '../../services/attachments';
import AttachmentForm from '../attachment-form';
import styles from './Attachments.module.scss';
import { Console } from '../../utils';

function Attachments({ workerId, handleSubmit, resetForm }) {
  const { t } = useTranslation('lang');
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [oldFilesState, setOldFilesState] = useState([]);
  const [oldAttachmentsState, setOldAttachmentsState] = useState([]);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

  const fetchAttachments = () => {
    getAttachments('workers', workerId).then(({ is_success: isSuccess, data }) => {
      if (isSuccess) {
        setFiles(data.items);
        setAttachments(data.items);
        setOldFilesState(data.items);
        setOldAttachmentsState(data.items);
      }
    });
  };

  const onSubmit = async () => {
    const existingFiles = files.filter((file) => !!file.uuid).map((f) => f.uuid);
    const filesToDelete = attachments
      .filter((attachment) => !existingFiles.includes(attachment.uuid))
      .map((file) => file.uuid);
    const filesToUpload = files.filter((file) => !file.uuid);
    try {
      await Promise.all([
        ...filesToUpload.map((file) => {
          const formData = new FormData();
          formData.append('', file);
          return uploadAttachment('workers', workerId, formData);
        }),
      ]);
      await Promise.all([
        ...filesToDelete.map((uuid) => deleteAttachment('workers', workerId, uuid)),
      ]);
      fetchAttachments();
      handleSubmit(7);
      setSaveButtonEnabled(false);
    } catch (error) {
      Console.error(error);
    }
  };

  //  This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      setFiles(oldFilesState);
      setAttachments(oldAttachmentsState);
    }
  }, [resetForm.resetId]);

  useEffect(() => {
    if (workerId) fetchAttachments();
  }, [workerId]);

  return (
    <div className={`${styles.attachmentContainer}`}>
      <div className={`${styles.attachmentContent}`}>
        <p>{t('worker.details.attachment.msg')}</p>
        <p>{t('worker.details.attachment.msg1')}</p>
      </div>
      <AttachmentForm
        files={files}
        setFiles={(newFiles) => {
          setFiles(newFiles);
          setSaveButtonEnabled(true);
        }}
        holderId={workerId}
        onSave={() => onSubmit()}
        holder="workers"
        saveButtonEnabled={saveButtonEnabled}
      />
    </div>
  );
}

Attachments.propTypes = {
  workerId: PropTypes.string,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

Attachments.defaultProps = {
  workerId: null,
  handleSubmit() {},
  resetForm: null,
};

export default Attachments;
