import React from 'react';
import PropTypes from 'prop-types';
import Tag from '.';
import styles from './Tag.module.scss';

function SpecialTag(props) {
  const { children, className } = props;
  return <Tag className={`${className} ${styles.specialTag}`}>{children}</Tag>;
}

SpecialTag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SpecialTag.defaultProps = {
  className: null,
};

export default SpecialTag;
