import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import styles from './BasicSettings.module.scss';
import AddUser from '../../../../components/add-user';
import MultiSelectDropDown from '../../../../components/multi-select-dropdown';
import { dateFormats, timeFormats, languages } from '../../../../data/index';
import User from '../../../../components/user';
import Button from '../../../../components/button';
import { getAdmins, addAdmin, deleteAdmin } from '../../../../services/admin';
import { getWorkers, updateWorker } from '../../../../services/workers';
import { getUserProfile } from '../../../../redux/features/users/userSlice';
import SideLoader from '../../../../components/side-loader';
import { getImageSrcUrl } from '../../../../utils';
import ConfirmationModal from '../../../../components/confirmation-modal';

function BasicSettings({ setActiveTab, resetForm, setResetForm }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { data } = userInfo;
  const [currentDateFormat, setCurrentDateFormat] = useState(
    data?.date_format ? data.date_format : ''
  );
  const [currentTimeFormat, setCurrentTimeFormat] = useState(
    data?.time_format ? data.time_format : ''
  );
  const [currentLanguage, setCurrentLanguage] = useState(
    data?.preferred_language ? data.preferred_language : ''
  );
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [adminToRemove, setAdminToRemove] = useState(null);
  const [formOldState, setFormOldState] = useState({});

  const fetchAdmins = () => {
    setLoading(true);
    getAdmins()
      .then((response) => {
        setLoading(false);
        setAdmins(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchUsers = () => {
    setLoading(true);
    getWorkers()
      .then((response) => {
        setLoading(false);
        setUsers(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addNewAdmin = (newData) => {
    setLoading(true);
    addAdmin(newData)
      .then(() => {
        setLoading(false);
        toast.success(t('settings.basic.msgs.admin_added'));
        fetchAdmins();
        fetchUsers();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleAdminDelete = () => {
    if (adminToRemove) {
      setLoading(true);
      deleteAdmin({ users: [adminToRemove] })
        .then(() => {
          setLoading(false);
          setAdminToRemove(null);
          toast.success(t('settings.basic.msgs.admin_deleted'));
          fetchAdmins();
          fetchUsers();
        })
        .catch(() => {
          setAdminToRemove(null);
          setLoading(false);
        });
    }
  };

  const updateBasicSettings = () => {
    if (currentDateFormat === '') {
      toast.error(t('settings.basic.msgs.valid_date'));
    } else if (currentTimeFormat === '') {
      toast.error(t('settings.basic.msgs.valid_time'));
    } else if (currentLanguage === '') {
      toast.error(t('settings.basic.msgs.valid_language'));
    } else {
      updateWorker(data.uuid, {
        preferred_language: currentLanguage,
        date_format: currentDateFormat,
        time_format: currentTimeFormat,
      }).then((res) => {
        if (res.status) {
          dispatch(getUserProfile());
          toast.success(t('settings.msgs.saved'));
          setActiveTab(1);
          setResetForm({ reset: false, resetId: null });
        }
      });
    }
  };

  const handleUser = (user) => {
    addNewAdmin({ users: [user.uuid] });
  };

  const handleDateFormat = (val) => {
    setCurrentDateFormat(val.uuid);
  };

  const handleTimeFormat = (val) => {
    setCurrentTimeFormat(val.uuid);
  };

  const handleLanguageChange = (val) => {
    setCurrentLanguage(val.uuid);
  };

  useEffect(() => {
    if (data.is_admin) fetchAdmins();
    if (data.is_admin) fetchUsers();
  }, [data]);

  useEffect(() => {
    if (data) {
      const {
        time_format: timeFormat,
        date_format: dateFormat,
        preferred_language: preferredLanguage,
      } = data;
      setCurrentDateFormat(dateFormat ?? '');
      setCurrentTimeFormat(timeFormat ?? '');
      setCurrentLanguage(preferredLanguage ?? '');
      setFormOldState({ timeFormat, dateFormat, preferredLanguage });
    }
  }, [data]);

  // This useEffect is to reset form values on tab change without save
  useEffect(() => {
    if (resetForm.reset) {
      const { timeFormat, dateFormat, preferredLanguage } = formOldState;
      setCurrentDateFormat(dateFormat);
      setCurrentTimeFormat(timeFormat);
      setCurrentLanguage(preferredLanguage);
    }
  }, [resetForm.resetId]);

  return (
    <div className="scrollContainer d-lg-flex flex-wrap">
      {loading && <SideLoader />}
      <ConfirmationModal
        show={show}
        onConfirm={() => {
          handleAdminDelete();
          setShow(false);
        }}
        handleClose={() => {
          setShow(false);
          setAdminToRemove(null);
        }}
        title={t('settings.basic.msgs.warning')}
        message={t('settings.basic.msgs.admin_delete')}
      />
      <div className={styles.dropdownColumn}>
        <div className={`${styles.dropdownContainer} d-lg-flex justify-content-between`}>
          <p className="dropdownLabel">{t('settings.basic.date_format')}:</p>
          <div className={styles.dropdownDiv}>
            <MultiSelectDropDown
              selectedOptions={dateFormats.filter((obj) => obj.uuid === currentDateFormat)}
              isMulti={false}
              options={dateFormats}
              placeholder={t('settings.basic.msgs.select_format')}
              handleChange={handleDateFormat}
            />
          </div>
        </div>
        <div className={`${styles.dropdownContainer} d-lg-flex justify-content-between`}>
          <p className="dropdownLabel">{t('settings.basic.time_format')}:</p>
          <div className={styles.dropdownDiv}>
            <MultiSelectDropDown
              selectedOptions={timeFormats.filter((obj) => obj.uuid === currentTimeFormat)}
              isMulti={false}
              options={timeFormats}
              placeholder={t('settings.basic.msgs.select_format')}
              handleChange={handleTimeFormat}
            />
          </div>
        </div>
        <div className={`${styles.dropdownContainer} d-lg-flex justify-content-between`}>
          <p className="dropdownLabel">{t('settings.basic.language')}:</p>
          <div className={styles.dropdownDiv}>
            <MultiSelectDropDown
              selectedOptions={languages.filter((obj) => obj.uuid === currentLanguage)}
              isMulti={false}
              options={languages}
              placeholder={t('settings.basic.msgs.select_language')}
              handleChange={handleLanguageChange}
            />
          </div>
        </div>
      </div>
      {data?.is_admin && (
        <div className={`${styles.userColumn} d-lg-flex `}>
          <p className={`${styles.dropdownLabel} dropdownLabel `}>
            {t('settings.basic.admin_users')}:
          </p>
          <div className={`${styles.userMainContainer} d-lg-flex flex-lg-wrap`}>
            {admins.map((admin) => {
              const newKeyIndex = uuid();
              return (
                <div className={`${styles.userContainer}`} key={newKeyIndex}>
                  <User
                    id={admin.uuid}
                    area={admin.current_nationality ? admin.current_nationality : ''}
                    profilePic={getImageSrcUrl(admin)}
                    tags={admin.workTypes}
                    name={`${admin.firstname} ${admin.lastname}`}
                    status="admin"
                    alignImage="top"
                    deleteBtn
                    onDelete={(id) => {
                      setAdminToRemove(id);
                      setShow(true);
                    }}
                    onEdit={(id) => navigate(`/workers/${id}`)}
                  />
                </div>
              );
            })}
            <div className={`${styles.addUserContainer}`}>
              <AddUser
                users={users}
                setUser={handleUser}
                placeHolder={t('settings.msgs.add_user')}
              />
            </div>
          </div>
        </div>
      )}
      <div className={`btnContainer ${!data.is_admin ? 'mt-4' : ''}`}>
        <Button variant="saveBtn" onClick={updateBasicSettings}>
          {t('buttons.save_and_continue')}
        </Button>
      </div>
    </div>
  );
}

BasicSettings.propTypes = {
  resetForm: PropTypes.instanceOf(Object),
  setResetForm: PropTypes.func,
  setActiveTab: PropTypes.func,
};

BasicSettings.defaultProps = {
  resetForm: {
    reset: false,
    resetId: null,
  },
  setResetForm() {},
  setActiveTab() {},
};

export default BasicSettings;
