import { downloadAttachment } from '../services/attachments';
import { defaultUserProfilePicture } from '../data';

const invalidCharsForNumberInput = ['e', 'E', '-', '+', '/', '*'];

export const downloadFile = (holder, holderId, uuid, name) => {
  downloadAttachment(holder, holderId, uuid).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  });
};

export const downloadPdf = (response) => {
  const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
  window.open(url);
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const getImageSrcUrl = (obj) => obj?.profile_pic?.src ?? defaultUserProfilePicture;

export const preventInvalidChars = (event) => {
  if (invalidCharsForNumberInput.includes(event.key)) {
    event.preventDefault();
  }
};

export const preventDecimalAndInvalidChars = (event) => {
  if ([...invalidCharsForNumberInput, '.'].includes(event.key)) {
    event.preventDefault();
  }
};

export const Console = {
  log(value) {
    if (process.env.REACT_APP_ENV === 'dev') {
      // eslint-disable-next-line no-console
      console.log(value);
    }
  },
};
