/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styles from './PassportDetails.module.scss';
import { deleteAttachment, getAttachments, uploadAttachment } from '../../services/attachments';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import { downloadFile, Console } from '../../utils';
import Attachment from '../attachment';
import Button from '../button';
import ConfirmationModal from '../confirmation-modal';
import DropZone from '../dropzone';
import FILE_TYPES from '../dropzone/constants';
import FormDatePicker from '../form-date-picker';
import formToInitialValues from './helper';
import useConstants from './useConstants';

function PassportDetails({
  workerId,
  passportDetails: newPassportDetails,
  handleSubmit,
  isFieldDisabled,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const { PASSPORT_DETAILS: form } = useConstants();
  const [passportDetails, setPassportDetails] = useState(formToInitialValues(form));
  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const fetchPassportAttachments = () => {
    getAttachments('passports', newPassportDetails.uuid).then(({ is_success: isSuccess, data }) => {
      if (isSuccess) {
        setAttachments(data.items);
        setFiles(data.items);
      }
    });
  };
  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.passport_details) {
        fieldsToUpdate.passport_details = [];
      }
      if (!fieldsToUpdate.passport_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          passport_details: [...fieldsToUpdate.passport_details, key],
        }));
      }
    }
  };
  useEffect(() => {
    if (newPassportDetails) {
      setPassportDetails(newPassportDetails);
      fetchPassportAttachments();
    }
  }, [newPassportDetails, workerId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let res = null;
    const requestBody = {
      passport_details: passportDetails,
      fieldsToUpdate: JSON.stringify(fieldsToUpdate),
    };
    if (newPassportDetails) {
      res = updateWorkerDetails(workerId, requestBody);
    } else {
      res = addWorkerDetails(workerId, requestBody);
    }
    res
      .then(async ({ status, data }) => {
        if (status) {
          const existingFiles = files.filter((file) => !!file.uuid).map((f) => f.uuid);
          const filesToDelete = attachments
            .filter((attachment) => !existingFiles.includes(attachment.uuid))
            .map((file) => file.uuid);
          const filesToUpload = files.filter((file) => !file.uuid);
          try {
            await Promise.all([
              ...filesToUpload.map((file) => {
                const formData = new FormData();
                formData.append('', file);
                return uploadAttachment('passports', data.passport_details.uuid, formData);
              }),
            ]);
            await Promise.all([
              ...filesToDelete.map((fileUuid) =>
                deleteAttachment('passports', data.passport_details.uuid, fileUuid)
              ),
            ]);
            fetchPassportAttachments();
          } catch (error) {
            Console.log(error);
          }
          handleSubmit(2);
        }
      })
      .catch((error) => {
        Console.log(error);
      });
  };
  const onUpload = (newFiles) => {
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);
  };
  const onRemove = (index) => {
    setFileToDelete({ ...files[index], index });
    setShowModal(true);
  };
  const deleteFile = () => {
    setShowModal(false);
    const updatedFiles = [...files];
    updatedFiles.splice(fileToDelete.index, 1);
    setFiles(updatedFiles);
  };
  const downloadAttachment = (index) => {
    const file = files[index];
    downloadFile('passports', passportDetails.uuid, file.uuid, file.name);
  };

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (newPassportDetails) {
        setPassportDetails(newPassportDetails);
        fetchPassportAttachments();
      } else {
        setPassportDetails(formToInitialValues(form));
        setFileToDelete(null);
        setAttachments([]);
        setFiles([]);
      }
      setFileToDelete(null);
    }
  }, [resetForm.resetId]);

  return (
    <div>
      <Form className="scrollContainer" onSubmit={onSubmit}>
        <div className={`d-flex flex-column h-100 ${styles.passportContainer}`}>
          <div className="d-lg-flex justify-content-lg-between">
            <div className={`${styles.firstColumn}`}>
              {Object.keys(form).map((key, index) => {
                const field = form[key];
                return (
                  <div key={index}>
                    {field.type === 'radio' && (
                      <div className={`d-lg-flex ${styles.flexContainer}`}>
                        <p className={`${styles.label}`}>{field.label}</p>
                        <div
                          className={`d-lg-flex justify-content-lg-between ${styles.flexDivContainer}`}
                        >
                          {field.options.map((option, indx) => (
                            <div
                              className={`${styles.flexDiv}`}
                              key={`passport_radio_options${option}${indx}`}
                            >
                              <label
                                htmlFor={`passport_radio_options_${option
                                  .split(' ')
                                  .join('_')}${indx}`}
                                className="radioContainer"
                              >
                                {t(`worker.details.passport.placeholders.${option}`)}
                                <Form.Check.Input
                                  checked={passportDetails[key] === option}
                                  type="radio"
                                  key={option}
                                  id={`passport_radio_options_${option
                                    .split(' ')
                                    .join('_')}${indx}`}
                                  disabled={isFieldDisabled('passport_details', key)}
                                  name={key}
                                  onChange={() => {
                                    updateFields(key);
                                    setPassportDetails({
                                      ...passportDetails,
                                      [key]: option,
                                    });
                                  }}
                                />
                                <span className="radioIcon" />
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {field.type === 'date' && (
                      <div className={`d-lg-flex ${styles.flexContainer}`}>
                        <p className={`${styles.label}`}>{field.label}</p>
                        <div>
                          <FormDatePicker
                            className={`inputField ${styles.inputField}`}
                            value={passportDetails[key]}
                            disabled={isFieldDisabled('passport_details', key)}
                            onChange={(val) => {
                              updateFields(key);
                              setPassportDetails({
                                ...passportDetails,
                                [key]: val,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {field.type === 'text' && (
                      <div className={`d-lg-flex ${styles.flexContainer}`}>
                        <p className={`${styles.label}`}>{field.label}</p>
                        <div>
                          <Form.Control
                            className={`inputField ${styles.inputField}`}
                            required
                            disabled={isFieldDisabled('passport_details', key)}
                            value={passportDetails[key]}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              updateFields(key);
                              setPassportDetails({
                                ...passportDetails,
                                [key]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={`${styles.secondColumn}`}>
              <DropZone
                onUpload={onUpload}
                accept={{ ...FILE_TYPES.DOCUMENT, ...FILE_TYPES.IMAGE }}
              />
              <div className={`${styles.attachmentContainer}`}>
                {files.map((file, idx) => (
                  <Attachment
                    key={idx}
                    file={file}
                    onDelete={() => onRemove(idx)}
                    onDownload={() => downloadAttachment(idx)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="btnContainer">
            <Button type="submit" variant="saveBtn">
              {t('buttons.save_and_continue')}
            </Button>
          </div>
        </div>
      </Form>
      <ConfirmationModal
        title={t('worker.details.passport.msgs.delete_attachment')}
        message={
          <span>
            {t('worker.details.passport.msgs.confirm_msg')} <b>{fileToDelete?.name}</b>?
          </span>
        }
        show={showModal}
        onConfirm={() => deleteFile()}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
}

PassportDetails.propTypes = {
  workerId: PropTypes.string,
  passportDetails: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

PassportDetails.defaultProps = {
  workerId: null,
  passportDetails: null,
  handleSubmit() {},
  isFieldDisabled() {},
  resetForm: null,
};

export default PassportDetails;
