/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import Button from '../button';
import styles from './FamilyDetails.module.scss';
import useConstants from './useConstants';
import { Console } from '../../utils';

const GUARDIAN_DETAILS = {
  first_name: '',
  last_name: '',
  email: '',
  arriving_to_finland_with_you: false,
  living_in_finland_with_you: false,
};

function FamilyDetails({
  workerId,
  familyDetails: newFamilyDetails,
  handleSubmit,
  isFieldDisabled,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const { ARRIVAL_STATUS, BINARY_STATUS } = useConstants();
  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);
  const [familyDetails, setFamilyDetails] = useState({
    arrival_type: 'alone',
    guardians: [],
  });

  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.family_details) {
        fieldsToUpdate.family_details = [];
      }
      if (!fieldsToUpdate.family_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          family_details: [...fieldsToUpdate.family_details, key],
        }));
      }
    }
  };

  const handleChange = (index, field, value) => {
    updateFields(field);
    const guardians = [...familyDetails.guardians];
    guardians[index][field] = value;
    setFamilyDetails({ ...familyDetails, guardians });
  };

  const onArrivalChange = (val) => {
    const optionIndex = ARRIVAL_STATUS.indexOf(val);
    let guardians = [...familyDetails.guardians];
    if (optionIndex === 0) guardians = [];
    if (optionIndex === 1) {
      if (guardians.length === 2) guardians.pop();
      else guardians = [{ ...GUARDIAN_DETAILS }];
    }
    if (optionIndex === 2) {
      if (guardians.length) guardians.push({ ...GUARDIAN_DETAILS });
      else guardians = [{ ...GUARDIAN_DETAILS }, { ...GUARDIAN_DETAILS }];
    }
    setFamilyDetails({ ...familyDetails, arrival_type: val, guardians });
    updateFields('arrival_type');
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { arrival_type: arrivalType, guardians } = familyDetails;
    if (arrivalType === ARRIVAL_STATUS[2] && guardians[0].email === guardians[1].email) {
      toast.error(t('worker.details.family.msgs.unique_email'));
      return;
    }
    let res = null;
    const requestBody = {
      family_details: familyDetails,
      fieldsToUpdate: JSON.stringify(fieldsToUpdate),
    };
    if (newFamilyDetails) {
      res = updateWorkerDetails(workerId, requestBody);
    } else {
      res = addWorkerDetails(workerId, requestBody);
    }
    res
      .then(({ status }) => {
        if (status) handleSubmit(1);
      })
      .catch((error) => {
        Console.log(error);
      });
  };
  useEffect(() => {
    if (newFamilyDetails) setFamilyDetails(newFamilyDetails);
  }, [newFamilyDetails]);

  useEffect(() => {
    updateFields('arrival_type');
    updateFields('arriving_to_finland_with_you');
    updateFields('living_in_finland_with_you');
  }, []);

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (newFamilyDetails) {
        setFamilyDetails(newFamilyDetails);
      } else {
        setFamilyDetails({
          arrival_type: 'alone',
          guardians: [],
        });
      }
    }
  }, [resetForm.resetId]);

  return (
    <div>
      <Form className="d-flex flex-column scrollContainer" onSubmit={onSubmit}>
        <div className={` ${styles.FamilyContainer}`}>
          <p className={`${styles.label}`}>{t('worker.details.family.heading')}</p>
          <div className={`${styles.formContainer}`}>
            <div className={`d-lg-flex ${styles.selectMainRadio}`}>
              <p className={`${styles.label}`}>{t('worker.details.family.fields.arrival')}</p>
              <div className={`${styles.flexColumn}`}>
                <p className={`${styles.label}`}>{t('worker.details.family.fields.arrival_msg')}</p>
                <div
                  className={`d-flex flex-wrap justify-content-between justify-content-lg-start  ${styles.selectRadio}`}
                >
                  {ARRIVAL_STATUS.map((option, index) => (
                    <div key={index}>
                      <label
                        htmlFor={`${option}${index}`}
                        className="radioContainer text-capitalize"
                      >
                        {t(`worker.details.family.placeholders.${option}`)}
                        <Form.Check.Input
                          checked={familyDetails.arrival_type === option}
                          name="arrival_type"
                          type="radio"
                          id={`${option}${index}`}
                          className="px-3"
                          disabled={isFieldDisabled('family_details', 'arrival_type')}
                          onChange={() => onArrivalChange(option)}
                        />
                        <span className="radioIcon" />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              {familyDetails.guardians.map((guardian, i) => (
                <div key={`guardians${i}`}>
                  <div className={`d-lg-flex ${styles.inputDiv}`}>
                    <p className={`${styles.label}`}>
                      {t('worker.details.family.fields.gurdian')} {i + 1}
                    </p>
                    <div className={`d-lg-flex flex-lg-wrap ${styles.flexColumn}`}>
                      <p className={`${styles.label}`}>
                        {t('worker.details.family.fields.gurdian_title')}
                      </p>
                      <div>
                        <Form.Control
                          className={`inputField ${styles.inputField}`}
                          required
                          disabled={isFieldDisabled('family_details', 'first_name')}
                          value={guardian.first_name}
                          placeholder={t('worker.details.family.placeholders.name')}
                          onChange={(e) => {
                            handleChange(i, 'first_name', e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <Form.Control
                          className={`inputField ${styles.inputField}`}
                          required
                          value={guardian.last_name}
                          disabled={isFieldDisabled('family_details', 'last_name')}
                          placeholder={t('worker.details.family.placeholders.surname')}
                          onChange={(e) => handleChange(i, 'last_name', e.target.value)}
                        />
                      </div>
                      <div>
                        <Form.Control
                          className={`inputField ${styles.inputField}`}
                          type="email"
                          required
                          value={guardian.email}
                          disabled={isFieldDisabled('family_details', 'email')}
                          placeholder={t('worker.details.family.placeholders.email')}
                          onChange={(e) => handleChange(i, 'email', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`d-lg-flex ${styles.binaryContainer}`}>
                    <p className={`${styles.label}`} />
                    <div
                      className={`d-flex flex-wrap justify-content-between justify-content-lg-start ${styles.flexColumn}`}
                    >
                      <p className={`${styles.label}`}>
                        {t('worker.details.family.fields.gurdian_qs1')}
                      </p>
                      {BINARY_STATUS.map((option, idx) => (
                        <div key={`arriving_to_finland_with_you${i}${idx}`}>
                          <label
                            htmlFor={`arriving_to_finland_with_you${i}${idx}`}
                            className="radioContainer"
                          >
                            {option
                              ? t('worker.details.family.placeholders.yes')
                              : t('worker.details.family.placeholders.no')}
                            <Form.Check.Input
                              checked={guardian.arriving_to_finland_with_you === option}
                              disabled={isFieldDisabled(
                                'family_details',
                                'arriving_to_finland_with_you'
                              )}
                              id={`arriving_to_finland_with_you${i}${idx}`}
                              name={`arriving${i}`}
                              type="radio"
                              onChange={() =>
                                handleChange(
                                  i,
                                  'arriving_to_finland_with_you',
                                  option === BINARY_STATUS[0]
                                )
                              }
                            />
                            <span className="radioIcon" />
                          </label>
                        </div>
                      ))}
                      <p className={`${styles.label}`}>
                        {t('worker.details.family.fields.gurdian_qs2')}
                      </p>
                      {BINARY_STATUS.map((option, indx) => (
                        <div key={`living_in_finland_with_you${i}${indx}`}>
                          <label
                            htmlFor={`living_in_finland_with_you${i}${indx}`}
                            className="radioContainer"
                          >
                            {option
                              ? t('worker.details.family.placeholders.yes')
                              : t('worker.details.family.placeholders.no')}
                            <Form.Check.Input
                              checked={guardian.living_in_finland_with_you === option}
                              disabled={isFieldDisabled(
                                'family_details',
                                'living_in_finland_with_you'
                              )}
                              id={`living_in_finland_with_you${i}${indx}`}
                              name={`living${i}`}
                              type="radio"
                              onChange={() =>
                                handleChange(
                                  i,
                                  'living_in_finland_with_you',
                                  option === BINARY_STATUS[0]
                                )
                              }
                            />
                            <span className="radioIcon" />
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="btnContainer">
          <Button type="submit" variant="saveBtn">
            {t('buttons.save_and_continue')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

FamilyDetails.propTypes = {
  workerId: PropTypes.string,
  familyDetails: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

FamilyDetails.defaultProps = {
  workerId: null,
  familyDetails: null,
  handleSubmit() {},
  isFieldDisabled() {},
  resetForm: null,
};

export default FamilyDetails;
