import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { getDateFormat, getLocale } from '../../lib/dateLib';

function FormDatePicker({ format, value, onChange, disabled, className }) {
  return (
    <div>
      <DatePicker
        className={`${className}`}
        locale={getLocale().val}
        disabled={disabled}
        required
        placeholderText={getDateFormat()}
        dateFormat={format || getDateFormat()}
        selected={value ? new Date(value) : ''}
        onChange={onChange}
      />
    </div>
  );
}

FormDatePicker.propTypes = {
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

FormDatePicker.defaultProps = {
  format: 'dd/MM/yyyy',
  value: null,
  onChange() {},
  disabled: false,
  className: null,
};

export default FormDatePicker;
