import React, { useContext, useEffect } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ChevronUp from '../../svgs/chevron-up';
import ChevronDown from '../../svgs/chevron-down';
import styles from './CustomToggle.module.scss';

function CustomToggle({ eventKey, onChange }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  useEffect(() => {
    onChange(activeEventKey);
  }, [activeEventKey, onChange]);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button type="button" className={styles.downBtnColumn} onClick={decoratedOnClick}>
      {isCurrentEventKey ? <ChevronUp /> : <ChevronDown />}
    </button>
  );
}

CustomToggle.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomToggle;
