import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import ProjectDetails from './ProjectDetails';

function ProjectList({ companyId, projects }) {
  return (
    <div>
      {projects.map((project) => {
        const newKeyIndex = uuid();
        return <ProjectDetails companyId={companyId} project={project} key={newKeyIndex} />;
      })}
    </div>
  );
}

ProjectList.propTypes = {
  companyId: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(Object).isRequired,
};

export default ProjectList;
