import React, { useId, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Button from '../button';
import PlusSvg from '../svgs/plus-svg';
import Check2CircleSvg from '../svgs/check2-circle';
import styles from './InvoiceItemsForm.module.scss';
import { preventInvalidChars } from '../../utils';

function InvoiceItemsForm({
  serviceItems,
  additionalItems,
  setAdditionalItems,
  billing,
  onSave,
  isSaveBtnDisabled,
  setSaveBtnState,
  formValidated,
}) {
  const { t } = useTranslation('lang');
  const serviceItemIndexPrefix = useId();
  const [formAdditionalItems, setFormAdditionalItems] = useState([]);

  const onNewAdditionalItem = () => {
    const newItem = {
      name: 'Enter Other Cost',
      quantity: 1,
      price: 1,
      total_price: 1,
      preloaded: false,
      updated: true,
      uuid: uuid(),
    };
    setAdditionalItems([...additionalItems, { ...newItem }]);
    setSaveBtnState(false);
  };

  const onItemChange = (index, e) => {
    const values = [...additionalItems];
    const value = ['price', 'quantity'].includes(e.target.name)
      ? parseFloat(e.target.value)
      : e.target.value;
    if (e.target.name === 'price')
      values[index].total_price = parseFloat(
        parseFloat(value) * parseFloat(values[index].quantity)
      ).toFixed(2);

    if (e.target.name === 'quantity')
      values[index].total_price = parseFloat(
        parseFloat(value) * parseFloat(values[index].price)
      ).toFixed(2);

    values[index][e.target.name] = value;
    values[index].updated = true;
    setAdditionalItems(values);
    setSaveBtnState(false);
  };

  useEffect(() => {
    if (additionalItems) {
      setFormAdditionalItems(additionalItems.map((item) => ({ ...item, key: uuid() })));
    }
  }, [additionalItems]);

  return (
    <div className={`${styles.itemsMainContainer}`}>
      <div className={`${styles.itemsTitleContainer}`}>
        <p className={`${styles.itemsTitle}`}>{t('invoice_items.title')}</p>
      </div>
      <div>
        {serviceItems &&
          serviceItems.length !== 0 &&
          serviceItems.map((item) => {
            const newKeyIndex = uuid();
            return (
              <div
                className={`${styles.serviceItemContainer}`}
                key={`${serviceItemIndexPrefix}${newKeyIndex}`}
              >
                <div className={`${styles.serviceTitle}`}>
                  <p className="d-lg-none">{t('invoice_items.service_items')}</p>
                </div>
                <div>
                  <div
                    className={`d-flex justify-content-between flex-wrap flex-lg-nowrap ${styles.flexMainContainer}`}
                  >
                    <div className={`${styles.flexContainer}`}>
                      <p className="d-none d-lg-block">{t('invoice_items.service_items')}</p>
                      <p className={`${styles.flexTitle}`}>{item.name}</p>
                    </div>
                    <div className={`text-lg-center ${styles.flexContainer}`}>
                      <p>{t('invoice_items.total_to_invoice')}</p>
                      <p className={`${styles.flexTitle}`}>{item.total_to_invoice}</p>
                    </div>
                    <div className={`text-lg-center ${styles.flexContainer}`}>
                      <p>{t('invoice_items.sum')}</p>
                      <p className={`${styles.flexTitle}`}>{item.total_amount} €</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <Form validated={formValidated} onSubmit={onSave}>
          <div>
            <div className={`${styles.serviceItemContainer}`}>
              <div className={`${styles.serviceTitle}`}>
                <p className="d-lg-none">{t('invoice_items.additional.name')}</p>
              </div>
              <div
                className={`d-flex justify-content-between flex-wrap flex-lg-nowrap ${styles.flexMainContainer} ${styles.additionalContainer}`}
              >
                <div className={`${styles.flexContainer}`}>
                  <p className="d-none d-lg-block">{t('invoice_items.additional.name')}</p>
                  {/* <p className={`${styles.flexTitle}`}>
                    {t('invoice_items.additional.pcs')}
                  </p> */}
                </div>
                <div className={`text-lg-center ${styles.flexContainer}`}>
                  <p>{t('invoice_items.additional.pcs')}</p>
                  {/* <p className={`${styles.flexTitle}`}>22</p> */}
                </div>
                <div className={` text-lg-center ${styles.flexContainer}`}>
                  <p>{t('invoice_items.additional.a')}</p>
                  {/* <p className={`${styles.flexTitle}`}>22</p> */}
                </div>
                <div className={`text-lg-center ${styles.flexContainer}`}>
                  <p>{t('invoice_items.additional.sum')}</p>
                  {/* <p className={`${styles.flexTitle}`}>22</p> */}
                </div>
              </div>
            </div>
            {formAdditionalItems.map((itm, index) => (
              <div
                className={`d-flex justify-content-between flex-wrap flex-lg-nowrap ${styles.inputMainContainer}`}
                key={itm.uuid}
              >
                <div className={`${styles.inputFieldContainer}`}>
                  <Form.Control
                    className={`inputField ${styles.inputField}`}
                    type="text"
                    name="name"
                    value={itm.name}
                    onChange={(e) => onItemChange(index, e)}
                    required
                    placeholder={t('invoice_items.placeholders.item_name')}
                  />
                </div>
                <div className={`${styles.inputFieldContainer}`}>
                  <Form.Control
                    className={`inputField ${styles.inputField}`}
                    type="number"
                    name="quantity"
                    onKeyDown={preventInvalidChars}
                    step="any"
                    min="0.1"
                    value={itm.quantity}
                    onChange={(e) => onItemChange(index, e)}
                    required
                    placeholder={t('invoice_items.placeholders.pcs')}
                  />
                </div>
                <div className={`${styles.inputFieldContainer}`}>
                  <Form.Control
                    className={`inputField ${styles.inputField}`}
                    type="number"
                    name="price"
                    step="any"
                    onKeyDown={preventInvalidChars}
                    min="0.1"
                    value={itm.price}
                    onChange={(e) => onItemChange(index, e)}
                    required
                    placeholder={t('invoice_items.placeholders.a')}
                  />
                </div>

                <div className={`${styles.inputFieldContainer}`}>
                  <Form.Control
                    className={`inputField ${styles.inputField}`}
                    type="number"
                    name="total_price"
                    value={itm.total_price}
                    readOnly
                    placeholder={t('invoice_items.placeholders.sum')}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.BtnContainer}`}
          >
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              className={`d-flex align-items-center ${styles.addBtn}`}
              onClick={onNewAdditionalItem}
            >
              <div className={`${styles.iconContainer}`}>
                <PlusSvg className={`${styles.icon}`} />
              </div>
              <p> {t('buttons.add_new_item')}</p>
            </div>
            <div className={`d-flex align-items-center ${styles.saveBtnContainer}`}>
              <Button
                className={`d-flex align-items-center ${styles.saveBtn}`}
                variant="saveBtn"
                type="submit"
                disabled={isSaveBtnDisabled}
              >
                <div className={`${styles.iconContainer}`}>
                  <Check2CircleSvg />
                </div>
                <div>
                  <p> {t('buttons.save')} </p>
                </div>
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <div className={`${styles.totalContainer}`}>
        <div
          className={`d-flex align-items-center justify-content-between justify-content-lg-end ${styles.flexDiv}`}
        >
          <div>
            <p>{t('invoice_items.sum')}</p>
          </div>
          <div>
            <p>{billing?.total_amount_before_vat} €</p>
          </div>
        </div>
        <div
          className={`d-flex align-items-center justify-content-between justify-content-lg-end ${styles.flexDiv}`}
        >
          <div>
            <p>
              {t('invoice_items.vat')} {billing?.vat_rate}%
            </p>
          </div>
          <div>
            <p>{billing?.vat_amount} €</p>
          </div>
        </div>
        <div
          className={`d-flex align-items-center justify-content-between justify-content-lg-end ${styles.flexDiv}`}
        >
          <div>
            <p>{t('invoice_items.total')}:</p>
          </div>
          <div>
            <p>{billing?.total_amount_after_vat} €</p>
          </div>
        </div>
      </div>
    </div>
  );
}

InvoiceItemsForm.propTypes = {
  serviceItems: PropTypes.arrayOf(Object).isRequired,
  additionalItems: PropTypes.arrayOf(Object),
  setAdditionalItems: PropTypes.func,
  billing: PropTypes.instanceOf(Object).isRequired,
  onSave: PropTypes.func.isRequired,
  isSaveBtnDisabled: PropTypes.bool.isRequired,
  setSaveBtnState: PropTypes.func.isRequired,
  formValidated: PropTypes.bool.isRequired,
};

InvoiceItemsForm.defaultProps = {
  additionalItems: [],
  setAdditionalItems() {},
};

export default InvoiceItemsForm;
