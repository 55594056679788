import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import enLang from './translations/en/lang.json';
import fiLang from './translations/fi/lang.json';
import ruLang from './translations/ru/lang.json';

i18next.use(initReactI18next).init({
  supportedLngs: ['en', 'fi', 'ru'],
  resources: {
    en: {
      lang: enLang,
    },
    fi: {
      lang: fiLang,
    },
    ru: {
      lang: ruLang,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
