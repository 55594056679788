import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import styles from './SelectableMultiTags.module.scss';
import SearchBox from '../search-box';
import Tag from '../tag';

function SelectableMultiTags({ list, selectedTags, onSelect, className, label, id }) {
  const { t } = useTranslation('lang');
  const [listItems, setListItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState('');

  const getSortedList = (newList) =>
    newList.sort((a, b) => {
      if (a[label] > b[label]) {
        return 1;
      }
      if (b[label] > a[label]) {
        return -1;
      }

      return 0;
    });

  const handleClick = (item, index) => {
    let listItemsTemp = listItems;
    const listItem = listItemsTemp[index];
    listItem.selected = !listItem.selected;
    listItemsTemp = [...listItemsTemp.slice(0, index), listItem, ...listItemsTemp.slice(index + 1)];

    const idx = selectedItems.findIndex((obj) => obj[id] === item[id]);
    let updatedItems = selectedItems;
    if (idx === -1) {
      updatedItems = [...updatedItems, item];
    } else {
      updatedItems = updatedItems.filter((obj) => obj[id] !== item[id]);
    }
    setSelectedItems(updatedItems);
    setListItems(getSortedList(listItemsTemp));
    onSelect(updatedItems);
  };

  const handleFilter = (items) =>
    items.filter((item) => item[label].toLowerCase().indexOf(query.toLowerCase()) > -1);

  const prepareLists = (newSelectedItems, allItems) => {
    if (newSelectedItems.length > 0) {
      const unSelectedItems = allItems.filter(
        (item) => newSelectedItems.findIndex((selectedItem) => selectedItem[id] === item[id]) === -1
      );
      const modifiedSelectedItems = newSelectedItems.map((selectedItem) => ({
        ...selectedItem,
        selected: true,
      }));
      const modifiedUnselectedItems = unSelectedItems.map((unSelectedItem) => ({
        ...unSelectedItem,
        selected: false,
      }));
      const modifiedItems = [...modifiedUnselectedItems, ...modifiedSelectedItems];
      setListItems(getSortedList(modifiedItems));
      setSelectedItems(modifiedSelectedItems);
    } else {
      setListItems(getSortedList(allItems.map((item) => ({ ...item, selected: false }))));
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    prepareLists(selectedTags, list);
  }, [selectedTags, list]);

  return (
    <div className={`${className + styles.multipleTagsContainer}`}>
      <div className={`${styles.searchBoxContainer}`}>
        <SearchBox onSearch={setQuery} />
      </div>
      <div className={`${styles.tagScrollContainer} d-flex flex-wrap`}>
        {listItems.length !== 0
          ? handleFilter(listItems).map((item, index) => {
              const newKeyIndex = uuid();
              return (
                <div
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex={0}
                  key={newKeyIndex}
                  onClick={() => handleClick(item, index)}
                >
                  <Tag selected={item.selected}>{item[label]}</Tag>
                </div>
              );
            })
          : t('settings.project.work_type_empty_msg')}
      </div>
    </div>
  );
}

SelectableMultiTags.propTypes = {
  list: PropTypes.arrayOf(Object),
  selectedTags: PropTypes.arrayOf(Object),
  onSelect: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
};

SelectableMultiTags.defaultProps = {
  list: [],
  selectedTags: [],
  onSelect() {},
  className: null,
  label: 'name',
  id: 'uuid',
};

export default SelectableMultiTags;
