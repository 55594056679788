import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './LastestJobs.module.scss';
import Button from '../button';
import Status from '../status';
import LatestJobAccordion from '../latest-job-accordion/LatestJobAccordion';
import { getAllTasks } from '../../services/task';
import SideLoader from '../side-loader';
import { getDate } from '../../lib/dateLib';

function LastestJobs() {
  const navigate = useNavigate();
  const [lastestJobs, setLatestJobs] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('lang');

  const getWorkersCount = (workers) => {
    const worker = workers[0]?.worker;
    if (worker) {
      const firstName = worker.firstname || '';
      const lastName = worker.lastname || '';
      if (workers.length === 1) {
        return `${firstName} ${lastName}`;
      }
      return `${firstName} ${lastName} (+${workers.length - 1})`;
    }
    return 'NA';
  };

  const fetchLatestJobs = () => {
    setLoading(true);
    getAllTasks().then((response) => {
      setLatestJobs(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchLatestJobs();
  }, []);

  return (
    <div className={` fontProximaNova ${styles.tableMainContainer} `}>
      {loading && <SideLoader />}
      <div className={`${styles.tableTitle}`}>
        <p>{t('home.latest_jobs_table.heading')}</p>
      </div>
      <div className={` d-none d-lg-block ${styles.tableContainer}`}>
        <div className={` d-flex ${styles.tableRow} ${styles.tableHeading}`}>
          <div>{t('home.latest_jobs_table.id')}</div>
          <div>{t('home.latest_jobs_table.client')}</div>
          <div>{t('home.latest_jobs_table.start')}</div>
          <div>
            {t('home.latest_jobs_table.price')} /{t('home.latest_jobs_table.h')}
          </div>
          <div>{t('home.latest_jobs_table.worker')}</div>
          <div>{t('home.latest_jobs_table.status')}</div>
          <div>
            {t('home.latest_jobs_table.total')} {t('home.latest_jobs_table.h')}
          </div>
        </div>

        {lastestJobs.data && lastestJobs.data.length !== 0
          ? lastestJobs.data.map((eachJobEntry) => (
              <div className={` d-flex ${styles.tableRow}`} key={eachJobEntry.uuid}>
                <div>...{eachJobEntry.uuid.substring(eachJobEntry.uuid.length - 5)}</div>
                <div>{eachJobEntry.project.company.name}</div>
                <div>{getDate(eachJobEntry.contract_period_start)}</div>
                <div>{eachJobEntry.hourly_rate}</div>
                <div>
                  {eachJobEntry.workers.length !== 0
                    ? getWorkersCount(eachJobEntry.workers)
                    : t('common_messages.no_available_worker')}
                </div>
                <div>
                  <Status
                    className="jobsStatus"
                    variant={eachJobEntry.status ? eachJobEntry.status : t('common_messages.na')}
                    type="small"
                  >
                    {eachJobEntry.status || t('common_messages.na')}
                  </Status>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>{eachJobEntry.totalHours ? eachJobEntry.totalHours : 0}</div>
                  <div className={`${styles.detailsBtnContainer}`}>
                    <Button
                      className={`${styles.detailsBtn}`}
                      type="button"
                      onClick={() =>
                        navigate(
                          `/companies/${eachJobEntry.project.company.uuid}/projects/${eachJobEntry.project.uuid}/task/${eachJobEntry.uuid}`
                        )
                      }
                    >
                      {t('home.latest_jobs_table.details')}
                    </Button>
                  </div>
                </div>
              </div>
            ))
          : t('common_messages.no_jobs_available')}
      </div>
      {lastestJobs.data && lastestJobs.data.length !== 0 ? (
        lastestJobs.data.map((eachJobEntry) => (
          <LatestJobAccordion
            id={eachJobEntry.uuid}
            client={eachJobEntry.project.company.name}
            start={getDate(eachJobEntry.contract_period_start)}
            price={eachJobEntry.hourly_rate}
            worker={
              eachJobEntry.workers.length !== 0
                ? getWorkersCount(eachJobEntry.workers)
                : t('common_messages.no_available_worker')
            }
            status={eachJobEntry.status || ''}
            total={eachJobEntry.totalHours ? eachJobEntry.totalHours : 0}
            key={eachJobEntry.uuid}
          />
        ))
      ) : (
        <div className="d-lg-none">{t('common_messages.no_jobs_available')}</div>
      )}
    </div>
  );
}

export default LastestJobs;
