import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AttachmentForm from '../attachment-form';
import { getAttachments, uploadAttachment, deleteAttachment } from '../../services/attachments';
import { Console } from '../../utils';

function TaskAttachmentForm({ handleSubmit, resetForm }) {
  const { taskId } = useParams();
  const { t } = useTranslation('lang');
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [saveBtn, setSaveBtn] = useState(false);
  const [oldState, setOldState] = useState(null);

  const fetchTaskAttachments = () => {
    getAttachments('tasks', taskId).then((res) => {
      if (res.is_success) {
        const attachmentFiles = res.data.items;
        setAttachments(attachmentFiles);
        setFiles(attachmentFiles);
        setOldState({
          attachments: attachmentFiles,
          files: attachmentFiles,
        });
      }
    });
  };

  const onAttachmentSave = async () => {
    const existingFiles = files.filter((file) => !!file.uuid).map((f) => f.uuid);
    const filesToDelete = attachments
      .filter((attachment) => !existingFiles.includes(attachment.uuid))
      .map((file) => file.uuid);
    const filesToUpload = files.filter((file) => !file.uuid);
    try {
      await Promise.all([
        ...filesToUpload.map((file) => {
          const newFormData = new FormData();
          newFormData.append('', file);
          return uploadAttachment('tasks', taskId, newFormData);
        }),
      ]);
      await Promise.all([...filesToDelete.map((uuid) => deleteAttachment('tasks', taskId, uuid))]);
      fetchTaskAttachments();
      toast.success(t('task.msgs.attachment_updated'));
    } catch (error) {
      Console.log(error);
    }
    handleSubmit(3);
  };

  useEffect(() => {
    if (taskId) {
      fetchTaskAttachments();
    }
  }, []);

  useEffect(() => {
    if (resetForm.reset) {
      if (oldState) {
        setFiles(oldState.files);
        setAttachments(oldState.attachments);
        setSaveBtn(false);
      }
    }
  }, [resetForm.resetId]);

  return (
    <AttachmentForm
      files={files}
      setFiles={(newFiles) => {
        setFiles(newFiles);
        setSaveBtn(true);
      }}
      onSave={() => onAttachmentSave()}
      holderId={taskId}
      holder="tasks"
      saveButtonEnabled={saveBtn}
    />
  );
}

TaskAttachmentForm.propTypes = {
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.instanceOf(Object),
};

TaskAttachmentForm.defaultProps = {
  handleSubmit() {},
  resetForm: null,
};

export default TaskAttachmentForm;
