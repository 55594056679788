import service from './wrapper';

export const getWorkerInvoiceData = async (companyId, projectId, taskId) =>
  service.post(`/companies/${companyId}/projects/${projectId}/tasks/${taskId}/invoice-details`);

export const getInvoices = async (page = 1, limit = 10) => service.get(`/invoice?page=${page}&limit=${limit}`);

export const markInvoiced = (taskId) => service.put(`/invoice/${taskId}`);

export const addProduct = (taskId, data) => service.post(`/invoice/${taskId}/products`, data);

export const updateProduct = (taskId, productId, data) =>
  service.put(`/invoice/${taskId}/products/${productId}`, data);

export const deleteProduct = (taskId, productId) =>
  service.delete(`/invoice/${taskId}/products/${productId}`);

export const printInvoice = (data) =>
  service.post(`/invoice/print`, data, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  });
