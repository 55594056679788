import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styles from './ProfileForm.module.scss';

function ProfileForm({ formData }) {
  const { t } = useTranslation('lang');
  return (
    <div className={`${styles.formContainer}`}>
      {formData.street_one && (
        <div>
          <p>{t('worker.profile.street')}</p>
          <Form.Control
            className={`${styles.inputField} inputField`}
            required
            value={formData.street_one}
            onChange={() => {}}
            readOnly
          />
        </div>
      )}

      {formData.street_two && (
        <div>
          <p>{t('worker.profile.street2')}</p>
          <Form.Control
            className={`${styles.inputField} inputField`}
            value={formData.street_two}
            readOnly
            onChange={() => {}}
          />
        </div>
      )}

      {formData.zip && (
        <div>
          <p>{t('worker.profile.zip')}</p>
          <Form.Control
            className={`${styles.inputField} inputField`}
            type="text"
            maxLength={6}
            value={formData.zip}
            readOnly
            onChange={() => {}}
          />
        </div>
      )}

      {formData.born_city && (
        <div>
          <p>{t('worker.profile.city')}</p>
          <Form.Control
            className={`${styles.inputField} inputField`}
            value={formData.born_city}
            readOnly
            onChange={() => {}}
          />
        </div>
      )}

      {formData.email && (
        <div>
          <p>{t('worker.profile.email')}</p>
          <Form.Control
            className={`${styles.inputField} inputField`}
            required
            type="email"
            value={formData.email}
            readOnly
            onChange={() => {}}
          />
        </div>
      )}

      {formData.phone && (
        <div>
          <p>{t('worker.profile.phone')}</p>
          <Form.Control
            className={`${styles.inputField} inputField`}
            required
            value={formData.phone}
            readOnly
            onChange={() => {}}
          />
        </div>
      )}
    </div>
  );
}

ProfileForm.propTypes = {
  formData: PropTypes.objectOf(Object).isRequired,
};

export default ProfileForm;
