import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import MenuTabs from '../menu-tabs';
import BasicDetails from './BasicDetails';
import FamilyDetails from './FamilyDetails';
import formToInitialValues from './helper';
import PassportDetails from './PassportDetails';
import { getWorkerDetails } from '../../services/workers';
import { fetchWorkerStatus } from '../../redux/features/workers/worker';
import ContactDetails from './ContactDetails';
import Attachments from './Attachments';
import Consent from './Consent';
import OtherDetails from './OtherDetails';
import VisaDetails from './VisaDetails';
import WorkContract from './WorkContract';
import useConstants from './useConstants';
import { Console } from '../../utils';
import PageLoader from '../page-loader';

function WorkerForm(props) {
  const { workerId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('lang');
  const { WORKER_BASIC_DETAILS, WORKER_DETAILS_SECTIONS } = useConstants();
  const [currentTab, setCurrentTab] = useState(0);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [basicDetails, setBasicDetails] = useState(formToInitialValues(WORKER_BASIC_DETAILS));
  const [preloadedWorkTypes, setPreloadedWorkTypes] = useState([]);
  const [preloadedWorkTypePermissions, setPreloadedWorkTypePermissions] = useState([]);
  const [workerOldState, setWorkerOldState] = useState({});
  const [resetForm, setResetForm] = useState({
    reset: false,
    resetId: uuid(),
  });
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (!userInfo?.data?.is_admin && basicDetails?.consent_details?.has_given_consent) {
      navigate('/');
    }
  }, []);

  const isFieldDisabled = (parentKey, fieldName) => {
    if (userInfo?.data?.is_admin) {
      return false;
    }
    const fieldsToUpdateObj = basicDetails.fieldsToUpdate
      ? JSON.parse(basicDetails.fieldsToUpdate)
      : {};
    return fieldsToUpdateObj[parentKey] && fieldsToUpdateObj[parentKey].indexOf(fieldName) !== -1;
  };

  const getBasicDetails = () => {
    getWorkerDetails(workerId)
      .then((data) => {
        if (data.status) {
          const details = data.data;
          const workTypes = data.data.workTypes ?? [];
          const workTypePermissions = data.data.workTypePermissions ?? [];
          setBasicDetails(details);
          setPreloadedWorkTypes(workTypes);
          setPreloadedWorkTypePermissions(workTypePermissions);
          setWorkerOldState({
            basicDetails: details,
            preLoadedWorkTypes: workTypes,
            preLoadedWorkTypePermissions: workTypePermissions,
          });
          setResetForm({ ...resetForm, reset: false });
        }
      })
      .catch((error) => {
        Console.log(error);
        navigate('/workers/add');
      });
    setPageLoading(false);
  };

  const handleSubmit = (id) => {
    toast.success(t('worker.details.family.msgs.saved'));
    getBasicDetails();
    if (id < 8) setCurrentTab(id + 1);
    setResetForm({ reset: false, resetId: uuid() });
  };

  const onTabChange = (key) => {
    if (currentTab !== parseInt(key, 10)) {
      setResetForm({ reset: true, resetId: uuid() });
    }
  };

  useEffect(() => {
    if (workerId) {
      setPageLoading(true);
      getBasicDetails();
    }
    if (userInfo?.data?.is_admin) dispatch(fetchWorkerStatus());
  }, [workerId, dispatch, navigate]);

  return pageLoading ? (
    <PageLoader />
  ) : (
    <div className="workerTabContainer">
      <MenuTabs
        currentTab={currentTab}
        onTabChange={onTabChange}
        components={[
          {
            tabIndex: 0,
            tabName: WORKER_DETAILS_SECTIONS[0],
            component: (
              <BasicDetails
                workerId={workerId}
                basicDetails={basicDetails}
                preloadedWorkTypes={preloadedWorkTypes}
                preloadedWorkTypePermissions={preloadedWorkTypePermissions}
                setBasicDetails={setBasicDetails}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                workerOldState={workerOldState}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 1,
            tabName: WORKER_DETAILS_SECTIONS[1],
            disabled: !workerId,
            component: (
              <FamilyDetails
                workerId={workerId}
                familyDetails={basicDetails.family_details}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 2,
            tabName: WORKER_DETAILS_SECTIONS[2],
            disabled: !workerId,
            component: (
              <PassportDetails
                workerId={workerId}
                passportDetails={basicDetails.passport_details}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 3,
            tabName: WORKER_DETAILS_SECTIONS[3],
            disabled: !workerId,
            component: (
              <ContactDetails
                workerId={workerId}
                contactDetails={basicDetails.contact_details}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 4,
            tabName: WORKER_DETAILS_SECTIONS[4],
            disabled: !workerId,
            component: (
              <VisaDetails
                workerId={workerId}
                visaDetails={basicDetails.visa_details}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 5,
            tabName: WORKER_DETAILS_SECTIONS[5],
            disabled: !workerId,
            component: (
              <WorkContract
                workerId={workerId}
                workContract={basicDetails.work_contract_details}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 6,
            tabName: WORKER_DETAILS_SECTIONS[6],
            disabled: !workerId,
            component: (
              <OtherDetails
                workerId={workerId}
                otherDetails={basicDetails.other_details}
                handleSubmit={handleSubmit}
                isFieldDisabled={isFieldDisabled}
                resetForm={resetForm}
              />
            ),
          },
          {
            tabIndex: 7,
            tabName: WORKER_DETAILS_SECTIONS[7],
            disabled: !workerId,
            component: (
              <Attachments workerId={workerId} handleSubmit={handleSubmit} resetForm={resetForm} />
            ),
          },
          {
            tabIndex: 8,
            tabName: WORKER_DETAILS_SECTIONS[8],
            disabled: !workerId,
            component: (
              <Consent
                workerId={workerId}
                consent={basicDetails.consent_details}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

WorkerForm.propTypes = {
  workerId: PropTypes.string,
};

WorkerForm.defaultProps = {
  workerId: null,
};

export default WorkerForm;
