/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function ArrowDownLeftCircleFill({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-5.904-2.803a.5.5 0 1 1 .707.707L6.707 10h2.768a.5.5 0 0 1 0 1H5.5a.5.5 0 0 1-.5-.5V6.525a.5.5 0 0 1 1 0v2.768l4.096-4.096z" />
    </svg>
  );
}

ArrowDownLeftCircleFill.propTypes = {
  className: PropTypes.string,
};

ArrowDownLeftCircleFill.defaultProps = {
  className: null,
};

export default ArrowDownLeftCircleFill;
