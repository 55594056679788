import React from 'react';
import { BarLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import styles from './SideLoader.module.scss';

function SideLoader({ color }) {
  return (
    <div
      className={`${styles.sideLoaderContainer} d-flex justify-content-center align-items-center`}
    >
      <BarLoader loading color={color} height={5} width={1920} />
    </div>
  );
}

SideLoader.propTypes = {
  color: PropTypes.string,
};

SideLoader.defaultProps = {
  color: '#D56730',
};

export default SideLoader;
