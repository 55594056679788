import React from 'react';
import './App.scss';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import View from './views';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<View />} />
      </Routes>
    </Router>
  );
}

export default App;
