import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './MenuTabs.module.scss';
import ChevronLeft from '../svgs/chevron-left';
import ChevronRight from '../svgs/chevron-right';

function MenuTabs(props) {
  const { page, components, className, currentTab, onTabChange = () => {}, tabClassName } = props;
  const [key, setKey] = useState('');
  const [tabs, setTabs] = useState([]);
  const [showNavigations, setShowNavigations] = useState(true);

  const onTabSelect = (newKey) => {
    setKey(newKey);
    onTabChange(newKey);
  };

  const onTabNavigate = (e, action) => {
    let activeKey = parseInt(key, 10);
    let elements = document.querySelectorAll('.menuTabOption');
    elements = Array.from(elements);

    if (action === 'left') {
      if (activeKey !== 0) {
        activeKey -= 1;
        setKey(activeKey.toString());
        const elementIndex = elements.findIndex((element) =>
          Array.from(element.classList).includes('active')
        );
        const element = elementIndex > 1 ? elements[elementIndex - 2] : elements[0];
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (action === 'right') {
      if (activeKey !== components.length - 1) {
        activeKey += 1;
        setKey(activeKey.toString());
        const elementIndex = elements.findIndex((element) =>
          Array.from(element.classList).includes('active')
        );
        const element =
          elementIndex < elements.length - 2
            ? elements[elementIndex + 2]
            : elements[elements.length - 1];
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const checkViewSize = () => {
    const width = window.innerWidth;
    if (page === 'task') {
      if (width >= 992) {
        setShowNavigations(false);
      } else {
        setShowNavigations(true);
      }
    } else {
      setShowNavigations(true);
    }
  };

  useEffect(() => {
    if (currentTab) {
      setKey(currentTab);
    } else {
      setKey(components?.length !== 0 && components[0].tabIndex);
    }
  }, [currentTab]);

  useEffect(() => {
    if (components) {
      setTabs(components);
      checkViewSize();
    }
  }, [components]);

  useEffect(() => {
    window.addEventListener('resize', checkViewSize);
    return () => window.removeEventListener('resize', checkViewSize);
  });

  return (
    <div className={`${className} ${styles.tabMainContainer}`}>
      {tabs.length > 3 && parseInt(key, 10) !== 0 && showNavigations && (
        <div
          className={styles.leftArrowContainer}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={(e) => onTabNavigate(e, 'left')}
        >
          <ChevronLeft className={styles.leftRightArrow} />
        </div>
      )}

      {tabs.length > 3 && parseInt(key, 10) !== tabs.length - 1 && showNavigations && (
        <div
          className={styles.rightArrowContainer}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={(e) => onTabNavigate(e, 'right')}
        >
          <ChevronRight className={styles.leftRightArrow} />
        </div>
      )}

      <Tabs id="controlled-tab" activeKey={key} onSelect={onTabSelect} className="menuTabs">
        {tabs.length !== 0 &&
          tabs.map((tab, index) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              eventKey={tab.tabIndex}
              tabClassName={`${tabClassName || null} ${
                tab.disabled ? 'disabledTab' : ''
              } menuTabOption`}
              title={tab.tabName}
              disabled={tab.disabled ? tab.disabled : false}
            >
              <div>{tab.component}</div>
            </Tab>
          ))}
      </Tabs>
    </div>
  );
}

MenuTabs.propTypes = {
  page: PropTypes.string,
  components: PropTypes.arrayOf(Object),
  className: PropTypes.string,
  currentTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTabChange: PropTypes.func,
  tabClassName: PropTypes.string,
};

MenuTabs.defaultProps = {
  page: null,
  components: [],
  className: null,
  currentTab: 0,
  onTabChange() {},
  tabClassName: null,
};

export default MenuTabs;
