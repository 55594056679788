import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchSvg from '../svgs/search-svg';
import styles from './SearchBox.module.scss';

function SearchBox({ onSearch, className }) {
  const { t } = useTranslation('lang');
  const [searchText, setSearchText] = useState('');
  const handleChange = (val) => {
    setSearchText(val);
    onSearch(val);
  };
  return (
    <div className={`${styles.inputContainer} ${className}`}>
      <input
        className={styles.inputField}
        value={searchText}
        onChange={(e) => handleChange(e.target.value)}
        type="text"
        placeholder={t('search.title')}
      />
      <div className={styles.inputIconContainer}>
        <SearchSvg />
      </div>
    </div>
  );
}

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SearchBox.defaultProps = {
  className: null,
};

export default SearchBox;
