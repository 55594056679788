import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { addWorkerDetails, updateWorkerDetails } from '../../services/workers';
import Button from '../button';
import styles from './ContactDetails.module.scss';
import formToInitialValues from './helper';
import useConstants from './useConstants';
import { Console } from '../../utils';

function ContactDetails({
  workerId,
  contactDetails: newContactDetails,
  handleSubmit,
  isFieldDisabled,
  resetForm,
}) {
  const { t } = useTranslation('lang');
  const { CONTACT_DETAILS: form } = useConstants();
  const [contactDetails, setContactDetails] = useState(formToInitialValues(form));
  const [fieldsToUpdate, setFields] = useState({});
  const { userInfo } = useSelector((state) => state.userLogin);

  const updateFields = (key) => {
    if (userInfo?.data?.is_admin) {
      if (!fieldsToUpdate.contact_details) {
        fieldsToUpdate.contact_details = [];
      }
      if (!fieldsToUpdate.contact_details.includes(key)) {
        setFields((prevState) => ({
          ...prevState,
          contact_details: [...fieldsToUpdate.contact_details, key],
        }));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let res = null;
    const {
      email,
      phone,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      zip,
      city,
    } = contactDetails;
    const requestBody = {
      contact_details: [
        {
          email,
          phone,
          Addresses: [
            { street_address: streetAddress, street_address_2: streetAddress2, zip, city },
          ],
        },
      ],
      fieldsToUpdate: JSON.stringify(fieldsToUpdate),
    };
    if (newContactDetails.length) {
      res = updateWorkerDetails(workerId, requestBody);
    } else {
      res = addWorkerDetails(workerId, requestBody);
    }
    res
      .then(({ status }) => {
        if (status) handleSubmit(3);
      })
      .catch((err) => {
        Console.log(err);
      });
  };

  const setContactValues = ({ email, phone, Addresses }) => {
    setContactDetails({ email, phone, ...Addresses[0] });
  };

  useEffect(() => {
    if (newContactDetails && newContactDetails.length) {
      setContactValues(newContactDetails[0]);
    }
  }, [newContactDetails]);

  // This useEffect is used to reset the form values when user clicks on other tab without saving details
  useEffect(() => {
    if (resetForm.reset) {
      if (newContactDetails && newContactDetails.length > 0) {
        setContactValues(newContactDetails[0]);
      } else {
        setContactDetails(formToInitialValues(form));
      }
    }
  }, [resetForm.resetId]);

  return (
    <div>
      <Form className="scrollContainer" onSubmit={onSubmit}>
        <div className={`d-flex flex-column h-100 ${styles.contactContainer}`}>
          <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{form.street_address.label}</p>
            <div className={`${styles.inputDiv}`}>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                required
                value={contactDetails.street_address}
                placeholder={form.street_address.placeholder}
                disabled={isFieldDisabled('contact_details', 'street_address')}
                onChange={(e) => {
                  updateFields('street_address');
                  setContactDetails({
                    ...contactDetails,
                    street_address: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{form.street_address_2.label}</p>
            <div className={`${styles.inputDiv}`}>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                required
                disabled={isFieldDisabled('contact_details', 'street_address_2')}
                value={contactDetails.street_address_2}
                placeholder={form.street_address_2.placeholder}
                onChange={(e) => {
                  updateFields('street_address_2');
                  setContactDetails({
                    ...contactDetails,
                    street_address_2: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div
            className={`d-flex align-items-lg-center justify-content-between justify-content-lg-start ${styles.flexContainer}`}
          >
            <p className={`d-none d-lg-block ${styles.label}`}>{form.zip.label}</p>
            <div
              className={`d-flex justify-content-between justify-content-start ${styles.inputDiv}`}
            >
              <div className={`${styles.cityZipContainer}`}>
                <p className={`d-lg-none ${styles.label}`}>{form.zip.label}</p>
                <div className={`${styles.inputDiv}`}>
                  <Form.Control
                    disabled={isFieldDisabled('contact_details', 'zip')}
                    className={`inputField ${styles.inputField}`}
                    required
                    type={form.zip.type}
                    value={contactDetails.zip}
                    placeholder={form.zip.placeholder}
                    onChange={(e) => {
                      updateFields('zip');
                      setContactDetails({
                        ...contactDetails,
                        zip: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className={`d-lg-flex align-items-lg-center ${styles.cityZipContainer}`}>
                <p className={`${styles.label}`}>{form.city.label}</p>
                <div className={`${styles.inputDiv}`}>
                  <Form.Control
                    className={`inputField ${styles.inputField}`}
                    required
                    disabled={isFieldDisabled('contact_details', 'city')}
                    value={contactDetails.city}
                    placeholder={form.city.placeholder}
                    onChange={(e) => {
                      updateFields('city');
                      setContactDetails({
                        ...contactDetails,
                        city: e.target.value.replace(/[0-9]/g, ''),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{form.phone.label}</p>
            <div className={`${styles.inputDiv}`}>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                required
                disabled={isFieldDisabled('contact_details', 'phone')}
                type={form.phone.type}
                onKeyDown={(e) => {
                  if (['-', '*', '/', '.', 'e', 'E'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={contactDetails.phone}
                placeholder={form.phone.placeholder}
                onChange={(e) => {
                  updateFields('phone');
                  setContactDetails({
                    ...contactDetails,
                    phone: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={`d-lg-flex align-items-lg-center ${styles.flexContainer}`}>
            <p className={`${styles.label}`}>{form.email.label}</p>
            <div className={`${styles.inputDiv}`}>
              <Form.Control
                className={`inputField ${styles.inputField}`}
                required
                disabled={isFieldDisabled('contact_details', 'email')}
                type={form.email.type}
                value={contactDetails.email}
                placeholder={form.email.placeholder}
                onChange={(e) => {
                  updateFields('email');
                  setContactDetails({
                    ...contactDetails,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="btnContainer">
            <Button type="submit" variant="saveBtn">
              {t('buttons.save_and_continue')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

ContactDetails.propTypes = {
  workerId: PropTypes.string,
  contactDetails: PropTypes.arrayOf(Object),
  handleSubmit: PropTypes.func,
  isFieldDisabled: PropTypes.func,
  resetForm: PropTypes.objectOf(Object),
};

ContactDetails.defaultProps = {
  workerId: null,
  contactDetails: [],
  handleSubmit() {},
  isFieldDisabled() {},
  resetForm: null,
};

export default ContactDetails;
