import moment from 'moment';
import i18next from 'i18next';
import eLocale from 'date-fns/locale/en-GB';
import fLocale from 'date-fns/locale/fi';
import rLocale from 'date-fns/locale/ru';

import store from '../store/store';

let language = 'en';
let dateFormat = 'dd/MM/yyyy';
let timeFormat = 'HH:mm';
let timeZone = 'Asia/Kolkata';

moment.locale(language);

export const handleStoreChanges = () => {
  const states = store.getState();
  const userData = states.userLogin?.userInfo?.data;
  if (userData) {
    language = userData.preferred_language ? userData.preferred_language.substring(0, 2) : language;

    dateFormat = userData.date_format ? userData.date_format : dateFormat;
    timeZone = userData.time_zone ? userData.time_zone : timeZone;
    if (userData.time_format) {
      if (userData.time_format === '24H') {
        timeFormat = 'HH:mm';
      } else {
        timeFormat = 'hh:mm a';
      }
    }
  }
  moment.locale(language);
  i18next.changeLanguage(language);
};

store.subscribe(handleStoreChanges);

export const getDate = (dateRec, format) =>
  moment(dateRec)
    .format(format || dateFormat.toUpperCase())
    .toString();

export const getTime = (time) => {
  const val = moment(time, ['HH:mm', 'hh:mm a', 'hh:mm A', 'HH:mm:ss'])
    .format(timeFormat)
    .toString();
  if (val !== 'Invalid date') return val;
  return '';
};

export const overViewDate = (on) =>
  moment(new Date()).format(`${timeFormat} [${on}] Do MMMM YYYY`).toString();

export const getDateRange = (date) => {
  const formattedDate = moment(date || new Date()).format(`YYYY-MM-DD`);
  const offSet = moment().format('Z');
  return {
    timeMin: `${formattedDate}T00:00:00${offSet}`,
    timeMax: `${formattedDate}T23:59:59${offSet}`,
  };
};

export const getGreetings = () => {
  const hrs = moment().hours();
  if (hrs >= 0 && hrs < 12) {
    return 'good_morning';
  }
  if (hrs === 12) {
    return 'good_noon';
  }
  if (hrs >= 12 && hrs <= 17) {
    return 'good_afternoon';
  }
  return 'good_evening';
};

export const getTimeFormat = () => timeFormat;

export const getDateFormat = () => dateFormat;

export const getLocale = () => {
  if (language === 'fi') {
    return { name: 'fi', val: fLocale };
  }
  if (language === 'ru') {
    return { name: 'ru', val: rLocale };
  }
  return { name: 'en', val: eLocale };
};

export const changeLanguageOnLoginPage = (lang) => {
  moment.locale(lang);
  i18next.changeLanguage(lang);
};

export const getTimeFormattedInDateObject = (time) => {
  const val = moment(time, ['HH:mm', 'hh:mm a', 'hh:mm A']).toDate();
  if (val !== 'Invalid date') return val;
  return '';
};

export const getTimeFormattedIn24Hours = (time) => {
  const val = moment(time, ['HH:mm', 'hh:mm a', 'hh:mm A']).format('HH:mm');
  if (val !== 'Invalid date') return val;
  return '';
};

export const getCurrentTime = () => moment().format('HH:mm');

export const getCurrentDate = (currentFormat = false, format = 'YYYY-MM-DD', date = new Date()) =>
  moment(date).format(currentFormat ? dateFormat : format || 'YYYY-MM-DD');

export const getUTCFormattedTime = ({ date, time }) =>
  moment(`${date} ${time}`).format(`YYYY-MM-DDTHH:mm:ssZ`);

export const getTimeZone = () => timeZone;
