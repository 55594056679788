import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import DropDown from '../../../../components/dropdown';
import PlusSvg from '../../../../components/svgs/plus-svg';
import User from '../../../../components/user/User';
import { fetchWorkerStatus } from '../../../../redux/features/workers/worker';
import { deleteWorker, getWorkers } from '../../../../services/workers';
import styles from './WorkersList.module.scss';
import PageLoader from '../../../../components/page-loader';
import ConfirmationModal from '../../../../components/confirmation-modal';
import { getImageSrcUrl } from '../../../../utils';

const initialMeta = {
  totalResults: 0,
  page: 1,
  limit: 15,
};

function WorkersList() {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workerStatus } = useSelector((state) => state.worker);
  const [workers, setWorkers] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [workerToDelete, setWorkerToDelete] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState({ ...initialMeta });
  const [selectedValue, setSelectedValue] = useState(null);

  const fetchWorkers = (page, limit = pagination.limit, status = selectedValue) => {
    setPageLoading(true);
    getWorkers(page, limit, status).then(({ status: responseStatus, data, meta }) => {
      if (responseStatus) {
        setWorkers(data);
        setPagination(meta);
      } else {
        setWorkers([]);
        setPagination({ ...initialMeta });
      }
      setPageLoading(false);
    });
  };

  const changePage = (page) => {
    fetchWorkers(page, pagination.limit, selectedValue);
  };

  const statusOptions = () =>
    workerStatus.map((status) => ({ label: t(`status.${status.toLowerCase()}`), value: status }));

  const onFilter = (status) => {
    setSelectedValue(status);
    fetchWorkers(1, pagination.limit, status);
  };

  useEffect(() => {
    const { page, limit } = pagination;
    fetchWorkers(page, limit, selectedValue);
    dispatch(fetchWorkerStatus());
  }, []);

  const removeWorker = () => {
    deleteWorker(workerToDelete.uuid).then(({ status }) => {
      if (status) {
        toast.success('Worker Archived.');
        setShowModal(false);
        fetchWorkers(pagination.page, pagination.limit);
      }
    });
  };

  const actions = [
    {
      actionLabel: t('workers_list.archive.archive_worker'),
      onClick: (workerUuid) => {
        setWorkerToDelete(workers.find((worker) => worker.uuid === workerUuid));
        setShowModal(true);
      },
    },
  ];

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className={`${styles.workerListContainer}`}>
      <Helmet>
        <title>Hiukka - {t('page_titles.workers')}</title>{' '}
      </Helmet>
      <div className="d-lg-flex justify-content-between align-items-center">
        <p className="pageTitle">{t('workers_list.title')}</p>
        <div
          className={`d-flex align-items-center justify-content-between justify-content-lg-end ${styles.headerContainer}`}
        >
          <div>
            <Button
              variant="addBtnBlue"
              onClick={() => navigate('/workers/add')}
              icon={<PlusSvg className="d-inline" />}
            >
              {t('workers_list.buttons.Add_New_Worker')}
            </Button>
          </div>
          <div>
            <DropDown
              placeholder={t('workers_list.dropdown.filter')}
              value={selectedValue}
              options={statusOptions()}
              onChange={(val) => onFilter(val)}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.cardListContainer}`}>
        {workers.map((worker) => (
          <div key={worker.uuid} className={`mb-4 ${styles.w20}`}>
            <User
              name={`${worker.firstname} ${worker.lastname}`}
              profilePic={getImageSrcUrl(worker)}
              area={worker.current_nationality ?? ''}
              tags={worker.workTypes}
              status={worker.work_status.status}
              alignImage="top"
              onEdit={() => navigate(`/workers/${worker.uuid}`)}
              id={worker.uuid}
              actions={actions}
              actionMenu={worker.work_status.status !== 'archived'}
              editBtn={worker.work_status.status !== 'archived'}
            />
          </div>
        ))}
      </div>
      {Math.ceil(pagination.totalResults / pagination.limit) > 1 ? (
        <div>
          <Pagination>
            <Pagination.Prev
              onClick={() => changePage(+pagination.page - 1)}
              disabled={+pagination.page === 1}
            />
            {Array(Math.ceil(pagination.totalResults / pagination.limit))
              .fill(0)
              .map((_, index) => {
                const newUuid = uuid();
                return (
                  <Pagination.Item
                    key={newUuid}
                    active={index + 1 === pagination.page - '0'}
                    onClick={() => changePage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}
            <Pagination.Next
              onClick={() => changePage(+pagination.page + 1)}
              disabled={Math.ceil(pagination.totalResults / pagination.limit) === +pagination.page}
            />
          </Pagination>
        </div>
      ) : null}
      <ConfirmationModal
        show={showModal}
        title={t('workers_list.archive.archive_worker')}
        message={
          <span>
            {t('workers_list.archive.question')}{' '}
            <b>
              {workerToDelete.firstname} {workerToDelete.lastname}?
            </b>
          </span>
        }
        handleClose={() => setShowModal(false)}
        onConfirm={() => removeWorker()}
      />
    </div>
  );
}

export default WorkersList;
