import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import styles from './passwordSent.module.scss';

function PasswordResetLinkSuccess() {
  const { t } = useTranslation('lang');
  return (
    <div className={styles.resetContainer}>
      <p>{t('login.reset_link_success_message')}</p>
      <Link className={styles.loginBtnContainer} to="/auth/login">
        <Button type="button" className={styles.loginBtn}>
          {t('login.login_txt')}
        </Button>
      </Link>
    </div>
  );
}

export default PasswordResetLinkSuccess;
