export const TimeSheetForm = {
  start_time: '',
  end_time: '',
  distance_driven: 0,
  other_costs: 0,
  lunch_break: {
    start_time: '',
    end_time: '',
  },
  extra_break: {
    start_time: '',
    end_time: '',
    reason: '',
  },
  had_lunch: false,
  had_extra_break: false,
  attachments: [],
  feedback: '',
};

export const lunchMinutes = 30;
