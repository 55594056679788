import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../button';
import { getDate, getTime } from '../../lib/dateLib';
import User from '../user';
import styles from './Task.module.scss';
import { getImageSrcUrl } from '../../utils';
import Tag from '../tag';

function Task({ task, companyId = '', projectId = '' }) {
  const navigate = useNavigate();
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.CardMainContainer}`}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.headerContainer}`}
      >
        <p className={`${styles.taskTitle}`}>{task.name}</p>
        <div className={`${styles.TitleBtnContainer}`}>
          <Button
            variant="addBtn"
            onClick={() =>
              navigate(`/companies/${companyId}/projects/${projectId}/task/${task.uuid}`)
            }
          >
            {t('buttons.edit')}
          </Button>
        </div>
      </div>
      <div className={`d-lg-flex ${styles.CardContainer}`}>
        <div className={`${styles.firstCard}`}>
          <div className={`d-flex justify-content-between align-items-center ${styles.header}`}>
            <div>
              {task.workTypePermissions && task.workTypePermissions.length > 0
                ? task.workTypePermissions.map(({ uuid, name }) => <Tag key={uuid}>{name}</Tag>)
                : t('task.card.no_work_type_permissions')}
            </div>

            <div>
              <Button
                variant="addBtn"
                onClick={() =>
                  navigate(`/companies/${companyId}/projects/${projectId}/task/${task.uuid}`)
                }
              >
                + {t('buttons.add')}
              </Button>
            </div>
          </div>
          <div className={`${styles.CardBody}`}>
            <div className={`d-flex ${styles.BodyContent}`}>
              <div>
                <p className={`${styles.contentTitle}`}>{t('task.card.contract_period')}</p>
                <p className={`${styles.contentDec}`}>
                  {getDate(task.contract_period_start)} {' - '}
                  {getDate(task.contract_period_end)}
                </p>
              </div>
              <div>
                <p className={`${styles.contentTitle}`}>{t('task.card.charge_rate')}</p>
                <p className={`${styles.contentDec}`}>
                  € {task.hourly_rate}/{t('task.card.h')}
                </p>
              </div>
            </div>
            <div>
              <p className={`${styles.contentTitle}`}>{t('task.card.daily_work_time')}</p>
              <p className={`${styles.contentDec}`}>
                {getTime(task.daily_work_start)} - {getTime(task.daily_work_end)}
              </p>
            </div>
          </div>
        </div>
        <div className={`${styles.firstCard}`}>
          <div className={`${styles.header}`}>
            <p>{t('task.card.assigned_workers')}</p>
          </div>
          <div className={`${styles.CardBody}`}>
            {task.workers.length !== 0
              ? task.workers.map(({ worker }) => (
                  <User
                    key={worker.uuid}
                    id={worker.uuid}
                    name={`${worker.firstname} ${worker.lastname}`}
                    profilePic={getImageSrcUrl(worker)}
                    tags={worker.workTypes}
                    specialTags={worker.workTypePermissions}
                    status={worker.work_status?.status ?? worker.status}
                    area={worker.current_nationality ?? ''}
                    alignImage="left"
                  />
                ))
              : t('task.msgs.no_worker')}
          </div>
        </div>
      </div>
    </div>
  );
}

Task.propTypes = {
  task: PropTypes.objectOf(Object),
  companyId: PropTypes.string,
  projectId: PropTypes.string,
};

Task.defaultProps = {
  task: null,
  companyId: null,
  projectId: null,
};

export default Task;
