import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EarningOverview.module.scss';
import { overViewDate } from '../../lib/dateLib';

function EarningOverview() {
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.earningOverviewCard}`}>
      <h3>{t('home.earnings_overview_card.earnings_overview')}</h3>
      <h5>
        {t('home.earnings_overview_card.last_updated')}
        {` ${overViewDate(t('home.earnings_overview_card.on'))}`}
      </h5>
    </div>
  );
}

export default EarningOverview;
