import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { getTimeFormat, getLocale, getTimeFormattedInDateObject } from '../../lib/dateLib';

function FormTimePicker({
  format,
  value,
  onChange,
  className,
  required,
  readOnly,
  placeholderText,
}) {
  return (
    <div>
      <DatePicker
        className={className}
        locale={getLocale().val}
        required={required || false}
        showTimeSelect
        readOnly={readOnly || false}
        showTimeSelectOnly
        dateFormat={format || getTimeFormat()}
        selected={value ? getTimeFormattedInDateObject(value) : ''}
        onChange={onChange}
        placeholderText={placeholderText}
      />
    </div>
  );
}

FormTimePicker.propTypes = {
  format: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
};

FormTimePicker.defaultProps = {
  format: 'HH:mm',
  value: null,
  onChange() {},
  className: null,
  required: false,
  readOnly: false,
  placeholderText: 'HH:mm',
};

export default FormTimePicker;
