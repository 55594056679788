import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function InputSaveModal({ show, title, field, value, handleClose, handleConfirm, className }) {
  const [query, setQuery] = useState('');

  const handleModalClose = () => {
    handleClose();
    setQuery('');
  };

  useEffect(() => {
    if (value.name) {
      setQuery(value.name);
    }
  }, [value]);

  return (
    <div className={className}>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{field}</Form.Label>
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Start Typing..."
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleConfirm(value.uuid ? { uuid: value.uuid, name: query } : query)}
          >
            {value.uuid ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

InputSaveModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

InputSaveModal.defaultProps = {
  show: false,
  title: null,
  field: null,
  value: null,
  className: null,
  handleClose: null,
  handleConfirm: null,
};

export default InputSaveModal;
