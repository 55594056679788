import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FeedbackList from '../feedback-list';
import FeedbackForm from '../feedback-form';
import Button from '../button';
import { addFeedback, getFeedbacks } from '../../services/feedbacks';
import styles from './TaskFeedback.module.scss';
import { Console } from '../../utils';

function TaskFeedback({ resetForm }) {
  const { companyId, projectId, taskId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('lang');
  const [feedback, setFeedback] = useState('');
  const { userInfo } = useSelector((state) => state.userLogin);
  const [feedbacks, setFeedbacks] = useState([]);

  const fetchFeedbacks = () => {
    getFeedbacks(companyId, projectId, taskId).then((res) => {
      if (res.status) {
        setFeedbacks(res.data);
      }
    });
  };

  const postFeedback = () => {
    if (feedback !== '') {
      setFeedbacks([
        ...feedbacks,
        {
          feedback,
          createdAt: moment().toDate(),
          from: {
            firstname: userInfo?.data?.firstname,
            lastname: userInfo?.data?.lastname,
          },
        },
      ]);
      setFeedback('');
    } else {
      toast.error(`${t(`task.feedback.title`)} ${t(`common_messages.cannot_be_empty`)}`);
    }
  };

  const uploadFeedbacks = async () => {
    const feedbackToUpload = feedbacks.filter((f) => !f.uuid);
    try {
      if (feedbackToUpload.length !== 0) {
        await Promise.all(
          feedbackToUpload.map((f) => addFeedback(companyId, projectId, taskId, f))
        );
        toast.success(t(`task.msgs.feedback_success`));
      }
      navigate(`/companies/${companyId}/projects/${projectId}`);
    } catch (error) {
      Console.log(error);
    }
  };

  useEffect(() => {
    if (companyId && projectId && taskId) {
      fetchFeedbacks();
    }
  }, []);

  useEffect(() => {
    if (resetForm.reset) {
      setFeedback('');
    }
  }, [resetForm.resetId]);

  return (
    <div
      className={` scrollContainer d-lg-flex justify-content-lg-between ${styles.feedbackMainContainer}`}
    >
      <div>
        <FeedbackForm
          title={<p>{t('task.feedback.heading')}</p>}
          feedback={feedback}
          setFeedback={setFeedback}
          postFeedback={postFeedback}
          btnTitle="save"
          placeholder={t('task.feedback.placeholder')}
        />
      </div>
      <div>
        <FeedbackList feedbacks={feedbacks} />
        <div className={`${styles.btnContainer}`}>
          <Button variant="saveBtn" onClick={uploadFeedbacks}>
            {t('buttons.post_and_continue')}
          </Button>
        </div>
      </div>
    </div>
  );
}

TaskFeedback.propTypes = {
  resetForm: PropTypes.instanceOf(Object),
};

TaskFeedback.defaultProps = {
  resetForm: null,
};

export default TaskFeedback;
