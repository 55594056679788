import googleService from './googleWrapper';

export const getEvents = ({ calendarId, timeMax, timeMin, timeZone, orderBy }) =>
  googleService.get(`/${calendarId}/events`, {
    params: {
      timeMax,
      timeMin,
      timeZone,
      singleEvents: true,
      orderBy,
      domain: process.env.REACT_APP_GOOGLE_APP_DOMAIN,
    },
  });

export const getEvent = (calendarId, eventId) =>
  googleService.get(`/${calendarId}/events/${eventId}`, {
    params: { domain: process.env.REACT_APP_GOOGLE_APP_DOMAIN },
  });

export const createEvent = ({ calendarId, body }) =>
  googleService.post(`/${calendarId}/events`, body, {
    params: { domain: process.env.REACT_APP_GOOGLE_APP_DOMAIN },
  });

export const updateEvent = ({ calendarId, eventId, body }) =>
  googleService.put(`/${calendarId}/events/${eventId}`, body, {
    params: { domain: process.env.REACT_APP_GOOGLE_APP_DOMAIN },
  });
