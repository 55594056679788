import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import CompanyForm from '../../../components/company-form';
import { addCompany } from '../../../services/company';
import styles from './AddCompany.module.scss';
import COMPANY_DETAILS from './constant';
import { Console } from '../../../utils';

function AddCompany() {
  const [company, setCompany] = useState(COMPANY_DETAILS);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation('lang');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(true);
    addCompany(company)
      .then(() => {
        toast.success(t('company.msgs.saved'));
        setCompany(COMPANY_DETAILS);
        navigate('/projects');
      })
      .catch((error) => {
        Console.log(error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Hiukka - {t('page_titles.projects')}</title>{' '}
      </Helmet>
      <p className="pageTitle">{t('company.heading')}</p>
      <Card className={styles.companyDetailsContainer}>
        <Card.Body className={`${styles.scrollContainer}`}>
          <CompanyForm
            company={company}
            setCompany={setCompany}
            isValid={isValid}
            handleSubmit={handleSubmit}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default AddCompany;
