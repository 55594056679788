import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Button from '../../../components/button';
import styles from './login.module.scss';
import { loginUser } from '../../../redux/features/users/userSlice';
import SideLoader from '../../../components/side-loader';
import { changeLanguageOnLoginPage } from '../../../lib/dateLib';
import { getGoogleRefreshToken } from '../../../services/auth';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('lang');

  const [user, setUser] = useState({
    email: null,
    password: null,
  });

  const { loading, userInfo } = useSelector((state) => state.userLogin);
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(user));
  };

  const onSignIn = (res) => {
    getGoogleRefreshToken(res.code, res.state).then((data) => {
      const { expiry_date: expiryDate, access_token: accessToken } = data.token;
      localStorage.setItem('accessToken', JSON.stringify({ expiryDate, accessToken }));
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      navigate('/');
    });
  };

  const initClient = (state) => {
    try {
      /* global google */
      const client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: process.env.REACT_APP_GOOGLE_CALENDAR_SCOPE,
        ux_mode: 'popup',
        state,
        callback: onSignIn,
      });
      client.requestCode();
    } catch (error) {
      toast.error('Something Went Wrong');
    }
  };

  useEffect(() => {
    if (userInfo.data && userInfo.data.uuid) {
      if (userInfo.data.refreshToken) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        navigate('/');
      } else {
        initClient(userInfo.data.uuid);
      }
    }
  }, [navigate, userInfo]);

  return (
    <div className={styles.formContainer}>
      <Helmet>
        {' '}
        <title>Hiukka - {t('page_titles.login')}</title>{' '}
      </Helmet>
      {loading && <SideLoader />}
      <Form onSubmit={onSubmit}>
        <Form.Group className={styles.inputContainer}>
          <Form.Control
            className={styles.inputField}
            type="email"
            required
            placeholder={t('login.username')}
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <Form.Text />
        </Form.Group>
        <Form.Group className={styles.inputContainer}>
          <Form.Control
            className={styles.inputField}
            type="password"
            required
            placeholder={t('login.password')}
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <Form.Text />
        </Form.Group>

        <Button type="submit" className={styles.loginBtn}>
          {t('login.login_txt')}
        </Button>
      </Form>
      <div className={styles.forgotPasswordContainer}>
        <Link to="/auth/forgot-password" className={styles.forgotPasswordLink}>
          {t('login.forget_password')}
        </Link>
      </div>
      <div className={`d-flex align-items-center justify-content-center ${styles.flagContainer}`}>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('en')}
          >
            <img className={styles.flagImg} src="/images/flag1.png" alt="flag" />
          </button>
        </div>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('fi')}
          >
            <img className={styles.flagImg} src="/images/flag2.png" alt="flag" />
          </button>
        </div>
        <div>
          <button
            className={`${styles.imgBtn}`}
            type="button"
            onClick={() => changeLanguageOnLoginPage('ru')}
          >
            <img className={styles.flagImg} src="/images/flag3.png" alt="flag" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
