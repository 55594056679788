/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function ArrowUpRightCircleFill({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
      viewBox="0 0 16 16"
    >
      <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z" />
    </svg>
  );
}

ArrowUpRightCircleFill.propTypes = {
  className: PropTypes.string,
};

ArrowUpRightCircleFill.defaultProps = {
  className: null,
};

export default ArrowUpRightCircleFill;
