import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import styles from './PageLoader.module.scss';

function PageLoader({ color, size }) {
  return (
    <div
      className={`${styles.pageLoaderContainer} d-flex justify-content-center align-items-center`}
    >
      <div className={styles.pageLoader}>
        <ClimbingBoxLoader loading color={color} size={size} />
      </div>
    </div>
  );
}

PageLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

PageLoader.defaultProps = {
  color: '#303030',
  size: 30,
};

export default PageLoader;
