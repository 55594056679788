/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from '../../../services/auth';
import { getProfile } from '../../../services/user';

const initialState = {
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
  loading: false,
  error: '',
};
export const loginUser = createAsyncThunk('login', async ({ email, password }) =>
  login(email, password).then(async (response) => {
    localStorage.setItem('token', response.token);
    return getProfile('me').then((profileResponse) => profileResponse);
  })
);

export const getUserProfile = createAsyncThunk('profile', async () =>
  getProfile('me').then((response) => {
    localStorage.setItem('userInfo', JSON.stringify(response));
    return response;
  })
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('access_token');

      return {
        ...state,
        userInfo: {},
        loading: false,
        error: '',
      };
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => ({ ...state, loading: false }),
    [loginUser.fulfilled]: (state, action) => ({
      ...state,
      userInfo: action.payload,
      loading: false,
      error: '',
    }),
    [loginUser.rejected]: (state, action) => ({
      ...state,
      userInfo: {},
      loading: false,
      error: action.error.message,
    }),
    [getUserProfile.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getUserProfile.fulfilled]: (state, action) => ({
      ...state,
      userInfo: action.payload,
      loading: false,
      error: '',
    }),
    [getUserProfile.rejected]: (state, action) => ({
      userInfo: {},
      loading: false,
      error: action.error.message,
    }),
  },
});

export const { logOut } = userSlice.actions;
export default userSlice;
