import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';

const useAuth = () => {
  const { userInfo } = useSelector((state) => state.userLogin);
  return userInfo && userInfo.data;
};

function ProtectedRoutes() {
  const isAuthenticated = useAuth();
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
}

export default ProtectedRoutes;
